import { useMemo } from 'react'
import file from './assets/file.svg'

const icons: Record<string, string> = { file }

export const useCustomIcon = (name: string, size?: number | string) => {
  const [set, id] = name.split('-')

  const styles = useMemo(() => {
    const icon = icons[id]

    if (set === 'custom' && icon) {
      return {
        background: `url(${icon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: typeof size === 'undefined' ? '1em' : size,
        width: typeof size === 'undefined' ? '1em' : size
      }
    }
  }, [size, set, id])

  return styles
}
