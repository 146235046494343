import { Block } from 'graphql/types'
import { ReactNode, createContext, useContext } from 'react'
import { useEffect } from 'react'
import { BlocksStoreState, useBlocksStore } from './blocks.store'

export interface BlocksContextProviderProps {
  blocks: Block[]
  children: ReactNode
}

const BlocksContext = createContext<BlocksStoreState>({} as any)

export const BlocksContextProvider = ({
  blocks: initialBlocks,
  children
}: BlocksContextProviderProps) => {
  const store = useBlocksStore()

  useEffect(() => {
    store.import(initialBlocks)
  }, [])

  return (
    <BlocksContext.Provider value={store}>{children}</BlocksContext.Provider>
  )
}

export const useBlocksContext = () => {
  return useContext(BlocksContext)
}
