import { useAuth } from 'auth'
import { ModalProvider } from 'components/Elements'
import { Page } from './EditProfileBlocksPage'
import { AnimatedRoute } from 'v2/AnimatedRoute/AnimatedRoute'

export const EditProfileBlocksPageRoute = () => {
  const auth = useAuth()

  const profile = auth?.profile

  if (!profile) {
    return null
  }

  return (
    <AnimatedRoute>
      <ModalProvider>
        <Page profile={profile} />
      </ModalProvider>
    </AnimatedRoute>
  )
}
