import { MouseEvent, forwardRef } from 'react'
import $ from 'classnames'
import { useCustomIcon } from './useCustomIcon'

import styles from './styles.module.scss'

interface IconProps {
  name: string
  inline?: boolean
  className?: any
  color?: string
  size?: number | string
  bold?: boolean
  style?: any
  onClick?: (event: MouseEvent) => void
}

export const Icon = forwardRef<HTMLBaseElement, IconProps>(
  ({ className, inline, name, color, size, bold, style, onClick, ...props }, ref) => {
    const customIconStyles = useCustomIcon(name, size)

    return (
      <i
        ref={ref}
        className={$(
          styles.icon,
          bold && styles.bold,
          inline && styles.inline,
          !!onClick && styles.clickable,
          name.startsWith('custom') && styles.custom,
          name,
          className
        )}
        style={{ color, fontSize: size, ...customIconStyles, ...style }}
        onClick={onClick}
        {...props}
      ></i>
    )
  }
)
