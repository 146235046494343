import { ReactNode } from 'react'
import { Container } from './Container'

import styles from './Template.module.scss'
import { Stack } from '@shareback/structural'

export interface TemplateProps {
  title?: ReactNode
  description?: ReactNode
  children: ReactNode
}

export const Template = ({ title, description, children }: TemplateProps) => {
  return (
    <Container data-fs-unmask>
      <Stack gap={12} fill horizontal="center">
        {title && <h2 className={styles.title}>{title}</h2>}
        {description && <div className={styles.description}>{description}</div>}
        {
          <Stack gap="1em" fill horizontal="center" className={styles.children}>
            {children}
          </Stack>
        }
      </Stack>
    </Container>
  )
}
