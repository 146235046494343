import { FlexRow, FlexStack } from 'components/Layout'
import styles from './Controls.module.scss'
import { Icon } from 'components/Elements'

export interface ControlsProps {
  onConfirm: () => void
}

export const Controls = ({ onConfirm }: ControlsProps) => {
  return (
    <FlexStack gap={8} style={{ height: 60 }} align="center">
      <FlexRow gap="0.5em" className={styles.button} onClick={onConfirm}>
        Continue <Icon name="ri-arrow-right-line" size="1em" />
      </FlexRow>

      <div className={styles.note}>Or tap photo to try again</div>
    </FlexStack>
  )
}
