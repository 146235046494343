import $ from 'classnames'
import { AvatarStack, Icon } from 'components/Elements'
import { Row } from '@shareback/structural'
import { ReactNode } from 'react'

import styles from './PendingProfilesCard.module.scss'

export interface PendingProfilesCardProps {
  onClick: () => void
  children: ReactNode
  photos: string[]
}

export const PendingProfilesCard = ({ children, photos, onClick }: PendingProfilesCardProps) => {
  return (
    <Row gap={10} fill className={$(styles.clickable)} onClick={onClick}>
      <Row gap={20} fill>
        <AvatarStack borderColor="#1b1b1b" avatars={photos} size={32} />
        <div>{children}</div>
      </Row>
      <Row>
        <Icon name="ri-arrow-right-s-line" />
      </Row>
    </Row>
  )
}
