import styles from './WaveButton.module.scss'

export interface WaveButtonProps {
  onClick: () => void
}

export const WaveButton = ({ onClick }: WaveButtonProps) => {
  return (
    <button
      type="button"
      className={styles.button}
      onClick={() => {
        onClick()
      }}
    >
      Connect
    </button>
  )
}
