import { Stack } from '@shareback/structural'
import { ViewBlocks } from 'components/Blocks/ViewBlocks'
import { ProfileAbout } from 'components/Profiles/ProfileAbout'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { Tags } from 'components/Tags'
import { CompanySummaryFragment, ProfileFragment, TagInput } from 'graphql/types'
import { VoicePlayer } from 'routes/Tests/Voice/VoicePlayer'
import { getRoleDescription } from 'v2/_utils/getRoledescription'

export interface ProfileComponentProps {
  profile: ProfileFragment
  company?: CompanySummaryFragment
  onAvatarClick?: () => void
}
/**
 * @todo: this is temporary - fix it laterrrr
 * @param profile
 * @returns
 */
const profileToTags = (profile: ProfileFragment) => {
  const tags: TagInput[] = []

  const {
    socialDribble,
    socialEmail,
    socialInstagram,
    socialLinkedIn,
    socialPhone,
    industry,
    location
  } = profile

  if (location) {
    tags.push({
      text: location,
      type: 'location'
    })
  }

  if (industry) {
    tags.push({
      text: industry,
      type: 'industry'
    })
  }

  if (socialDribble) {
    tags.push({
      text: socialDribble,
      type: 'dribble'
    })
  }

  if (socialEmail) {
    tags.push({
      text: socialEmail,
      type: 'email'
    })
  }

  if (socialInstagram) {
    tags.push({
      text: socialInstagram,
      type: 'instagram'
    })
  }

  if (socialLinkedIn) {
    tags.push({
      text: socialLinkedIn,
      type: 'linkedIn'
    })
  }

  if (socialPhone) {
    tags.push({
      text: socialPhone,
      type: 'phone'
    })
  }

  return tags
}

export const ProfileComponent = ({
  profile,
  company: _company,
  onAvatarClick
}: ProfileComponentProps) => {
  const company = _company || profile.company

  return (
    <Stack gap={40} fill>
      <Stack>
        <ProfileAvatar picture={profile.photo} onClick={onAvatarClick} />

        <ProfileAbout
          link={company ? `/c/${company.identifier}` : undefined}
          title={profile.name}
          description={getRoleDescription(profile.role, company?.name)}
        />

        {profile.audio && (
          <div style={{ padding: 20, paddingTop: 0 }}>
            <VoicePlayer size="small" audioUrl={profile.audio} />
          </div>
        )}

        <Tags tags={profileToTags(profile)} />
      </Stack>

      <ViewBlocks blocks={profile.blocks || []} />
    </Stack>
  )
}
