import { Route, Routes, useLocation } from 'react-router-dom'
import { ProfilePageRoute } from 'routes/ProfilePage'
import { AnimatePresence } from 'framer-motion'
import { GroupInvitePage } from 'routes/GroupInvitePage'
import { GroupPageRoute } from 'routes/GroupPage'
import { EditGroupPageRoute } from 'routes/EditGroupPage'
import { OnboardingPageRoute } from 'routes/OnboardingPage'
import { EditProfilePageRoute } from 'routes/EditProfilePage'
import { HomePageRoute } from 'routes/HomePage'
import { CompanyPageRoute } from 'routes/CompanyPage'
import { TestsRouter } from 'routes/Tests/TestsRouter'
import { EditCompanyPageRoute } from 'routes/EditCompanyPage'
import { EditProfileBlocksPageRoute } from 'routes/EditProfileBlocksPage'
import { SystemRouter } from 'routes/SystemRouter/SystemRouter'
import { NotificationsPageRoute } from 'routes/NotificationsPage'
import { ContactsPageRoute } from 'routes/ContactsPage/ContactsPageRoute'

export const AuthenticatedApp = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route key="onboarding" path="/onboarding/*" element={<OnboardingPageRoute />} />
        <Route key="system" path="/s/*" element={<SystemRouter />} />
        <Route key="edit" path="/edit" element={<EditProfilePageRoute />} />
        <Route key="edit-blocks" path="/edit-blocks" element={<EditProfileBlocksPageRoute />} />
        <Route key="edit-group" path="/g/:id/edit" element={<EditGroupPageRoute />} />
        <Route key="group" path="/g/:id/*" element={<GroupPageRoute />} />
        {/* <Route key="matchmaking" path="/m/:id/*" element={<MatchmakingPageRoute />} /> */}
        {/* <Route key="club" path="/x/:id/*" element={<ClubPageRoute />} /> */}
        <Route key="invite" path="/i/:id" element={<GroupInvitePage />} />
        <Route key="company" path="/c/:identifier" element={<CompanyPageRoute />} />
        <Route key="company-ed" path="/c/:identifier/edit" element={<EditCompanyPageRoute />} />
        <Route key="test" path="/t/*" element={<TestsRouter />} />
        <Route key="profile" path="/:identifier/*" element={<ProfilePageRoute />} />
        <Route key="home" path="/" element={<HomePageRoute />} />
        <Route key="notifications" path="/notifications" element={<NotificationsPageRoute />} />
        <Route key="contacts" path="/contacts" element={<ContactsPageRoute />} />
      </Routes>
    </AnimatePresence>
  )
}
