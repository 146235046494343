import { ModalProvider } from 'components/Elements'
import { PropsWithChildren } from 'react'
import { NotificationsProvider } from 'routes/NotificationsPage/NotificationsProvider'

export interface PageLayoutControllerProps extends PropsWithChildren {}

export const PageLayoutController = (props: PageLayoutControllerProps) => {
  return (
    <ModalProvider>
      <NotificationsProvider>{props.children}</NotificationsProvider>
    </ModalProvider>
  )
}
