import { Avatar } from 'components/Elements'
import { FlexRow } from 'components/Layout'
import $ from 'classnames'

import styles from './ProfileListItem.module.scss'
import Skeleton from 'react-loading-skeleton'
import { Row, Stack } from '@shareback/structural'
import { ReactNode } from 'react'

export interface ProfileListItemProps {
  title: string
  description: string
  photo: string
  photoRadius?: number
  padding?: number
  disabled?: boolean
  more?: ReactNode
  onClick?: () => void
}

const DEFAULT_PHOTO_RADIUS = 64

export const ProfileListItem = ({
  title,
  description,
  photo = '',
  photoRadius = DEFAULT_PHOTO_RADIUS,
  padding = 10,
  disabled = false,
  more,
  onClick
}: ProfileListItemProps) => (
  <FlexRow
    fill
    gap={20}
    className={$(
      styles.record,
      !!onClick && !disabled && styles.clickable,
      disabled && styles.disabled
    )}
    style={{ padding }}
    onClick={disabled ? undefined : onClick}
  >
    <Avatar url={photo} size={photoRadius} />

    <Row fill>
      <Stack fill>
        <Row fill className={styles.title}>
          {title}
        </Row>
        <div className={styles.description}>{description}</div>
      </Stack>
      {more}
    </Row>
  </FlexRow>
)

export interface ProfileListItemLoadingProps {
  photoRadius?: number
}

export const ProfileListItemLoading = ({
  photoRadius = DEFAULT_PHOTO_RADIUS
}: ProfileListItemLoadingProps) => (
  <Row fill gap={20} className={styles.record}>
    <Avatar url={''} size={photoRadius} />
    <Row fill>
      <Stack fill>
        <Row fill>
          <Skeleton width={100} />
        </Row>
        <Row fill className={styles.description}>
          <Skeleton width={230} />
        </Row>
      </Stack>
    </Row>
  </Row>
)
