import { Row } from '@shareback/structural'
import { ReactNode } from 'react'

import styles from './FieldLabel.module.scss'

export interface FieldLabelProps {
  children: ReactNode
  description?: string
}

export const FieldLabel = ({ children, description }: FieldLabelProps) => {
  return (
    <Row fill horizontal="spaced" className={styles.label}>
      <div>{children}</div>
      <div>{description}</div>
    </Row>
  )
}
