import { ReactNode } from 'react'

type TextTransforms =
  /* Keyword values */
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'full-width'
  | 'full-size-kana'
  /* Global values */
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'

type TextWeights =
  | 'hairline' // 100,
  | 'thin' // 200,
  | 'light' // 300,
  | 'normal' // 400,
  | 'medium' // 500,
  | 'semibold' // 600,
  | 'bold' // 700,
  | 'extrabold' // 800,
  | 'black' // 900

type TextAlignment = 'left' | 'right' | 'center'

export interface TextProps {
  bold?: boolean
  lineHeight?: number
  align?: TextAlignment
  transform?: TextTransforms
  weight?: TextWeights
  size?: number
  color?: string
  children: ReactNode
}

export const Text = ({
  children,
  align,
  bold,
  lineHeight = 1.5,
  color,
  size,
  ...props
}: TextProps) => (
  <span
    style={{
      textAlign: align,
      fontWeight: bold ? 500 : undefined,
      fontSize: size,
      lineHeight,
      color
    }}
    {...props}
  >
    {children}
  </span>
)
