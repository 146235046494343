import { useState, useEffect } from 'react'
import { useIntervalWhen } from 'rooks'

export const useDuration = (enabled: boolean) => {
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    if (enabled) setDuration(0)
  }, [enabled])

  useIntervalWhen(
    () => {
      setDuration((duration) => duration + 1)
    },
    1000,
    enabled
  )

  return duration
}
