import { Avatar } from 'components/Elements'
import { FlexRow, FlexStack } from 'components/Layout'

import styles from './About.module.scss'

export interface AboutProps {
  title: string
  subtitle: string
  photo: string
}

export const About = ({ title, subtitle, photo }: AboutProps) => {
  return (
    <FlexRow gap={20} fill vertical="center">
      <Avatar url={photo} size={48} />

      <FlexStack>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </FlexStack>
    </FlexRow>
  )
}
