import { HTMLAttributes, forwardRef } from 'react'
import $ from 'classnames'

import styles from './Row.module.scss'

type HTMLDivProps = Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style' | 'children' | 'onClick'>

export interface RowProps extends HTMLDivProps {
  id?: string
  fill?: boolean
  stackOnPhone?: boolean
  gap?: number | string
  wrap?: boolean
  shrink?: boolean
  reverse?: boolean
  horizontal?: 'left' | 'right' | 'center' | 'spaced'
  vertical?: 'top' | 'center' | 'bottom' | 'baseline'
  padded?: boolean
  contentEditable?: any
  suppressContentEditableWarning?: boolean
}
/**
 * Aligns items horizontally and puts them in the row.
 */

const pad = (padded: boolean, gap: number | string) => {
  if (padded) {
    return { paddingLeft: gap, paddingRight: gap }
  }
}

export const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      className,
      style,
      fill,
      shrink,
      stackOnPhone,
      reverse,
      wrap,
      gap = 0,
      horizontal = 'left',
      vertical = 'center',
      padded = false,
      ...props
    },
    ref
  ) => {
    const classes = $(
      styles.row,
      horizontal && styles[`h-${horizontal}`],
      vertical && styles[`v-${vertical}`],
      reverse && styles.reverse,
      stackOnPhone && styles.stackOnPhone,
      fill && styles.fill,
      wrap && styles.wrap,
      className
    )

    return (
      <div
        {...props}
        ref={ref}
        className={classes}
        style={{
          gap,
          ...pad(padded, gap),
          ...style,
          flexShrink: typeof shrink !== 'undefined' ? (shrink ? 1 : 0) : undefined
        }}
      />
    )
  }
)
