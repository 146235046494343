import { motion } from 'framer-motion'
import { ReactNode } from 'react'

export interface AnimatedRouteProps {
  children: ReactNode
}

export const AnimatedRoute = ({ children }: AnimatedRouteProps) => {
  return (
    <motion.main
      style={{ height: '-webkit-fill-available' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.12 }}
    >
      {children}
    </motion.main>
  )
}
