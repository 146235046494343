import { ReactNode } from 'react'
import $ from 'classnames'

import styles from './Column.module.scss'

export interface ColumnProps {
  max: number
  className?: string
  children: ReactNode
}

export const Column = ({ max, className, children }: ColumnProps) => {
  return (
    <div className={$(styles.container, className)} style={{ maxWidth: max }}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
