import { useState, useEffect } from 'react'

interface UseVoiceRecorderOptions {
  onError: (error: string) => void
  onReady: (blob: Blob, url: string) => void
}

const types = ['audio/webm', 'audio/mp4']
const type = types.find((type) => MediaRecorder?.isTypeSupported(type))

console.log(`MediaRecorder uses ${type} mimeType.`)

export const useVoiceRecorder = ({
  onError,
  onReady
}: UseVoiceRecorderOptions) => {
  const [recording, setRecording] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [audioUrl, setAudioUrl] = useState('')

  useEffect(() => {
    return () => {
      stop()
    }
  }, [])

  const start = async () => {
    try {
      setAudioUrl('')
      onError('')

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream, { mimeType: type })

      const chunks: BlobPart[] = []

      recorder.ondataavailable = (event) => {
        chunks.push(event.data)

        console.log(event)
      }

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: recorder.mimeType })
        const url = URL.createObjectURL(blob)

        setAudioUrl(url)
        onReady(blob, url)
      }

      recorder.start()
      setRecording(true)
      setMediaRecorder(recorder)

      console.log('W:: MediaRecorder mime type:', recorder.mimeType)

      setTimeout(() => {
        if (recorder.state === 'recording') {
          stop()
        }
      }, 5 * 60 * 1000)
    } catch (err: any) {
      const errorName = err.name || ''
      let errorMessage = 'Failed to access the microphone'

      if (
        errorName === 'NotAllowedError' ||
        errorName === 'PermissionDeniedError'
      ) {
        errorMessage = 'Microphone access denied'
      } else if (
        errorName === 'NotFoundError' ||
        errorName === 'DevicesNotFoundError'
      ) {
        errorMessage = 'Microphone not found'
      }

      onError(errorMessage)
    }
  }

  const stop = () => {
    if (!mediaRecorder) return

    mediaRecorder.stop()

    setRecording(false)

    if (mediaRecorder.stream) {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop())
    }
  }

  return {
    recording,
    audioUrl,
    start,
    stop,
    mediaRecorder
  }
}
