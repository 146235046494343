import { PropsWithChildren, ReactNode } from 'react'
import styles from './PageLayout.module.scss'
import { PageHeader } from './PageHeader'
import { PageLayoutController } from './PageLayoutControler'
import { Glow } from 'v2/Picture/Glow'
import { AnimatedRoute } from 'v2/AnimatedRoute/AnimatedRoute'

const PageLayoutBase = (props: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PageHeader />
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

export interface PageLayoutProps extends PropsWithChildren {
  glow?: boolean
  footer?: ReactNode
}

export const PageLayout = ({ footer, glow, children }: PageLayoutProps) => {
  return (
    <PageLayoutController>
      <AnimatedRoute>
        {glow && <Glow />}
        <PageLayoutBase>{children}</PageLayoutBase>
        {footer}
      </AnimatedRoute>
    </PageLayoutController>
  )
}
