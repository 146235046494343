import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useJoinGroupMutation } from 'graphql/types'

export const useFollowupInvite = () => {
  const [search] = useSearchParams()
  const [joinGroup] = useJoinGroupMutation()

  console.log(search.get('group_id'))

  const groupId = search.get('group_id')

  useEffect(() => {
    if (groupId) {
      joinGroup({
        fetchPolicy: 'network-only',
        errorPolicy: 'none',
        variables: {
          groupId
        },
        onError(error) {
          console.log(error)
        }
      })
    }
  }, [groupId])
}
