import { FlexRow, FlexStack } from 'components/Layout'
import { Icon } from 'components/Elements'
import { MouseEventHandler, ReactNode } from 'react'
import $ from 'classnames'

import styles from './Block.module.scss'

export interface BasicBlockProps {
  sup?: string | null
  sub?: string | null
  icon?: string | null
  color?: string | null
  children?: ReactNode
  onClick?: MouseEventHandler
}

export const Block = ({
  onClick,
  children,
  sup,
  sub,
  icon,
  color
}: BasicBlockProps) => {
  return (
    <FlexRow
      className={styles.container}
      gap={8}
      fill
      horizontal="spaced"
      onClick={onClick}
    >
      <FlexStack gap={0} className={styles.content}>
        {sup && <div className={styles.script}>{sup}</div>}
        <div>{children}</div>
        {sub && <div className={$(styles.script, styles.sub)}>{sub}</div>}
      </FlexStack>

      {icon && <Icon name={icon} className={styles.icon} />}
    </FlexRow>
  )
}
