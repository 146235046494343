import config from 'config'
import { BlitzAuth } from './BlitzAuth'
import { BlitzApolloClient } from 'graphql/apollo'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

export class BlitzApplication {
  readonly auth: BlitzAuth
  readonly graphql: ApolloClient<NormalizedCacheObject>

  constructor() {
    this.graphql = new BlitzApolloClient()

    this.auth = new BlitzAuth(
      config.firebase.apiKey,
      config.firebase.authDomain,
      this.graphql
    )
  }

  get origin() {
    return window.location.origin
  }

  async logout() {
    await this.auth.signOut()
    await this.graphql.clearStore()

    window.location.reload()
  }
}
