import { PropsWithChildren } from 'react'
import styles from './Emoji.module.scss'

export interface EmojiProps extends PropsWithChildren {
  emoji: string
}

export const Emoji = ({ emoji, children }: EmojiProps) => {
  return (
    <div className={styles.wrapper}>
      {children}
      <div className={styles.emoji}>{emoji}</div>
    </div>
  )
}
