import config from 'config'

import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'

export const app = initializeApp(config.firebase)
export const auth = getAuth(app)

export const googleAuthProvider = new GoogleAuthProvider()
export const linkedInAuthProvider = new OAuthProvider('linkedin.com')
