import { Button, ButtonSize } from 'components/Elements'
import { FlexStack, Spacer } from 'components/Layout'
import {
  AnswerOption,
  MultipleChoiceQuestion,
  Question,
  TextQuestion
} from '../components/Question'
import { useCallback, useEffect, useState } from 'react'
import { BlockInput, useUpdateProfileMutation } from 'graphql/types'
import { v4 as uuidV4 } from 'uuid'
import { Row } from '@shareback/structural'
import { BackButton } from '../BackButton'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useNavigate, useParams } from 'react-router'

const question3 = {
  message: 'What do you need most right now?',
  hasOther: true,
  otherPlaceholder: 'e.g. Intros to angels, etc',
  placeholder: 'e.g. Clients, advice, etc',
  options: [
    {
      value: 'fundraising',
      text: 'Fundraising'
    },
    {
      value: 'user-interviews',
      text: 'User Interviews'
    },
    {
      value: 'hiring',
      text: 'Hiring'
    },
    {
      value: 'advice',
      text: 'Advice'
    },
    {
      value: 'beta-users',
      text: 'Beta Users'
    },
    {
      value: 'network',
      text: 'Network'
    }
  ]
}

// const question4 = {
//   message: 'How can someone connect with you on this?',
//   options: [
//     {
//       value: 'email',
//       text: 'Email'
//     },
//     {
//       value: 'link',
//       text: 'Link'
//     }
//   ]
// }

const question5 = {
  message: 'What can you give in return?',
  placeholder: 'e.g. Product advice'
}

const vcStageFocusQuestion = {
  message: 'What stage fundraising do you focus on? (choose all that apply)',
  options: [
    {
      value: 'Angel',
      text: 'Angel'
    },

    {
      value: 'pre-seed',
      text: 'Pre-seed'
    },

    {
      value: 'seed',
      text: 'Seed'
    },

    {
      value: 'series-a',
      text: 'Series A'
    },

    {
      value: 'growth',
      text: 'Growth'
    }
  ]
}

const vcTypeFocusQuestion = {
  message: 'What kind of startups are you looking for? (choose all that apply)',
  hasOther: true,
  otherPlaceholder: 'e.g. Web4',
  options: [
    { value: 'fintech', text: 'Fintech' },
    { value: 'b2c', text: 'B2C' },
    { value: 'hardware', text: 'Hardware' },
    { value: 'ai', text: 'AI' },
    { value: 'climate', text: 'Climate' },
    { value: 'healthcare', text: 'Healthcare' },
    { value: 'b2b', text: 'B2B' },
    { value: 'edtech', text: 'EdTech' }
  ]
}

const validateAnswer = (answer: AnswerOption) => {
  return answer.value !== 'other' || (answer.value === 'other' && !!answer.text)
}

const checkAnswerConfigured = (
  answer: AnswerOption | AnswerOption[] | string | null | undefined
) => {
  if (!answer) {
    return false
  }

  if (typeof answer === 'string') {
    return answer.trim().length > 0
  }

  const options = [answer].flat()

  return options.length > 0 && options.every(validateAnswer)
}

export const ConfigureBlocks = () => {
  const params = useParams()
  const navigate = useNavigate()

  const industry = params.industry as string

  const onNext = useCallback(() => {
    navigate(`/onboarding/photo`)
  }, [])

  useEffect(() => {
    if (!industry) {
      navigate(`/onboarding/tags`)
    }
  }, [])

  const [vcStage, setVcStage] = useState<AnswerOption[]>([])
  const [vcType, setVcType] = useState<AnswerOption[]>([])

  const [need, setNeed] = useState<AnswerOption>()
  const [give, setGive] = useState<string>('')

  const [otherNeed, setOtherNeed] = useState('')
  const [otherGive, setOtherGive] = useState('')

  const [updateProfile] = useUpdateProfileMutation()

  return (
    <AppPageLayout>
      <Spacer height={40} />

      <Row horizontal="spaced" fill>
        <Row gap={20}>
          <BackButton to="/onboarding/tags" />
          <div
            style={{
              textTransform: 'uppercase',
              opacity: 0.5,
              fontSize: 16,
              fontWeight: 900
            }}
          >
            {industry}
          </div>
        </Row>

        <Row>2 / 3</Row>
      </Row>

      <FlexStack fill gap={20}>
        {industry === 'startup' && (
          <>
            <Question
              {...question3}
              answer={need}
              onAnswer={(answer) => {
                setNeed(answer)
              }}
            />

            {!!need && (
              <TextQuestion {...question5} answer={give} onAnswer={setGive} />
            )}
          </>
        )}

        {industry === 'vc' && (
          <>
            <MultipleChoiceQuestion
              {...vcStageFocusQuestion}
              answers={vcStage}
              onAnswer={setVcStage}
            />

            {checkAnswerConfigured(vcStage) && (
              <MultipleChoiceQuestion
                {...vcTypeFocusQuestion}
                answers={vcType}
                onAnswer={setVcType}
              />
            )}

            {checkAnswerConfigured(vcStage) &&
              checkAnswerConfigured(vcType) && (
                <Button
                  fill
                  async
                  size={ButtonSize.Large}
                  onClick={async () => {
                    const blocks: BlockInput[] = [
                      {
                        id: uuidV4(),
                        type: 'prompt',
                        sup: 'I focus on these stages of financing:',
                        content: vcStage.map((v) => v.text).join(', '),
                        sub: '',
                        color: '',
                        url: ''
                      },
                      {
                        id: uuidV4(),
                        type: 'prompt',
                        sup: 'I focus on startups in:',
                        content: vcType.map((v) => v.text).join(', '),
                        sub: '',
                        color: '',
                        url: ''
                      }
                    ]

                    await updateProfile({
                      variables: {
                        input: {
                          blocks
                        }
                      }
                    })

                    onNext()
                  }}
                >
                  Next
                </Button>
              )}
          </>
        )}

        {industry === 'startup' &&
          checkAnswerConfigured(give) &&
          checkAnswerConfigured(need) && (
            <Button
              fill
              async
              size={ButtonSize.Large}
              onClick={async () => {
                const blocks: BlockInput[] = [
                  {
                    id: uuidV4(),
                    type: 'prompt',
                    sup: 'What I need most right now is:',
                    content: need!.text,
                    sub: '',
                    color: '',
                    url: ''
                  },
                  {
                    id: uuidV4(),
                    type: 'prompt',
                    sup: 'Areas I can help you out are',
                    content: give,
                    sub: '',
                    color: '',
                    url: ''
                  }
                ]

                await updateProfile({
                  variables: {
                    input: {
                      blocks
                    }
                  }
                })

                onNext()
              }}
            >
              Next
            </Button>
          )}

        {industry === 'other' && (
          <>
            <TextQuestion
              {...question3}
              answer={otherNeed}
              onAnswer={setOtherNeed}
            />

            {!!otherNeed && (
              <TextQuestion
                {...question5}
                answer={otherGive}
                onAnswer={setOtherGive}
              />
            )}
          </>
        )}

        {industry === 'other' && (
          <Button
            fill
            async
            size={ButtonSize.Large}
            onClick={async () => {
              const blocks: BlockInput[] = [
                {
                  id: '',
                  type: 'prompt',
                  sup: 'What I need most right now is:',
                  content: otherNeed,
                  sub: '',
                  color: '',
                  url: ''
                },
                {
                  id: '',
                  type: 'prompt',
                  sup: 'Areas I can help you out are',
                  content: otherGive,
                  sub: '',
                  color: '',
                  url: ''
                }
              ]

              await updateProfile({
                variables: {
                  input: {
                    blocks
                  }
                }
              })

              onNext()
            }}
          >
            Next
          </Button>
        )}
      </FlexStack>
    </AppPageLayout>
  )
}
