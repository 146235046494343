import { ReactNode } from 'react'
import styles from './BackGroupButton.module.scss'
import { Row } from '@shareback/structural'
import { Avatar, Icon } from 'components/Elements'
import { Link } from 'react-router-dom'

export interface BackGroupButtonProps {
  photo: string
  to: string
}

export const BackGroupButton = ({ photo, to }: BackGroupButtonProps) => {
  return (
    <Link to={to} className={styles.container}>
      <Row gap="0.5em">
        {/* <Icon name="ri-arrow-left-line" size={14} /> */}
        <Avatar size={20} url={photo} />
        <div>Back to group</div>
      </Row>
    </Link>
  )
}
