import { CSSProperties, forwardRef, ReactNode, useState } from 'react'
import $ from 'classnames'
import { Icon } from '../Icon'
import { ButtonLevel } from './ButtonLevel'
import { ButtonSize } from './ButtonSize'

import styles from './Button.module.scss'

export type IconProps =
  | string
  | {
      name: string
      class?: string
      style?: CSSProperties
      size?: number | string
      placement?: 'left' | 'right'
    }

export interface ButtonProps {
  id?: string
  type?: 'button' | 'submit' | 'reset'
  className?: string
  title?: string
  icon?: IconProps
  level?: ButtonLevel
  async?: boolean
  loading?: boolean
  size?: ButtonSize
  children?: ReactNode
  disabled?: boolean
  fill?: boolean
  onClick?: () => void | Promise<void>
  /* Todo: "fill" and "width" serve similar purposes */
  width?: number | string
  style?: any
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      type = 'button',
      size = ButtonSize.Medium,
      level = ButtonLevel.Primary,
      title,
      async,
      icon,
      fill,
      loading,
      children,
      onClick,
      className,
      disabled,
      width,
      style,
      ...attributes
    }: ButtonProps,
    ref
  ) => {
    const [asyncLoading, setAsyncLoading] = useState(false)

    return (
      <button
        id={id}
        title={title}
        ref={ref}
        type={type}
        className={$(
          styles.button,
          styles[size],
          styles[level],
          {
            [styles.disabled]: disabled,
            [styles.fill]: fill,
            [styles.loading]: loading || asyncLoading,
            [styles.reverse]: typeof icon === 'object' ? icon.placement === 'right' : undefined
          },
          className
        )}
        disabled={disabled}
        onClick={async () => {
          if (onClick) {
            if (async) {
              setAsyncLoading(true)
              await onClick()
              setAsyncLoading(false)
            } else {
              onClick()
            }
          }
        }}
        /**
         * Setting minWidth makes sure that button does not shrink in side flex parent.
         */
        style={{ ...style, width, minWidth: width }}
        {...attributes}
      >
        {typeof icon === 'string' && <Icon className={styles.icon} name={icon} size="1em" />}

        {typeof icon === 'object' && (
          <Icon
            className={$(styles.icon, icon.class)}
            name={icon.name}
            size={icon.size || '1em'}
            style={icon.style}
          />
        )}

        {!!children && (
          <span className={styles.children} data-fs-unmask>
            {children}
          </span>
        )}
      </button>
    )
  }
)
