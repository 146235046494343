import { Column } from './Column'
import { FullScreenLayout } from './FullScreenLayout'
import { ReactNode } from 'react'
import { AnimatedRoute } from './AnimatedRoute/AnimatedRoute'
import { ModalProvider } from 'components/Elements'
import { Glow } from './Picture/Glow'
import { Stack } from '@shareback/structural'

export interface AppPageLayoutProps {
  children: ReactNode
  header?: ReactNode
  glow?: boolean
  gap?: number
  verticalAlignment?: 'top' | 'center'
}

export const AppPageLayout = ({
  gap = 40,
  header,
  verticalAlignment,
  children,
  glow = false
}: AppPageLayoutProps) => {
  return (
    <ModalProvider>
      <AnimatedRoute>
        <FullScreenLayout>
          {glow && <Glow />}

          <Column max={480}>
            {header}
            <Stack gap={gap} horizontal="center" vertical={verticalAlignment}>
              {children}
            </Stack>
          </Column>
        </FullScreenLayout>
      </AnimatedRoute>
    </ModalProvider>
  )
}
