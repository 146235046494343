import { Row, Spacer, Stack } from '@shareback/structural'
import { getRoleDescription } from 'v2/_utils/getRoledescription'
import { CompanySummaryFragment, GroupSummaryFragment, ProfileFragment } from 'graphql/types'
import { Button } from 'routes/ProfilePage/Button'
import { Icon, ModalManager, useModal } from 'components/Elements'
import { QRCodeOverlay } from './QRCodeOverlay'
import { CollapsibleRecordList } from './CollapsibleRecordList'
import {
  ProfileListItem,
  ProfileListItemLink,
  ProfileListItemLoading
} from 'components/ProfileListItem'
import { QRCodeScannerModal } from './QRCodeScanner/QRCodeScannerModal'
import { CreateGroupModal } from './CreateGroupModal'
import { AppLogoLink } from 'v2/AppLogoLink/AppLogoLink'
import { HomeSettingsModal } from './HomeSettingsModal'
import { NewGroupButton } from './NewGroupButton'
import { useNavigate } from 'react-router'
import { PageLayout } from 'v2/PageLayout/PageLayout'
import { ContactsButton } from './ContactsButton'
import { PoweredBy } from './PoweredBy'
// import { CasualButton } from './CasualButton'

export interface HomePageProps {
  profile?: ProfileFragment
  company?: CompanySummaryFragment
  groups: GroupSummaryFragment[]
  invites: any[]
}

export const HomePageHeader = () => {
  const modal = useModal()
  const navigate = useNavigate()

  return (
    <>
      <ModalManager component={HomeSettingsModal} name="settings" />
      <Row horizontal="spaced" fill>
        <Row gap={10}>
          <AppLogoLink />
        </Row>
        <Row gap={10}>
          <Button
            onClick={async () => {
              navigate('/notifications')
            }}
          >
            <Icon name="ri-notification-2-line" size={16} />
          </Button>

          <Button
            onClick={async () => {
              modal.show('settings')
            }}
          >
            <Icon name="ri-menu-line" size={16} />
          </Button>
        </Row>
      </Row>
    </>
  )
}

export const HomePage = ({ profile, company, groups, invites }: HomePageProps) => {
  const modal = useModal()

  const loading = !profile

  return (
    <>
      <PageLayout glow>
        <Stack fill>
          <Stack fill gap={10}>
            {loading ? (
              <ProfileListItemLoading />
            ) : (
              <ProfileListItemLink
                title={profile.name}
                photo={profile.photo}
                description={getRoleDescription(profile.role, profile.company?.name)}
                to={`/${profile.identifier}`}
              />
            )}

            <ContactsButton />
          </Stack>

          {company && (
            <Stack>
              <Row horizontal="spaced" fill>
                <h2 className="heading">Company</h2>
              </Row>

              <Stack fill gap={10}>
                <ProfileListItemLink
                  title={company.name}
                  photo={company.photo}
                  description={company.description}
                  to={`/c/${company.identifier}`}
                />
              </Stack>
            </Stack>
          )}

          <Stack fill>
            <h2 className="heading">Groups</h2>

            {invites.length > 0 && (
              <Stack fill>
                {invites.map((invite) => (
                  <ProfileListItem
                    key={invite.id}
                    disabled
                    title={invite.group.name}
                    photo={invite.group.photo}
                    description={'Access is pending...'}
                  />
                ))}
              </Stack>
            )}

            <Stack fill>
              <NewGroupButton
                onClick={() => {
                  modal.show('create-group')
                }}
              />

              <CollapsibleRecordList
                records={groups.map((group) => {
                  return {
                    title: group.name,
                    photo: group.photo,
                    description: group.description,
                    to: `/g/${group.id}`
                  }
                })}
              />
            </Stack>
          </Stack>
        </Stack>

        <PoweredBy />
      </PageLayout>
      <ModalManager component={QRCodeOverlay} name="qr-code-overlay" />
      <ModalManager component={QRCodeScannerModal} name="qr-code-scan" />
      <ModalManager component={CreateGroupModal} name="create-group" />
    </>
  )
}
