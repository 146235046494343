import { forwardRef } from 'react'
import { Button, ButtonProps } from './Button'
import { useNavigate } from 'react-router'

export interface ButtonLinkProps extends ButtonProps {
  link: string
}

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ link, onClick, ...props }, ref) => {
    const navigate = useNavigate()

    return (
      <Button
        {...props}
        ref={ref}
        onClick={() => {
          navigate(link)
          onClick?.()
        }}
      />
    )
  }
)
