export const openSystemFileDialog = async (
  accept: string
): Promise<File | undefined> => {
  const el = document.createElement('input')

  el.type = 'file'
  el.accept = accept
  el.style.cssText = 'display: none'

  return new Promise((resolve) => {
    el.addEventListener('change', function listener(event) {
      const file = el?.files?.[0]

      el.removeEventListener('change', listener)
      el.remove()

      resolve(file)
    })

    el.addEventListener('blur', (event) => {
      console.log(el.files?.length)
    })

    el.addEventListener('focus', (event) => {
      console.log(el.files?.length)
    })

    /* Safari requires the element to be in the DOM to work consistently */
    document.body.appendChild(el)
    el.click()
  })
}
