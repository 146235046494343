import {
  Button,
  ButtonLevel,
  Icon,
  Modal,
  ModalProps,
  useModal
} from 'components/Elements'
import { FlexRow, FlexStack } from 'components/Layout'
import {
  useAcceptGroupInviteMutation,
  useGetGroupMembersQuery,
  useRejectGroupInviteMutation
} from 'graphql/types'
import { Request } from './Request/Request'

import styles from './GroupMembersModal.module.scss'

export const GroupMembersModal = (props: ModalProps) => {
  const groupId = props.attributes!.groupId as string

  const modal = useModal()

  const { data, loading, refetch } = useGetGroupMembersQuery({
    variables: {
      id: groupId
    }
  })

  const [acceptGroupInvite] = useAcceptGroupInviteMutation()
  const [rejectGroupInvite] = useRejectGroupInviteMutation()

  const invites = data?.group?.invites ?? []

  const update = async () => {
    await refetch()

    if (invites.length === 0) {
      modal.hide()
    }
  }

  return (
    <Modal {...props}>
      <FlexStack className={styles.modal} gap={20}>
        <FlexStack gap={20}>
          {invites.map((invite) => (
            <Request
              key={invite.id}
              from={invite.profile}
              onAccept={async () => {
                await acceptGroupInvite({
                  variables: {
                    inviteId: invite.id
                  }
                })

                await update()
              }}
              onReject={async () => {
                await rejectGroupInvite({
                  variables: {
                    inviteId: invite.id
                  }
                })

                await update()
              }}
            />
          ))}
        </FlexStack>
      </FlexStack>
    </Modal>
  )
}
