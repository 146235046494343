import { useAuth } from 'auth'
import {
  Button,
  ButtonSize,
  Input,
  Modal,
  ModalProps,
  useModal
} from 'components/Elements'
import { FlexStack } from 'components/Layout'
import {
  GetHomePageDocument,
  GroupPrivacy,
  useCreateGroupMutation
} from 'graphql/types'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { PrivacyToggle } from './PricayToggle'

export const CreateGroupModal = (props: ModalProps) => {
  const [createGroup] = useCreateGroupMutation()

  const auth = useAuth()
  const navigate = useNavigate()

  const profile = auth.profile!

  const [name, setName] = useState('')

  const [description, setDescription] = useState(
    `Group created by ${profile.name}`
  )

  const [privacy, setPrivacy] = useState(false)

  return (
    <Modal {...props}>
      <FlexStack gap={24}>
        <FlexStack fill gap={14}>
          <Input
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <Input
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />

          <PrivacyToggle value={privacy} onChange={setPrivacy} />
        </FlexStack>

        <Button
          async
          size={ButtonSize.Large}
          onClick={async () => {
            if (name.trim().length < 3) {
              return
            }

            const { data } = await createGroup({
              variables: {
                input: {
                  name,
                  description: description,
                  privacy: privacy ? GroupPrivacy.Private : GroupPrivacy.Public
                }
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: GetHomePageDocument
                }
              ]
            })

            if (data) {
              const id = data.createGroup.id
              // modal.hide()
              navigate(`/g/${id}`)
            }
          }}
        >
          Create group
        </Button>
      </FlexStack>
    </Modal>
  )
}
