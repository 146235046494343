import {
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { getMainDefinition } from '@apollo/client/utilities'
import { auth } from './auth'
import { errors } from './errors'
import { httpLink } from './http'
import { wsLink } from './ws'

const retry = new RetryLink({
  attempts: {
    max: 3
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  from([retry, auth, httpLink])
)

export const links = from([errors, splitLink])

export class BlitzApolloClient extends ApolloClient<NormalizedCacheObject> {
  constructor() {
    super({
      link: links,
      cache: new InMemoryCache()
    })
  }
}
