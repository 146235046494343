// import { AppLogoLink } from 'v2/AppLogoLink/AppLogoLink'
// import { AppPageLayout } from 'v2/AppPageLayout'
// import { Button } from 'routes/ProfilePage/Button'
import { copy } from 'hooks/useCopy'
import { FlexRow, FlexStack, Spacer } from 'components/Layout'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'
// import { getRoleDescription } from 'v2/_utils/getRoledescription'
import { GroupFragment, GroupMemberFragment, MatchFragment } from 'graphql/types'
import { GroupInvitesButton } from './GroupInvitesButton'
import { GroupMembersModal } from './GroupMembersModal'
import { Icon, ModalManager, useModal } from 'components/Elements'
import { ProfileAbout } from 'components/Profiles/ProfileAbout'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
// import { ProfileListItemLink } from 'components/ProfileListItem'
import { Stack } from '@shareback/structural'
import { share } from 'v2/_utils/share'
import { toast } from 'react-hot-toast'
import { useGroupInvites } from './useGroupInvites'
import { useNavigate } from 'react-router'
import { ViewBlocks } from 'components/Blocks/ViewBlocks'
import { MatchmakingPage } from 'routes/MatchmakingPage/MatchmakingPage'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { PageLayout } from 'v2/PageLayout/PageLayout'
import { PendingProfilesCard } from 'components/Records/PendingProfilesCard'

export interface GroupProps {
  group: GroupFragment
  membership: GroupMemberFragment
}

export const GroupPage = ({ group, membership }: GroupProps) => {
  const modal = useModal()
  const navigate = useNavigate()
  const invites = useGroupInvites(group.id, membership.role !== 'admin')

  return (
    <>
      <PageLayout>
        <Stack gap={40}>
          <Stack fill>
            <ProfileAvatar picture={group.photo} />
            <ProfileAbout title={group.name} description={group.description} />
            <FlexRow horizontal="middle" fill>
              <CasualButton
                style={{ width: 'fit-content' }}
                onClick={async () => {
                  try {
                    await share(
                      `${group.name}`,
                      `Join ${group.name} on Blitz`,
                      `${window.location.origin}/g/${group.id}`
                    )
                  } catch (error) {
                    console.warn('[HANDLED]')
                    console.warn('Error sharing', error)

                    copy(`${window.location.origin}/i/${group.id}`)

                    toast('Group link copied', {
                      style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff'
                      }
                    })
                  }
                }}
                label="Share"
                icon="ri-share-box-line"
              />
            </FlexRow>
          </Stack>

          {group.blocks.length > 0 && <ViewBlocks blocks={group.blocks} />}

          {invites.length > 0 && (
            <PendingProfilesCard
              photos={invites.slice(0, 5).map((v) => v.profile.photo)}
              onClick={() => {
                modal.show('group-members', {
                  groupId: group.id
                })
              }}
            >
              {invites.length} Pending requests
            </PendingProfilesCard>
          )}

          <MatchmakingPage viewer={membership.profile} groupId={group.id} />

          <Spacer height={60} />

          {membership.role === 'admin' && (
            <FloatingActionMenu
              actions={[
                {
                  label: 'Manage group',
                  // color: '#b7fbc9',
                  async action() {
                    navigate(`/g/${group.id}/edit`)
                  }
                }
              ]}
            />
          )}
        </Stack>
      </PageLayout>

      <ModalManager name="group-members" component={GroupMembersModal} />
    </>
  )
}
