import { createBlock } from 'components/BlocksContext'
import { DeepPartial, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { createYupValidationResolver } from './useYupValidationResolver'
import { useState } from 'react'

export function useBlockEditForm<T extends object>(
  type: string,
  defaultValues: DeepPartial<T>,
  schema: yup.ObjectSchema<T>
) {
  const fields = Object.keys(defaultValues) as Array<keyof T>

  const [resolver] = useState(() => {
    return createYupValidationResolver(schema)
  })

  const form = useForm<T>({
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
    resolver
  })

  const submit = (values: T) => {
    const block = createBlock(type, {
      ...values
    })

    return block
  }

  return { form, fields, submit } as const
}
