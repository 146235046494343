import { ApolloLink } from '@apollo/client'
import { getAccessToken } from 'auth'
/**
 * Sets headers on every request
 */
export const auth = new ApolloLink((operation, forward) => {
  operation.setContext((request: any) => {
    const token = getAccessToken()

    const headers: Record<string, string> = {
      ...request.headers
    }

    if (token) {
      headers['authorization'] = `Bearer ${token}`
    }

    return {
      headers
    }
  })

  return forward(operation).map((data) => {
    return data
  })
})
