import { BlurBackground } from 'components/BlurBackground'
import { Button, ButtonSize, Input } from 'components/Elements'
import { Centered, FlexRow, FlexStack, useViewport } from 'components/Layout'
import { Theme } from 'components/Theme'
import { useRequestAccountMutation } from 'graphql/types'
import { useState } from 'react'

import styles from './RequestAccount.module.scss'

export const RequestAccount = () => {
  const [requested, setRequested] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')

  const viewport = useViewport()
  const isMobile = viewport.width < 800
  const [requestAccount] = useRequestAccountMutation()

  const heading = (
    <BlurBackground>
      <h1 className={styles.title}>Blitz</h1>
    </BlurBackground>
  )

  const form = requested ? (
    <FlexStack
      gap={20}
      fill
      style={{ maxWidth: 400, padding: 10, textAlign: 'center', fontSize: 20 }}
    >
      Thank you for the application
      <br />
      <br />
      We will email you as soon as your request is processed
      <br />
      <br />
      🙌
    </FlexStack>
  ) : (
    <FlexStack gap={20} fill style={{ maxWidth: 400, padding: 10 }}>
      <FlexRow horizontal="middle">
        <h2>Claim your link</h2>
      </FlexRow>

      <Input
        placeholder="Full name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <Input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <FlexStack gap={8}>
        <Input
          value={username}
          placeholder="Link"
          onChange={(event) =>
            setUsername(event.target.value.trim().toLowerCase())
          }
        />

        {username && (
          <div style={{ color: '#777', fontSize: 12 }}>
            Claim link blitz.so/{username}
          </div>
        )}
      </FlexStack>

      <Button
        size={ButtonSize.Large}
        onClick={async () => {
          const response = await requestAccount({
            variables: {
              name,
              username,
              email
            }
          })

          if (response.data?.requestAccount) {
            setRequested(true)
          }
        }}
      >
        Join beta
      </Button>
    </FlexStack>
  )

  if (isMobile) {
    return (
      <>
        <div style={{ height: 200 }}>{heading}</div>

        <Theme.Container verticalPadding={false}>{form}</Theme.Container>
      </>
    )
  }

  return (
    <Centered window>
      {heading}
      <Centered>{form}</Centered>
    </Centered>
  )
}
