import { Absolute, FlexRow, FlexStack, Spacer } from 'components/Layout'
import {
  Input,
  Textarea,
  ButtonLevel,
  ButtonLink,
  Button
} from 'components/Elements'
import { useEffect, useState } from 'react'
import { CompanyFragment, useUpdateCompanyMutation } from 'graphql/types'
import { useNavigate } from 'react-router'
import { ChangePictureButton } from 'components/Theme/ChangePictureButton'
import { AppPageLayout } from 'v2/AppPageLayout'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { ProfileAbout } from 'components/Profiles/ProfileAbout'
import { EditBlocks } from 'components/Blocks/EditBlocks'
import { useBlocksStore } from 'components/BlocksContext'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'

export interface EditCompanyPageProps {
  company: CompanyFragment
}

export const EditCompanyPage = ({ company }: EditCompanyPageProps) => {
  const navigate = useNavigate()

  const [photo, setPhoto] = useState(company.photo || '')
  const [name, setName] = useState(company.name || '')
  const [description, setDescription] = useState(company.description || '')
  const blocksStore = useBlocksStore()

  useEffect(() => {
    blocksStore.import(company.blocks)
  }, [company.blocks])

  const [updateCompany] = useUpdateCompanyMutation()

  return (
    <AppPageLayout header={<Spacer height={40} />}>
      <FlexStack>
        <ProfileAvatar picture={photo} />
        <ProfileAbout title={name} description={description} />

        <Absolute right={20} top={20}>
          <ChangePictureButton
            onChange={(url) => {
              setPhoto(url)
            }}
          />
        </Absolute>
      </FlexStack>

      <FlexStack fill gap={10}>
        <Input
          fill
          placeholder="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <Textarea
          fill
          minRows={2}
          placeholder="About (optional)"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </FlexStack>

      <EditBlocks store={blocksStore} />

      <Spacer height={60} />

      <FloatingActionMenu
        actions={[
          {
            label: 'Go back',
            action() {
              navigate(`/c/${company.identifier}`)
            }
          },
          {
            label: 'Save changes',
            // color: '#f8fd99',
            color: '#b7fbc9',
            async action() {
              await updateCompany({
                variables: {
                  input: {
                    name,
                    description,
                    photo,
                    blocks: blocksStore.blocks
                  }
                }
              })

              navigate(`/c/${company.identifier}`)
            }
          }
        ]}
      />
    </AppPageLayout>
  )
}
