import React, { useEffect, useState } from 'react'
import styles from './Flash.module.scss'

interface Props {
  duration?: number
  onCompleted?: () => void
}

export const Flash = ({ duration = 800, onCompleted }: Props) => {
  const [showAnimation, setShowAnimation] = useState(false)

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>

    if (showAnimation) {
      timerId = setTimeout(() => {
        setShowAnimation(false)
        onCompleted?.()
      }, duration)
    }

    return () => clearTimeout(timerId)
  }, [showAnimation, duration, onCompleted])

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true)
    }, 1)
  }, [])

  return showAnimation ? <div className={styles.container} /> : null
}
