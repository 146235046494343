import { MediaClient } from 'graphql/media'
import { PictureHeaderButton } from './PictureHeaderButton'
import { openSystemFileDialog } from './upload'

export interface ChangePictureButtonProps {
  label?: string
  onChange: (url: string) => void
}

const defaultLabel = 'Change photo'

export const ChangePictureButton = ({
  label = defaultLabel,
  onChange
}: ChangePictureButtonProps) => {
  return (
    <PictureHeaderButton
      onClick={async () => {
        try {
          const file = await openSystemFileDialog('image/*')

          if (file) {
            const url = await MediaClient.uploadAvatar(file)

            if (url) {
              onChange?.(url)
            }
          }
          console.log(file)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {label}
    </PictureHeaderButton>
  )
}
