import { useState } from 'react'
import { Template } from 'routes/Auth/Template/Template'
import { FlexStack } from 'components/Layout'
import {
  Button,
  ButtonLink,
  Text,
  Input,
  ButtonLevel
} from 'components/Elements'
import { useCreateMagicLinkMutation } from 'graphql/types'
import { useContinueUrl } from '../useContinueUrl'
import { useLocation } from 'react-router'

export const AuthWithEmail = () => {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  const continueUrl = useContinueUrl()
  const location = useLocation()

  const [createMagicLinkMutation, { loading }] = useCreateMagicLinkMutation()

  const onSubmit = async () => {
    const response = await createMagicLinkMutation({
      variables: {
        input: {
          email,
          redirectUrl: continueUrl
        }
      }
    })

    setSubmitted(!!response.data?.createMagicLink)
  }

  return (
    <Template title="Sign in">
      {submitted ? (
        <FlexStack gap="2em">
          <Text>
            We’ve sent a magic link to <b>{email}</b>, click it to proceed. If
            you don’t see anything in your mailbox, don’t forget to check your
            spam folder.
          </Text>
          <ButtonLink level={ButtonLevel.Secondary} link="/auth">
            Back
          </ButtonLink>
        </FlexStack>
      ) : (
        <>
          <Input
            fill
            placeholder="Enter your email..."
            value={email}
            onChange={(event) => {
              const value = event.target.value
              const email = value.trim().toLocaleLowerCase()

              setEmail(email)
            }}
          />
          <Button
            fill
            icon="ri-magic-line"
            loading={loading}
            onClick={onSubmit}
          >
            Send magic link
          </Button>

          <ButtonLink
            link={`/auth${location.search}`}
            fill
            level={ButtonLevel.Secondary}
          >
            Back
          </ButtonLink>
        </>
      )}
    </Template>
  )
}
