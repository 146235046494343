import cn from 'classnames'
import { ReactNode } from 'react'
import ReactModal, { Props } from 'react-modal'

import styles from './Modal.module.scss'

export interface ModalProps<
  Attr extends Record<string, any> = Record<string, any>
> extends Props {
  maxWidth?: number
  height?: number | string
  closable?: boolean
  attributes?: Attr
  callbacks?: Record<string, Function>
  children?: ReactNode
}

export const Modal = ({
  children,
  className,
  overlayClassName,
  maxWidth,
  height,
  ...props
}: ModalProps) => (
  <ReactModal
    shouldReturnFocusAfterClose={false}
    closeTimeoutMS={300}
    className={
      typeof className === 'object' ? className : cn(styles.modal, className)
    }
    style={{ content: { maxWidth, height } }}
    overlayClassName={styles.overlay}
    {...props}
  >
    {children}
  </ReactModal>
)

export default Modal
