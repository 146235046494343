import $ from 'classnames'

import styles from './Question.module.scss'
import { FlexRow, FlexStack } from 'components/Layout'
import { Input } from 'components/Elements'
import { AnswerOption } from './Question'

export interface MultipleChoiceQuestionProps {
  message: string
  options: AnswerOption[]
  answers: AnswerOption[]
  hasOther?: boolean
  otherPlaceholder?: string
  onAnswer: (answers: AnswerOption[]) => void
}

export const MultipleChoiceQuestion = ({
  message,
  options,
  answers,
  hasOther,
  otherPlaceholder,
  onAnswer
}: MultipleChoiceQuestionProps) => {
  const otherAnswer = answers.find((answer) => answer.value === 'other')

  return (
    <FlexStack fill gap={30} className={styles.container}>
      <FlexStack gap={20}>
        <div className={styles.message}>{message}</div>

        <FlexRow gap={10} wrap fill>
          {options.map((option) => (
            <div
              key={option.value}
              className={$(
                styles.option,
                answers.findIndex((answer) => answer.value === option.value) !==
                  -1 && styles.selected
              )}
              onClick={async () => {
                let updatedAnswers

                let index = answers.findIndex(
                  (answer) => answer.value === option.value
                )

                if (index === -1) {
                  updatedAnswers = [...answers, { ...option }]
                } else {
                  updatedAnswers = answers.filter(
                    (answer) => answer.value !== option.value
                  )
                }

                onAnswer(updatedAnswers)
              }}
            >
              {option.text}
            </div>
          ))}

          {hasOther && (
            <div
              className={$(styles.option, !!otherAnswer && styles.selected)}
              onClick={async () => {
                let updatedAnswers

                if (otherAnswer) {
                  updatedAnswers = answers.filter(
                    (answer) => answer.value !== 'other'
                  )

                  onAnswer(updatedAnswers)
                } else {
                  updatedAnswers = [...answers, { value: 'other', text: '' }]
                }

                onAnswer(updatedAnswers)
              }}
            >
              Other
            </div>
          )}
        </FlexRow>
      </FlexStack>

      {otherAnswer && (
        <Input
          value={otherAnswer.text}
          placeholder={otherPlaceholder || 'Type here...'}
          onChange={(event) => {
            const updatedOtherAnswer = {
              ...otherAnswer,
              text: event.target.value
            }

            const updatedAnswers = answers.map((item) => {
              return item.value === 'other' ? updatedOtherAnswer : item
            })

            onAnswer(updatedAnswers)
          }}
        />
      )}
    </FlexStack>
  )
}
