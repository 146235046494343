import React, { ReactNode, useEffect } from 'react'

const viewportContext = React.createContext({
  width: window.innerWidth,
  height: window.innerWidth
})

export interface ViewportProviderProps {
  children: ReactNode
}
/**
 * The ViewportProvider component is created to wrap the application components
 * and provide access to the viewport dimensions via the context.
 */
export const ViewportProvider = ({ children }: ViewportProviderProps) => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  let resizeTimeout: ReturnType<typeof setTimeout> | null = null // Initialize a timeout variable

  const handleWindowResize = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout) // Clear the timeout if it's already running
    }

    resizeTimeout = setTimeout(() => {
      // Set a new timeout
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)

      resizeTimeout = null // Reset the timeout variable
    }, 100) // Wait for 100ms before calling the function
  }

  // Add an event listener for window resize events

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  return React.useContext(viewportContext)
}
