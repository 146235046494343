import { FlexStack } from 'components/Layout'
import { useState } from 'react'

import styles from './CollapsibleRecordList.module.scss'
import {
  ProfileListItem,
  ProfileListItemLink,
  ProfileListItemLinkProps
} from 'components/ProfileListItem'
import { Button } from 'components/Records'
import { Row } from '@shareback/structural'

export interface CollapsibleRecordListProps {
  records: ProfileListItemLinkProps[]
}

export const CollapsibleRecordList = ({ records }: CollapsibleRecordListProps) => {
  const [collapsed, setCollapsed] = useState(true)
  const max = collapsed ? 3 : records.length

  return (
    <FlexStack fill>
      {records.slice(0, max).map((record, index) => (
        <ProfileListItemLink
          key={index}
          title={record.title}
          photo={record.photo}
          description={record.description}
          to={record.to}
        />
      ))}

      {collapsed && records.length > 3 && (
        <Row fill horizontal="center" style={{ padding: 20 }} onClick={() => setCollapsed(false)}>
          <Button>Show more</Button>
        </Row>
      )}
    </FlexStack>
  )
}
