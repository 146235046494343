import { ReactNode, useState } from 'react'
import cn from 'classnames'
import styles from './PictureHeader.module.scss'
import { openSystemFileDialog } from './upload'
import { MediaClient } from 'graphql/media'
import { PictureHeaderButton } from './PictureHeaderButton'
import { FlexRow } from 'components/Layout'

export interface PictureHeader {
  inject?: ReactNode
  maxHeight?: number
  picture?: string
  title?: ReactNode
  subtitle?: string
  canExpand?: boolean
  // canChangePicture?: boolean
  // onChangePicture?: (url: string) => void
}

export const PictureHeader = ({
  picture,
  title,
  subtitle,
  maxHeight,
  canExpand,
  inject
}: PictureHeader) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={styles.container} style={{ maxHeight }}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${picture})` }}
      />

      <FlexRow horizontal="spaced" className={styles.controls}>
        <FlexRow></FlexRow>
        <FlexRow>{inject}</FlexRow>
      </FlexRow>

      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        <div
          className={cn(styles.subtitle, expanded && styles.expanded)}
          onClick={() => {
            if (canExpand) {
              setExpanded(!expanded)
            }
          }}
        >
          {subtitle}
        </div>
      </div>
    </div>
  )
}
