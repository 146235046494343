import React, { useEffect } from 'react'
import styles from './Camera.module.scss'

interface Props {
  videoRef: React.RefObject<HTMLVideoElement>
  onError?: (error: Error) => void
  onPermission?: () => void
}

const Camera = ({ videoRef, onError, onPermission }: Props) => {
  const handleSuccess = (stream: MediaStream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream
      onPermission?.()
    }
  }

  const handleError = (error: Error) => {
    onError?.(error)
    console.warn(error)
  }

  useEffect(() => {
    const mediaDevices = navigator.mediaDevices
    let cachedStream: MediaStream

    mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: 'user'
        }
      })
      .then((stream: MediaStream) => {
        cachedStream = stream
        handleSuccess(stream)
      })
      .catch(handleError)

    return () => {
      cachedStream?.getTracks().forEach((track) => track.stop())

      if (videoRef.current) {
        videoRef.current.srcObject = null
      }
    }
  }, [videoRef])

  return (
    <div className={styles.container}>
      <video
        className={styles.video}
        ref={videoRef}
        autoPlay
        muted
        playsInline
      />
    </div>
  )
}

export default Camera
