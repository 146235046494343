import {
  Avatar,
  Icon,
  Modal,
  ModalProps,
  Textarea,
  useModal
} from 'components/Elements'
import {
  BlockFragment,
  GetCommentsDocument,
  useCreateCommentMutation,
  useGetCommentsQuery,
  useRemoveCommentMutation
} from 'graphql/types'
import { CommentButton } from './CommentButton'
import { Row, Stack } from '@shareback/structural'
import { useAuth } from 'auth'
import styles from './PromptModal.module.scss'
import { useState } from 'react'
import { useNavigate } from 'react-router'
// import { Link } from 'react-router-dom'

let options = [
  'Looking forward to connecting',
  'Agreed',
  'I can help',
  'Let’s talk more about this'
]

export const PromptModal = (props: ModalProps) => {
  const block = props.attributes!.block as BlockFragment

  // const canComment = (props.attributes!.canReply as boolean) ?? true
  // const canManage = (props.attributes!.canManage as boolean) ?? false

  const auth = useAuth()

  const [createComment] = useCreateCommentMutation()
  const [removeComment] = useRemoveCommentMutation()

  const { data, loading } = useGetCommentsQuery({
    variables: {
      blockId: block.id
    },
    skip: auth.loading
  })

  const navigate = useNavigate()
  const modal = useModal()

  const comments = data?.comments ?? []
  const [message, setMessage] = useState('')

  const canEdit =
    !loading &&
    !!auth.profile &&
    !comments.some((v) => v.author.id === auth.profile?.id)

  const [edit, setEdit] = useState(false)

  return (
    <Modal {...props}>
      <Stack horizontal="center" gap={40} fill>
        <div className={styles.component}>
          <div className={styles.title}>{block.sup}</div>
          <div className={styles.description}>{block.content}</div>
        </div>

        {(canEdit || edit) && (
          <Stack fill gap={10}>
            <Row fill gap={20} vertical="top">
              <Row fill gap={10} wrap>
                {options.map((option) => (
                  <div
                    key={option}
                    className={styles.option}
                    onClick={async () => {
                      await createComment({
                        variables: {
                          blockId: block.id,
                          message: option
                        },
                        refetchQueries: [
                          {
                            query: GetCommentsDocument,
                            variables: {
                              blockId: block.id
                            }
                          }
                        ]
                      })

                      setEdit(false)
                    }}
                  >
                    {option}
                  </div>
                ))}
              </Row>

              <Avatar url={auth.profile?.photo} size={48} />
            </Row>

            {/* <Row fill gap={20} style={{ paddingLeft: 20 }}>
              <Avatar url={auth.profile?.photo} size={56} />
              <Textarea
                maxRows={3}
                spellCheck
                allowNewline={false}
                fill
                value={message}
                placeholder="Type your message here..."
                onChange={(event) => setMessage(event.target.value)}
              />

              <CommentButton
                onClick={async () => {
                  if (message.trim().length === 0) {
                    return
                  }

                  await createComment({
                    variables: {
                      blockId: block.id,
                      message
                    },
                    refetchQueries: [
                      {
                        query: GetCommentsDocument,
                        variables: {
                          blockId: block.id
                        }
                      }
                    ]
                  })

                  setEdit(false)
                  setMessage('')
                }}
              />
            </Row> */}
          </Stack>
        )}

        {!loading && (
          <Stack fill gap={10}>
            {comments.map((comment) => (
              <Row fill horizontal="right" gap={20} key={comment.id}>
                {auth.profile?.id === comment.author.id && (
                  <Row gap={10}>
                    <div
                      className={styles.button}
                      onClick={async () => {
                        await removeComment({
                          variables: {
                            blockId: block.id
                          },
                          refetchQueries: [
                            {
                              query: GetCommentsDocument,
                              variables: {
                                blockId: block.id
                              }
                            }
                          ]
                        })
                      }}
                    >
                      <Icon name="ri-close-line" />
                    </div>

                    <div
                      className={styles.button}
                      onClick={() => {
                        setEdit(true)
                        setMessage(comment.message)
                      }}
                    >
                      <Icon name="ri-pencil-line" />
                    </div>
                  </Row>
                )}
                <div className={styles.message}>{comment.message}</div>

                <Avatar
                  clickable
                  url={comment.author.photo}
                  size={48}
                  onClick={() => {
                    modal.hide()
                    navigate(`/${comment.author.identifier}`)
                  }}
                />
              </Row>
            ))}
          </Stack>
        )}
      </Stack>
    </Modal>
  )
}
