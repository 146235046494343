import { Tooltip } from 'components/Elements/Tooltip'
import { forwardRef } from 'react'
import { AvatarBase, AvatarBaseProps } from './AvatarBase'

interface AvatarProps extends AvatarBaseProps {
  tooltip?: string
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ tooltip, ...props }, ref) => (
    <Tooltip message={tooltip}>
      <AvatarBase {...props} ref={ref} />
    </Tooltip>
  )
)
