import { Icon } from 'components/Elements'
import { Row } from '@shareback/structural'
import $ from 'classnames'

import styles from './Tags.module.scss'

export interface TagsProps {
  tags: TagInput[]
}

interface TagInput {
  type: string
  text: string
  highlighted?: boolean
  color?: string
  meta?: string
}

interface TagProps extends TagInput {
  icon: string
  title: string
}

export const templates = [
  {
    title: 'Location',
    type: 'location',
    icon: 'ri-map-pin-2-line'
  },
  {
    title: 'Industry',
    type: 'industry',
    icon: 'ri-bar-chart-2-line'
  },
  {
    title: 'Linked In',
    type: 'linkedin',
    icon: 'ri-linkedin-box-line',
    clickable: true
  },
  {
    title: 'Email',
    type: 'email',
    icon: 'ri-mail-open-line',
    clickable: true
  }
]

const LINKEDIN_PREFIX = 'https://www.linkedin.com/in/'

const formatTag = (tag: TagProps) => {
  const data = { ...tag }

  const text = data.text.trim()

  if (data.text.startsWith(LINKEDIN_PREFIX)) {
    data.meta = text
    data.text = text.substring(LINKEDIN_PREFIX.length)
  } else {
    data.meta = LINKEDIN_PREFIX + text
    data.text = text
  }

  return data
}

export const Tags = ({ tags }: TagsProps) => {
  if (tags.length === 0) {
    return null
  }

  const items = templates.reduce<TagProps[]>((array, template) => {
    let tag = tags.find((tag) => tag.type === template.type)

    if (tag && tag.text) {
      array.push(
        formatTag({
          ...template,
          text: tag.text,
          highlighted: !!tag.highlighted
        })
      )
    }

    return array
  }, [])

  return (
    <Row gap="1.5em" fill wrap horizontal="center">
      {items.map((tag) => (
        <Row
          vertical="center"
          className={$(styles.tag, tag.highlighted && styles.highlighted, tag.type === 'linkedin' && styles.clickable)}
          gap="0.5em"
          key={tag.type}
          onClick={() => {
            if (tag.type === 'linkedin') {
              const url = tag.meta

              window.open(url, '_blank')
            }
          }}
        >
          <Icon className={styles.icon} name={tag.icon} />
          <div>{tag.text}</div>
        </Row>
      ))}
    </Row>
  )
}
