import localhost from './localhost'
import production from './production'

console.log(`Environment: ${process.env.NODE_ENV}`, window.location.host)

const config = window.location.host.endsWith('localhost:3000')
  ? localhost
  : production

export default config
