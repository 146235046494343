import { FieldLabel } from 'components/Elements'
import { useState } from 'react'
import Toggle from 'react-toggle'

import styles from './PrivacyToggle.module.scss'
import { Row, Stack } from '@shareback/structural'

import 'react-toggle/style.css'
import './Toggle.scss'

const privacyOptions = [
  {
    value: 'private',
    title: 'Private',
    description:
      'Anyone with the link can request access, administrator will need to approve each request'
  },
  {
    value: 'public',
    title: 'Public',
    description: 'Anyone with the link can join'
  }
]

export interface PrivacyToggleProps {
  value: boolean
  onChange: (value: boolean) => void
}

export const PrivacyToggle = ({ value, onChange }: PrivacyToggleProps) => {
  // const [value, setValue] = useState(false)
  const option = value ? privacyOptions[0] : privacyOptions[1]

  return (
    <div>
      <FieldLabel>Privacy</FieldLabel>

      <Row gap={20} fill className={styles.body}>
        <Toggle
          className="toggle"
          checked={value}
          icons={false}
          onChange={(event) => onChange(event.target.checked)}
        />

        <Stack gap={6}>
          <div className={styles.title}>{option.title}</div>
          <div className={styles.description}>{option.description}</div>
        </Stack>
      </Row>
    </div>
  )
}
