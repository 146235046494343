import { Avatar } from 'components/Elements'
import styles from './Faces.module.scss'

export interface FacesProps {
  size: number
  background: string
  foreground: string
  ratio?: number
}

export const Faces = ({ foreground, background, size, ratio = 0.715 }: FacesProps) => {
  const pictureSize = Math.floor(size * ratio)

  return (
    <div className={styles.container} style={{ width: `${size}px`, height: `${size}px` }}>
      <div className={styles.topLeft}>
        <Avatar url={background} size={pictureSize} />
      </div>
      <div className={styles.bottomRight}>
        <Avatar url={foreground} size={pictureSize} />
      </div>
    </div>
  )
}
