import { Emoji } from '../Emoji'
import { Card } from '../Card'
import { Button } from '../Button'
import { Row } from '@shareback/structural'
import { Avatar, Icon } from 'components/Elements'

import styles from '../Shared.module.scss'

export interface IncomingConnectionCardProps {
  photo: string
  name: string
  link: string
  onDecline: () => void
  onAccept: () => void
}
export const IncomingConnectionCard = ({
  photo,
  link,
  name,
  onDecline,
  onAccept
}: IncomingConnectionCardProps) => (
  <Card
    avatar={
      <Emoji emoji="👋">
        <Avatar url={photo} size={64} />
      </Emoji>
    }
    footer={
      <Row fill gap={8} horizontal="right">
        <Button onClick={onDecline}>
          <Icon name="ri-close-line" />
        </Button>

        <Button onClick={onAccept}>Connect</Button>
      </Row>
    }
  >
    <div className={styles.message}>
      <a className={styles.link} href={link} target="_blank">
        {name}
      </a>
      {'  would like to connect with you'}
    </div>
  </Card>
)
