import { getRoleDescription } from 'v2/_utils/getRoledescription'
import {
  GetGroupMembersPageDocument,
  MatchStatus,
  ProfileFragment,
  useCreateMatchMutation,
  useGetGroupMembersPageQuery,
  useGetGroupTagsQuery
} from 'graphql/types'
import { ProfileListItem } from 'components/ProfileListItem'
import { Row, Stack } from '@shareback/structural'
import { useNavigate } from 'react-router'
import { WaveButton } from './WaveButton'
import { useState } from 'react'
import { Select } from './Select'
import { useAuth } from 'auth'

import styles from './GroupPage.module.scss'

export interface GroupProps {
  viewer: ProfileFragment
  groupId: string
}

export interface MatchButtonsProps {
  viewerId: string
  groupId: string
  targetId: string
  matchStatus?: MatchStatus
  matchSenderId?: string
  contactId?: string
}

const MatchButtons = ({
  viewerId,
  groupId,
  targetId,
  contactId,
  matchStatus,
  matchSenderId
}: MatchButtonsProps) => {
  const [createMatchMutation] = useCreateMatchMutation()

  const createMatch = async () => {
    await createMatchMutation({
      variables: {
        groupId: groupId,
        receiverId: targetId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetGroupMembersPageDocument,
          variables: {
            id: groupId
          }
        }
      ]
    })
  }

  if (contactId || matchStatus === 'APPROVED') {
    return <div style={{ color: 'rgb(183, 251, 201)', fontSize: 14 }}>Connected</div>
  }

  if (!matchStatus) {
    return <WaveButton onClick={createMatch} />
  }

  if (matchStatus === 'PENDING') {
    if (matchSenderId === viewerId) {
      return <div className={styles.waveSent}>Requested</div>
    }

    return <WaveButton onClick={createMatch} />
  }

  return null
}

const useSearchTags = (groupId: string) => {
  const { data } = useGetGroupTagsQuery({
    variables: {
      id: groupId
    }
  })

  const industry = data?.groupTags.industry ?? []
  const location = data?.groupTags.location ?? []

  return { industry, location }
}

export const MatchmakingPage = ({ viewer, groupId }: GroupProps) => {
  const [industry, setIndustry] = useState<string>()
  const [location, setLocation] = useState<string>()

  const navigate = useNavigate()

  const [members, setMembers] = useState<any[]>([])

  const { loading } = useGetGroupMembersPageQuery({
    fetchPolicy: 'cache-and-network',

    variables: {
      id: groupId,
      industry,
      location
    },
    onCompleted(data) {
      setMembers(data.group?.members ?? [])
    }
  })

  const searchTags = useSearchTags(groupId)

  return (
    <Stack fill horizontal="center" gap="1em">
      {/* <Row fill horizontal="center">
        <TabButton
          tab={tab}
          setTab={setTab}
          tabs={[
            { icon: 'ri-list-check', value: 'list' },
            { icon: 'ri-layout-grid-fill', value: 'grid' }
          ]}
        />
      </Row> */}

      <Row gap="1em" fill>
        <Select
          value={industry}
          options={searchTags.industry}
          placeholder="Industry"
          onChange={setIndustry}
        />

        <Select
          value={location}
          options={searchTags.location}
          placeholder="Location"
          onChange={setLocation}
        />
      </Row>

      <Stack horizontal="center" fill>
        {members.map(({ profile, match, contact }) => (
          <ProfileListItem
            key={profile.id}
            title={profile.name}
            description={getRoleDescription(profile.role, profile.company?.name)}
            photo={profile.photo}
            onClick={() => {
              navigate(`/${profile.identifier}?from_group=${groupId}`)
            }}
            more={
              profile.id !== viewer.id && (
                <Row
                  shrink={false}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                >
                  <MatchButtons
                    viewerId={viewer.id}
                    targetId={profile.id}
                    contactId={contact?.id}
                    matchStatus={match?.status}
                    matchSenderId={match?.senderId}
                    groupId={groupId}
                  />
                </Row>
              )
            }
          />
        ))}
      </Stack>
    </Stack>
  )
}
