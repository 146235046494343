import { useAuth } from 'auth'
import { Centered } from 'components/Layout'
import { Selfie } from 'components/Selfie/Selfie'
import { MediaClient } from 'graphql/media'
import { useUpdateProfileMutation } from 'graphql/types'
import { useNavigate } from 'react-router'
import { Glow } from 'v2/Picture/Glow'

export const Camera = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const [updateProfile] = useUpdateProfileMutation()

  return (
    <Centered window>
      {/* <Glow /> */}
      <Selfie
        rim
        onContinue={async (photo) => {
          const url = await MediaClient.uploadAvatar(photo)

          if (auth.profile) {
            console.log(url)

            await updateProfile({
              variables: {
                input: {
                  photo: url
                }
              }
            })

            navigate(`/${auth.profile.identifier}`)
          }
        }}
      />
    </Centered>
  )
}
