import { Route, Routes } from 'react-router'
import { EditProfilePageRoute } from 'routes/EditProfilePage'
import { NotificationsPageRoute } from 'routes/NotificationsPage'

export const SystemRouter = () => {
  return (
    <Routes>
      <Route key="edit" path="/edit" element={<EditProfilePageRoute />} />
      <Route key="ntf" path="/notifications" element={<NotificationsPageRoute />} />
    </Routes>
  )
}
