import { ReactNode } from 'react'

export interface AbsoluteProps {
  left?: number
  top?: number
  right?: number
  bottom?: number
  fill?: boolean
  children: ReactNode
}

export const Absolute = ({ left, top, right, bottom, fill, ...rest }: AbsoluteProps) => (
  <div
    style={{
      display: 'block',
      position: 'absolute',
      left,
      top,
      right,
      bottom,
      width: fill ? '100%' : undefined
    }}
    {...rest}
  />
)
