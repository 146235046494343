import { Modal, ModalProps } from 'components/Elements'
import { QRScanner } from './QRCodeScanner'

export const QRCodeScannerModal = (props: ModalProps) => {
  return (
    <Modal {...props}>
      <QRScanner
        onScan={(data) => {
          console.log(data)
        }}
      />
    </Modal>
  )
}
