import { Block } from 'components/BlocksContext'
import { FieldLabel, Textarea } from 'components/Elements'
import { Modal, ModalProps, useModal } from 'components/Elements'
import * as yup from 'yup'
import styles from './BlockEditor.module.scss'
import { Stack } from '@shareback/structural'
import { EditBlockModalFooter } from './EditBlockModalFooter'
import { useBlockEditForm } from './useBlockEditForm'
import { groupPrompts, profilePrompts } from '../prompts'

import fieldStyles from 'components/Elements/Field/Field.module.scss'
import { usePageLocation } from '../usePageLocation'

interface FormValues {
  content: string
  sup: string
}

const validationSchema = yup.object().shape({
  content: yup.string().required('This field is required').trim(),
  sup: yup.string().required('This field is required').trim()
})

interface ModalAttributes {
  initialValues: FormValues
  onCommit: (block: Block) => void
  onRemove?: () => void
}

export const EditPromptModal = (props: ModalProps) => {
  const modal = useModal()

  const { initialValues, onCommit, onRemove } =
    props.attributes! as ModalAttributes

  const { form, submit } = useBlockEditForm<FormValues>(
    'prompt',
    {
      content: initialValues?.content ?? '',
      sup: initialValues?.sup ?? profilePrompts[0]
    },
    validationSchema
  )

  console.log('Attributes', props.attributes)

  const onSubmit = (values: FormValues) => {
    const block = submit(values)

    onCommit?.(block)
    modal.hide()
  }

  const pageLocation = usePageLocation()

  const promptOptions =
    pageLocation === 'company'
      ? groupPrompts
      : pageLocation === 'group'
      ? groupPrompts
      : profilePrompts

  console.log(pageLocation)

  return (
    <Modal {...props} maxWidth={440}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack gap={24} className={styles.container}>
          <Stack fill gap={14}>
            <Stack fill>
              <FieldLabel>Prompt</FieldLabel>
              <select className={fieldStyles.field} {...form.register('sup')}>
                <option value="">Select...</option>

                {promptOptions.map((prompt, index) => (
                  <option key={index} value={prompt}>
                    {prompt}
                  </option>
                ))}
              </select>
            </Stack>

            <Textarea
              minRows={1}
              maxRows={2}
              label="Description"
              placeholder="e.g. ..."
              error={form.formState.errors.content?.message}
              {...form.register('content')}
            />
          </Stack>

          <EditBlockModalFooter onRemove={onRemove} />
        </Stack>
      </form>
    </Modal>
  )
}
