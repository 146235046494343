import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { FirebaseError, initializeApp } from 'firebase/app'
import {
  OAuthProvider,
  getIdToken,
  signInWithEmailLink,
  signInWithPopup,
  AuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  UserCredential,
  signInWithRedirect
} from 'firebase/auth'
import {
  Auth as FirebaseAuth,
  getAuth,
  GoogleAuthProvider
} from 'firebase/auth'
import { AuthenticateDocument, AuthenticateMutation } from 'graphql/types'

const tenMinutes = 600000

export class BlitzAuth {
  public readonly auth: FirebaseAuth

  private readonly googleProvider = new GoogleAuthProvider()
  private readonly linkedInProvider = new OAuthProvider('linkedin.com')
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>

  private refreshInterval: NodeJS.Timer

  constructor(
    apiKey: string,
    authDomain: string,
    apolloClient: ApolloClient<any>
  ) {
    const app = initializeApp({
      apiKey,
      authDomain
    })

    this.auth = getAuth(app)
    this.refreshInterval = setInterval(() => this.refreshToken(), tenMinutes)

    this.auth.onAuthStateChanged(async (user) => {
      console.log(`Authentication state changed: ${user?.email}`)
    })

    this.apolloClient = apolloClient
  }

  async refreshToken() {
    try {
      await this.auth.currentUser?.getIdToken(true)
    } catch (error) {
      console.warn(error)
    }
  }

  get user() {
    return this.auth.currentUser
  }

  getToken(): string {
    // @ts-ignore
    return this.auth?.currentUser?.accessToken ?? ''
  }

  async signOut() {
    this.auth.signOut()
  }

  private async prepareAccount(token: string) {
    const { data, errors } =
      await this.apolloClient.mutate<AuthenticateMutation>({
        mutation: AuthenticateDocument,
        variables: {
          token
        }
      })

    if (errors) {
      console.warn(errors)
      await this.signOut()
    }

    return data?.authenticate ?? false
  }

  private getProviderForProviderId(providerId: string) {
    switch (providerId) {
      case 'google.com':
        return this.googleProvider

      case 'linkedin.com':
        return this.linkedInProvider
    }
  }

  private async oauth(provider: AuthProvider) {
    await this.signOut()

    let credentials: UserCredential | null = null

    try {
      credentials = await signInWithPopup(this.auth, provider)
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/account-exists-with-different-credential') {
          const email = error.customData?.email as string
          const pendingCredential = OAuthProvider.credentialFromError(error)

          if (email && pendingCredential) {
            const methods = await fetchSignInMethodsForEmail(this.auth, email)

            if (methods.includes('google.com')) {
              // credentials = await signInWithPopup(
              //   this.auth,
              //   this.googleProvider
              // )
              // credentials = await linkWithCredential(
              //   credentials.user,
              //   pendingCredential
              // )
            }
          }
        }
      }

      if (!credentials) {
        throw error
      }
    }

    const token = await getIdToken(credentials.user)
    const prepared = await this.prepareAccount(token)

    if (!prepared) {
      throw new Error('Backend authentication failed')
    }

    return token
  }

  async signInGoogle() {
    return this.oauth(this.googleProvider)
  }

  async signInLinkedIn() {
    return this.oauth(this.linkedInProvider)
  }

  signInMagicLink = async (email: string, location: string) => {
    await this.signOut()

    const credentials = await signInWithEmailLink(this.auth, email, location)
    const token = await getIdToken(credentials.user)

    const prepared = await this.prepareAccount(token)

    if (!prepared) {
      throw new Error('Backend authentication failed')
    }

    return token
  }
}
