import { auth } from './firebase'
import * as hooks from 'react-firebase-hooks/auth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { User } from 'firebase/auth'

export const useAuthState = (
  onHasChanged?: (user: User | null) => Promise<void>
) => {
  return hooks.useAuthState(auth, {
    async onUserChanged(user) {
      console.log('User changed to:', user?.uid, user?.email)
      onHasChanged?.(user)
    }
  })
}

export const useRedirectUnauthenticated = () => {
  const navigate = useNavigate()

  const [user, loading] = useAuthState()

  useEffect(() => {
    if (!loading && !user) {
      navigate(`/auth/signin${window.location.search}`)
    }
  }, [user, loading, navigate])
}

export const useRedirectAuthenticated = () => {
  const [user, loading] = useAuthState()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user, loading])
}
