import { GroupPage } from './GroupPage'
import { ModalProvider } from 'components/Elements'
import { useNavigate, useParams } from 'react-router'
import { useAuth } from 'auth'
import { useEffect } from 'react'
import { useGetGroupPageQuery, useGetMatchesQuery } from 'graphql/types'

const useData = (groupId: string, skip: boolean) => {
  const groupQuery = useGetGroupPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: groupId
    },
    skip
  })

  const group = groupQuery.data?.group

  const loading = groupQuery.loading
  const error = groupQuery.error

  return { group, loading, error }
}

export const GroupPageRoute = () => {
  const params = useParams()
  const navigate = useNavigate()
  const auth = useAuth()

  const id = params.id ?? ''

  const { group, loading, error } = useData(id, auth.loading || !id)

  const loaded = !auth.loading && !loading

  useEffect(() => {
    if (auth.loading === false && !auth.user) {
      navigate(`/i/${id}`)
    }
  }, [auth.loading, auth.user])

  useEffect(() => {
    if (loaded && !group) {
      navigate(`/i/${id}`)
    }
  }, [loaded, group])

  if (!group) {
    return null
  }

  return (
    <ModalProvider>
      <GroupPage group={group} membership={group.membership!} />
    </ModalProvider>
  )
}

// <Routes>
// <Route
//   path="/participants"
//   element={
//     <Overlay
//       close={() => {
//         navigate(`/g/${group.id}`)
//       }}
//     >
//       <SwipeCarousel
//         profiles={group.members.map((member) => member.profile)}
//         bookmarks={bookmarks}
//       />
//     </Overlay>
//   }
// />
// </Routes>
