import styles from './Question.module.scss'

import { FlexStack } from 'components/Layout'
import { Input } from 'components/Elements'

export interface TextQuestionProps {
  message: string
  answer?: string
  placeholder?: string
  onAnswer: (answer: string) => void
}

export const TextQuestion = ({
  message,
  answer = '',
  placeholder,
  onAnswer
}: TextQuestionProps) => {
  return (
    <FlexStack fill className={styles.container}>
      <FlexStack gap={20}>
        <div className={styles.message}>{message}</div>

        <Input
          value={answer}
          placeholder={placeholder || 'Type here...'}
          onChange={(event) => {
            onAnswer(event.target.value)
          }}
        />
      </FlexStack>
    </FlexStack>
  )
}
