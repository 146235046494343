import { FlexRow, FlexStack, Spacer } from 'components/Layout'
import { CompanyFragment, ProfileSummaryFragment } from 'graphql/types'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { ProfileAbout } from 'components/Profiles/ProfileAbout'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useNavigate } from 'react-router'
import { Avatar, Icon } from 'components/Elements'
import { Button } from 'routes/ProfilePage/Button'
import { ProfileListItemLink } from 'components/ProfileListItem'
import { getRoleDescription } from 'v2/_utils/getRoledescription'
import { ViewBlocks } from 'components/Blocks/ViewBlocks'

import { AppLogoLink } from 'v2/AppLogoLink/AppLogoLink'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'
import { share } from 'v2/_utils/share'
import { copy } from 'hooks/useCopy'
import { toast } from 'react-hot-toast'
import { PageLayout } from 'v2/PageLayout/PageLayout'
import { Stack } from '@shareback/structural'

export interface ProfilePageProps {
  company: CompanyFragment
  employees: ProfileSummaryFragment[]
  authenticated?: boolean
  owner?: boolean
}

export const CompanyPage = ({ authenticated, employees, company, owner }: ProfilePageProps) => {
  const navigate = useNavigate()

  const url = `${window.location.origin}/c/${company.identifier}`

  return (
    <PageLayout>
      <Stack gap={40}>
        <Stack fill>
          <ProfileAvatar picture={company.photo} />
          <ProfileAbout title={company.name} description={company.description} />
        </Stack>

        {company.blocks.length > 0 && <ViewBlocks blocks={company.blocks || []} />}

        <FlexStack fill>
          {employees.map((profile) => (
            <ProfileListItemLink
              key={profile.id}
              title={profile.name}
              description={getRoleDescription(profile.role)}
              photo={profile.photo}
              to={`/${profile.identifier}`}
            />
          ))}
        </FlexStack>

        <Spacer height={40} />
      </Stack>

      {owner && (
        <FloatingActionMenu
          actions={[
            {
              label: 'Edit company',

              async action() {
                navigate(`/c/${company.identifier}/edit`)
              }
            }
          ]}
        />
      )}
    </PageLayout>
  )
}

{
  /* <Button
elevated
onClick={async () => {
  try {
    await share(
      `${company.name}`,
      `${company.name} on Blitz`,
      url
    )
  } catch (error) {
    console.warn('[HANDLED]')
    console.warn('Error sharing', error)

    copy(url)

    toast('Company link copied', {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff'
      }
    })
  }
}}
>
<Icon name="ri-share-box-line" size={18} />
</Button> */
}
