import { ReactNode } from 'react'

import styles from './PictureHeaderButton.module.scss'

export interface PictureHeaderButtonProps {
  children: ReactNode
  onClick: () => void
}

export const PictureHeaderButton = ({
  children,
  onClick
}: PictureHeaderButtonProps) => (
  <div className={styles.button} role="button" onClick={onClick}>
    {children}
  </div>
)
