import { HttpLink } from '@apollo/client'
import config from 'config'

/**
 * Adds ?op=OPERATION_NAME to request. Very useful for debugging.
 */
export const httpLink = new HttpLink({
  uri: `${config.apiUrl}/graphql`,

  fetch(uri: any, options: any) {
    const { body } = options
    const { operationName } = JSON.parse(body)
    const query = operationName ? `?op=${operationName}` : ''

    return fetch(`${uri}${query}`, options)
  }
})
