export function flipImage(
  image: CanvasImageSource,
  ctx: CanvasRenderingContext2D,
  flipH: boolean,
  flipV: boolean,
  width: number,
  height: number
) {
  var scaleH = flipH ? -1 : 1,
    scaleV = flipV ? -1 : 1,
    posX = flipH ? width * -1 : 0,
    posY = flipV ? height * -1 : 0

  ctx.save()
  ctx.scale(scaleH, scaleV)
  ctx.drawImage(image, posX, posY, width, height)
  ctx.restore()
}

export function dataURLtoBlob(dataUrl: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()

    req.open('GET', dataUrl)
    req.responseType = 'arraybuffer'
    req.onload = function fileLoaded(e) {
      var mime = this.getResponseHeader('content-type') ?? ''

      resolve(new Blob([this.response], { type: mime }))
    }

    req.send()
  })
}

export const dataURLtoFile = async (dataUrl: string) => {
  const blob = await dataURLtoBlob(dataUrl)

  const file = new File([blob], 'file', {
    type: blob.type,
    lastModified: Date.now()
  })

  return file
}
