import { useAuth } from 'auth'
import { Icon, Modal, ModalProps } from 'components/Elements'
import { FlexRow, FlexStack } from 'components/Layout'
import QRCode from 'react-qr-code'

import { ProfileListItem } from 'components/ProfileListItem'

import styles from './QRCodeOverlay.module.scss'
import { getRoleDescription } from 'v2/_utils/getRoledescription'
import { Glow } from 'v2/Picture/Glow'

const getProfileLink = (id: string) => {
  return `${window.location.origin}/${id}?via=s`
}

export const QRCodeOverlay = (props: ModalProps) => {
  const { profile } = useAuth()
  const url = getProfileLink(profile!.identifier)

  return (
    <Modal {...props}>
      <Glow opacity={0.1} />
      <FlexRow horizontal="middle" vertical="center" fill>
        {profile && (
          <FlexStack gap={10} vertical="center" align="center">
            <QRCode
              fgColor="transparent"
              bgColor="#eeeeee"
              size={260}
              value={url}
            />

            <FlexRow>
              <ProfileListItem
                photoRadius={42}
                photo={profile!.photo}
                description={`blitz.so/${profile.identifier}`}
                title={profile!.name}
              />
            </FlexRow>
          </FlexStack>
        )}
      </FlexRow>
    </Modal>
  )
}
