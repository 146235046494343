import { FlexStack } from 'components/Layout'
import { ProfileAbout } from 'components/Profiles/ProfileAbout'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { GroupFragment, GroupInfo } from 'graphql/types'

export interface GroupAboutProps {
  group: GroupInfo
}

export const GroupAbout = ({ group }: GroupAboutProps) => {
  return (
    <FlexStack>
      <ProfileAvatar picture={group.photo} />
      <ProfileAbout title={group.name} description={group.description} />
    </FlexStack>
  )
}
