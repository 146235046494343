import { Row } from '@shareback/structural'
import { Faces } from 'components/Faces'
import { Card } from 'components/Records/Card'
import { Button } from '../Button'
import { Icon } from 'components/Elements'

import styles from '../Shared.module.scss'

export interface JoinGroupCardProps {
  profilePhoto: string
  profileName: string
  profileLink: string
  groupPhoto: string
  groupName?: string
  groupLink?: string
  onDecline?: () => void
  onAccept?: () => void
}

export const JoinGroupCard = ({
  profileLink,
  profileName,
  profilePhoto,
  groupPhoto,
  groupLink,
  groupName,
  onDecline,
  onAccept
}: JoinGroupCardProps) => {
  const showGroupName = !!(groupLink && groupName)

  return (
    <Card
      avatar={<Faces background={groupPhoto} foreground={profilePhoto} size={64} />}
      footer={
        <Row fill gap={8} horizontal="right">
          <Button onClick={onDecline}>
            <Icon name="ri-close-line" />
          </Button>

          <Button onClick={onAccept}>Accept</Button>
        </Row>
      }
    >
      <div className={styles.message}>
        <a className={styles.link} href={profileLink} target="_blank">
          {profileName}
        </a>
        {'  has requested access to join '}
        {showGroupName ? (
          <a className={styles.link} href={groupLink} target="_blank">
            {groupName}
          </a>
        ) : (
          <span>the group</span>
        )}
      </div>
    </Card>
  )
}
