import { Row, Stack } from '@shareback/structural'
import { GroupFragment } from 'graphql/types'
import QRCode from 'react-qr-code'
import { AButton } from 'routes/HomePage/AButton'
import { Icon } from 'components/Elements'

import styles from './ManageCheckins.module.scss'

export interface ManageCheckinsProps {
  group: GroupFragment
}

const checkins = [
  {
    id: 'test_1',
    token: 'd94c4fa0-3801-493a-abf3-934d4c0c938e',
    groupId: 'a',
    createdAt: new Date(),
    updatedAt: new Date()
  }
]

export const ManageCheckins = ({ group }: ManageCheckinsProps) => {
  return (
    <Stack fill gap="1rem">
      <Row fill>
        <AButton icon="ri-add-line" onClick={() => {}}>
          Generate new QR code
        </AButton>
      </Row>

      {checkins.map((data) => {
        return (
          <Row key={data.id} gap="1rem" className={styles.component}>
            <QRCode
              className={styles.qr}
              fgColor="transparent"
              bgColor="#eeeeee"
              size={48}
              value={data.token}
            />

            <Stack fill>
              <div className={styles.title}>Mon 24 Apr</div>
              <div className={styles.description}>Description goes here</div>
            </Stack>

            <Row shrink={false}>
              <Icon className={styles.iconButton} name="ri-arrow-right-up-line" />
              <Icon className={styles.iconButton} name="ri-more-line" />
            </Row>
          </Row>
        )
      })}
    </Stack>
  )
}
