const config = {
  apiUrl: 'http://localhost:3021',
  graphqlWebsocketUrl: 'ws://localhost:3021/graphql',

  firebase: {
    apiKey: 'AIzaSyA6Wpc9tnNs32lyU02dma4CsSkI3xRwl98',
    authDomain: 'blitz-374213.firebaseapp.com'
  },

  qrCodeHost: 'https://localhost'
}

export default config
