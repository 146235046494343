import React from 'react'
import styles from './TakePhotoButton.module.scss'

interface Props {
  onClick: () => void
}

const TakePhotoButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.buttonIcon} onClick={onClick}>
      <div className={styles.outerCircle}></div>
      <div className={styles.innerCircle}></div>
    </div>
  )
}

export default TakePhotoButton
