import $ from 'classnames'
import styles from './Glow.module.scss'

export interface GlowProps {
  opacity?: number
}

export const Glow = ({ opacity = 0.15 }: GlowProps) => {
  return <div className={$(styles.box, styles.glow)} style={{ opacity }}></div>
}
