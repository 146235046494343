import { cloneElement, useEffect } from 'react'
import { useLayer, useHover, Placement } from 'react-laag'

import styles from './Tooltip.module.scss'

export interface TooltipProps {
  placement?: Placement
  delay?: number
  offset?: number
  message?: string
  children: any
}

const isReactText = (children: any): children is string | number => {
  return ['string', 'number'].includes(typeof children)
}

export const Tooltip = ({
  delay = 200,
  placement = 'top-center',
  offset = 4,
  message,
  children
}: TooltipProps) => {
  const [isOpen, hoverProps, close] = useHover({
    delayEnter: delay,
    delayLeave: 50,
    hideOnScroll: true
  })

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    placement,
    preferX: 'right',
    preferY: 'top',
    triggerOffset: offset
  })

  // used to close the tooltip when there isn't a mouseout event e.g. another react-laag layer

  // useEffect(() => {
  //   return close
  // }, [close])

  /**
   * Skipping rendering tooltip if there is no message or small screen.
   */
  if (!message) {
    return <>{children}</>
  }

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  const trigger = isReactText(children) ? (
    <span {...triggerProps} {...hoverProps}>
      {children}
    </span>
  ) : (
    cloneElement(children, {
      ...triggerProps,
      ...hoverProps
    })
  )

  return (
    <>
      {trigger}
      {isOpen &&
        renderLayer(
          <div {...layerProps} className={styles.tooltip}>
            {message}
          </div>
        )}
    </>
  )
}
