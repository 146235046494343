import { Row } from '@shareback/structural'
import styles from './NewGroupButton.module.scss'
import { Icon } from 'components/Elements'

export interface NewGroupButtonProps {
  onClick?: () => void
}

export const NewGroupButton = ({ onClick }: NewGroupButtonProps) => {
  return (
    <Row
      fill
      gap={20}
      vertical="center"
      className={styles.row}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <Icon name="ri-add-line" className={styles.icon} />
      </div>

      <Row fill className={styles.text}>
        Create new group
      </Row>
    </Row>
  )
}
