import { onError } from '@apollo/client/link/error'

export const errors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.group('Graphql error')
      console.warn(message)
      console.warn(locations)
      console.warn(path)
      console.groupEnd()
    })
  }

  console.log(graphQLErrors, networkError)

  if (networkError) {
    console.warn(networkError)
  }
})
