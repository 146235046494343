import { Centered } from '@shareback/structural'
import VoiceRecorder from './VoiceRecorder'
import { Column } from 'v2/Column'

export const Voice = () => {
  return (
    <Centered window>
      <Column max={500}>
        <VoiceRecorder />
      </Column>
    </Centered>
  )
}
