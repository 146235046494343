import { ReactNode } from 'react'
import $ from 'classnames'

import styles from './Container.module.scss'

export interface ContainerProps {
  horizontalPadding?: boolean
  verticalPadding?: boolean
  verticalAlignment?: 'top' | 'center'
  className?: string
  header?: ReactNode
  children: ReactNode
  blurUrl?: string
}

export const Container = ({
  className,
  children,
  header,
  blurUrl,
  horizontalPadding = true,
  verticalPadding = true,
  verticalAlignment = 'top'
}: ContainerProps) => {
  return (
    <div
      className={$(
        styles.container,
        horizontalPadding && styles.horizontalPadding,
        verticalPadding && styles.verticalPadding,
        className
      )}
    >
      {blurUrl && (
        <div
          className={styles.blur}
          style={{ backgroundImage: `url(${blurUrl})` }}
        />
      )}

      <div
        className={styles.content}
        style={{
          justifyContent: verticalAlignment === 'center' ? 'center' : undefined
        }}
      >
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  )
}
