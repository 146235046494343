import { useAuth } from 'auth'
import { ModalProvider } from 'components/Elements'
import { useCreateContactMutation, useGetProfileQuery } from 'graphql/types'
import { Route, Routes, useParams } from 'react-router'
import { ProfilePage } from './ProfilePage'
import { AnimatedRoute } from 'v2/AnimatedRoute/AnimatedRoute'
import { useSearchParams } from 'react-router-dom'
import { BlockPage } from './BlockPage'
import { Helmet } from 'react-helmet'

enum ContactMethod {
  Scan = 's'
}

export const ProfilePageRoute = () => {
  const params = useParams()
  const [search, setSearchParams] = useSearchParams()
  const auth = useAuth()

  const [createContact] = useCreateContactMutation()

  const identifier = params.identifier!

  const { data } = useGetProfileQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: identifier
    },
    onCompleted(data) {
      const via = search.get('via')

      if (via) {
        search.delete('via')
        setSearchParams(search)
      }

      if (
        auth.profile &&
        data.profile &&
        via === ContactMethod.Scan &&
        auth.profile.id !== data.profile.id
      ) {
        createContact({
          variables: {
            input: {
              targetId: data.profile.id,
              method: 'scan'
            }
          },
          onCompleted(data) {
            console.log(data)
          }
        })
      }
    },
    skip: auth.loading
  })

  if (!data?.profile) {
    return null
  }

  return (
    <ProfilePage
      authenticated={!!auth.profile}
      profile={data.profile}
      company={data.profile.company ?? undefined}
      owner={auth.profile?.id === data.profile.id}
    />
  )
}
