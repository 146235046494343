import { useAuth } from 'auth'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalProvider } from 'components/Elements'
import { EditCompanyPage } from './EditCompanyPage'

export const EditCompanyPageRoute = () => {
  const navigate = useNavigate()
  const params = useParams()
  const auth = useAuth()

  const identifier = params.identifier ?? ''
  const company = auth.profile?.company

  if (auth.loading || !auth.profile) {
    return null
  }

  if (!company || company.identifier !== identifier) {
    navigate('/')
    return null
  }

  return (
    <ModalProvider>
      <EditCompanyPage company={company} />
    </ModalProvider>
  )
}
