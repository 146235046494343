import { useRef, useState } from 'react'
import styles from './Select.module.scss'
import { useOutsideClick } from 'rooks'
import { Row } from '@shareback/structural'
import { Icon } from 'components/Elements'

interface SelectProps {
  value: string | undefined
  options: string[]
  placeholder: string
  onChange: (selected: string | undefined) => void
}

export const Select = ({ value, options, placeholder, onChange }: SelectProps) => {
  const [open, setOpen] = useState(false)

  const ref = useRef(null)

  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div ref={ref} className={styles.component}>
      <div
        className={styles.pill}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {value ? (
          <Row>
            <div className={styles.value}>{value}</div>
            <Icon
              style={{ paddingLeft: '0.5em' }}
              name="ri-close-line"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()

                onChange(undefined)

                // setOpen(false)
              }}
            ></Icon>
          </Row>
        ) : (
          <Row gap="0.5em" className={styles.placeholder}>
            <Icon name="ri-search-line" />
            <div>{placeholder}</div>
          </Row>
        )}
      </div>

      {open && (
        <div className={styles.dropdown}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.option}
              onClick={() => {
                onChange(option)
                setOpen(false)
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
