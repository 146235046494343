import { Route, Routes } from 'react-router-dom'
import { Auth } from './routes'
import { ViewportProvider } from 'components/Layout'
import { AuthWithEmail } from './routes/AuthWithEmail'
import { AuthWithEmailRedirect } from './routes/AuthWithEmailRedirect'
import { RequestAccount } from './routes/RequestAccount'

export const AuthRoute = () => {
  return (
    <ViewportProvider>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/request" element={<RequestAccount />} />
        <Route path="/email" element={<AuthWithEmail />} />
        <Route path="/email/redirect" element={<AuthWithEmailRedirect />} />
      </Routes>
    </ViewportProvider>
  )
}
