import { Block } from 'components/BlocksContext'
import { EditBlockModalFooter } from './EditBlockModalFooter'
import { Modal, ModalProps, useModal } from 'components/Elements'
import { Stack } from '@shareback/structural'
import { Textarea } from 'components/Elements'
import { useBlockEditForm } from './useBlockEditForm'
import * as yup from 'yup'

import styles from './BlockEditor.module.scss'

interface FormValues {
  content: string
  url: string
}

// export const URL_REGEXP =
export const URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

const validationSchema = yup.object().shape({
  content: yup.string().required('This field is required').trim(),
  url: yup
    .string()
    /* Not using URL validator because it's too strict */
    .matches(URL_REGEXP, 'The url is invalid')
    .required('This field is required')
    .trim()
})

interface ModalAttributes {
  initialValues?: FormValues
  onCommit: (block: Block) => void
  onRemove?: () => void
}

export const EditLinkModal = (props: ModalProps) => {
  const modal = useModal()

  const { initialValues, onCommit, onRemove } =
    props.attributes! as ModalAttributes

  const { form, submit } = useBlockEditForm<FormValues>(
    'link',
    {
      content: initialValues?.content ?? '',
      url: initialValues?.url ?? ''
    },
    validationSchema
  )

  const onSubmit = (values: FormValues) => {
    const block = submit(values)

    onCommit?.(block)
    modal.hide()
  }

  return (
    <Modal {...props} maxWidth={440}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack gap={24} className={styles.container}>
          <Stack fill gap={14}>
            <Textarea
              minRows={1}
              maxRows={4}
              allowNewline={false}
              placeholder="e.g. https://google.com"
              label="URL"
              error={form.formState.errors.url?.message}
              {...form.register('url')}
            />

            <Textarea
              minRows={1}
              maxRows={2}
              label="Description"
              placeholder="e.g. My personal website"
              error={form.formState.errors.content?.message}
              {...form.register('content')}
            />
          </Stack>

          <EditBlockModalFooter onRemove={onRemove} />
        </Stack>
      </form>
    </Modal>
  )
}
