import { forwardRef, ReactNode } from 'react'
import $ from 'classnames'

import styles from './Column.module.scss'

export interface ColumnProps {
  fill?: boolean
  gap?: number
  count: number
  vertical?: 'top' | 'center' | 'bottom' | 'baseline'
  horizontal?: 'left' | 'center' | 'right'
  children: ReactNode
  className?: string
  style?: React.CSSProperties
}

const justify = {
  left: 'flex-start',
  center: 'middle',
  right: 'flex-end'
}

/**
 * Aligns items horizontally and puts them in the row.
 */
export const Columns = forwardRef<HTMLDivElement, ColumnProps>((props, ref) => {
  const {
    fill,
    className,
    style,
    gap = 0,
    count,
    vertical = 'center',
    horizontal,
    children
  } = props

  return (
    <div
      ref={ref}
      className={$(
        styles.columns,
        vertical && styles[vertical],
        fill && styles.fill,
        className
      )}
      style={{
        gridTemplateColumns: `repeat(${Math.max(1, count)}, minmax(0, 1fr))`,
        gap,
        justifyContent: horizontal && justify[horizontal],
        ...style
      }}
    >
      {children}
    </div>
  )
})
