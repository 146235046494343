import { Avatar } from './Avatar'

import styles from './AvatarStack.module.scss'

export interface AvatarStackProps {
  avatars: string[] | { url: string; name?: string }[]
  size: number
  max?: number
  borderColor?: string
}

export const AvatarStack = ({ max, avatars, size, borderColor = '#000' }: AvatarStackProps) => {
  const items = avatars.slice(0, max)

  return (
    <div className={styles.list}>
      {items.map((avatar, index) => {
        const [url, tooltip] = typeof avatar === 'string' ? [avatar] : [avatar.url, avatar.name]

        return (
          <div
            key={index}
            className={styles.item}
            style={{
              zIndex: index,
              width: size * 0.7,
              // @ts-ignore
              '--border-color': borderColor
            }}
          >
            <Avatar size={size} url={url} tooltip={tooltip} className={styles.avatar} />
          </div>
        )
      })}
    </div>
  )
}
