import { useAuth } from 'auth'
import { NotificationsPage } from './NotificationsPage'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const NotificationsPageRoute = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if (!auth.loading && !auth.user) {
      navigate('/auth', { replace: true })
    }
  }, [auth.user, auth.loading])

  return <NotificationsPage />
}
