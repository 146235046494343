import { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { useDidMount, useWillUnmount } from 'rooks'
import { SomeModalProps, useModalController } from './useModalController'

export type ContextValue = ReturnType<typeof useModalController>

interface ModalManagerProps extends SomeModalProps {
  name: string
  component: any
}

const context = createContext<Partial<ContextValue>>({})

export const ModalManager = ({
  name,
  component,
  ...props
}: ModalManagerProps) => {
  const modal = useModal()

  useDidMount(() => {
    modal.addTemplate(name, component, props)
  })

  useWillUnmount(() => {
    modal.removeTemplate(name)
  })

  return modal.render(name)
}

export const useModal = () => {
  return useContext(context) as ContextValue
}

export interface ModalProviderProps {
  children: ReactNode
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const controller = useModalController()

  return <context.Provider value={controller}>{children}</context.Provider>
}
