import { CSSProperties, forwardRef, ReactNode } from 'react'
import $ from 'classnames'

import styles from './Stack.module.scss'

export interface StackProps {
  id?: string
  fill?: boolean
  reverse?: boolean
  gap?: number | string
  horizontal?: 'left' | 'right' | 'center'
  vertical?: 'top' | 'bottom' | 'center'
  className?: string
  style?: CSSProperties
  children: ReactNode
  onClick?: () => void
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ className, fill, gap = 0, style, horizontal, vertical, reverse, ...props }, ref) => (
    <div
      {...props}
      className={$(
        styles.stack,
        reverse && styles.reverse,
        fill && styles.fill,
        horizontal && styles['h-' + horizontal],
        vertical && styles['v-' + vertical],
        className
      )}
      style={{ ...style, gap }}
      ref={ref}
    />
  )
)
