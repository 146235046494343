import { Block } from '../Block_v2'
import { ModalManager, useModal } from 'components/Elements'
import { PromptModal } from './PromptModal'
import { BlockBase } from '../Block_v2/types'

export interface PromptProps {
  block: BlockBase
  onClick?: (block: BlockBase) => void
}

export const Prompt = ({ block, onClick }: PromptProps) => {
  const modal = useModal()

  return (
    <>
      <Block
        sub={block.sub}
        sup={block.sup}
        icon="ri-chat-3-line"
        onClick={() => {
          if (onClick) {
            return onClick(block)
          }

          modal.show(block.id, {
            block,
            canManage: false,
            canComment: true
          })
        }}
      >
        {block.content}
      </Block>
      <ModalManager name={block.id} component={PromptModal} />
    </>
  )
}
