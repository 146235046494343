import './styles/index.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'
import * as Sentry from '@sentry/react'

import App from './App'
import reportWebVitals from './reportWebVitals'
// @ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'

mapboxgl.accessToken =
  'pk.eyJ1IjoieWV2dmxhc2Vua28iLCJhIjoiY2xsNmxyZWM5MGtvajNkcWpla3FxZHhlaSJ9.TUU-Z2aBmsA2YkY9hAkc6Q'

// @ts-ignore
const release = `blitz-ui@${window.__COMMIT_HASH__ ?? ''}`

console.log('Release', release)

Sentry.init({
  // release: release: '',
  dsn: 'https://8055270fb216455b8d1fe44935e4c7ae@o1002391.ingest.sentry.io/4505278668406784',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/blitz\.so/]
    }),
    new Sentry.Replay()
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3
})

const element = document.querySelector('#root') as HTMLElement
const root = ReactDOM.createRoot(element)

Modal.setAppElement(element)

const WatchedApp = Sentry.withProfiler(App)

root.render(<WatchedApp />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
