import { Button, ButtonLevel } from 'components/Elements'
import { FlexRow } from 'components/Layout'
import { About } from './About'

export interface RequestProps {
  from: {
    name: string
    photo: string
    email: string
    role: string
  }
  onAccept: () => void
  onReject: () => void
}

export const Request = ({ from, onAccept, onReject }: RequestProps) => {
  return (
    <FlexRow fill horizontal="spaced">
      <About title={from.name} subtitle={from.role} photo={from.photo} />

      <FlexRow gap={12} shrink={false}>
        <Button level={ButtonLevel.Secondary} onClick={onAccept}>
          Accept
        </Button>
        <Button level={ButtonLevel.Secondary} onClick={onReject}>
          Reject
        </Button>
      </FlexRow>
    </FlexRow>
  )
}
