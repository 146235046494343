import React from 'react'
import $ from 'classnames'
import styles from './Card.module.scss'

interface Props {
  clickable?: boolean
  gap?: number
  avatar?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
}

export const Card: React.FC<Props> = ({
  clickable,
  gap = 0,
  avatar,
  footer,
  children,
  onClick
}) => (
  <section
    className={$(styles.component, clickable && styles.clickable)}
    style={{
      gap: `${gap}px`,
      cursor: clickable ? 'pointer' : 'default'
    }}
    onClick={onClick}
    tabIndex={clickable ? 0 : undefined}
  >
    {avatar ? (
      <div className={styles.body}>
        {avatar}
        {children}
      </div>
    ) : (
      children
    )}

    {footer}
  </section>
)
