import { getIdToken, signInWithPopup, signInWithEmailLink } from 'firebase/auth'
import { auth, googleAuthProvider, linkedInAuthProvider } from './firebase'

export const refreshToken = async (force?: boolean) => {
  try {
    await auth.currentUser?.getIdToken(force)
  } catch (error) {
    console.warn(error)
  }
}

export const getAccessToken = (): string => {
  // @ts-ignore
  return auth?.currentUser?.accessToken ?? ''
}

export const initialize = () => {
  window.addEventListener('focus', async () => {
    await refreshToken(true)
  })
}

export const signOut = async () => {
  if (auth.currentUser) {
    await auth.signOut()
  }
}

export const signInWithGoogle = async () => {
  await signOut()

  const credentials = await signInWithPopup(auth, googleAuthProvider)
  const token = await getIdToken(credentials.user)

  return token
}

export const signInWithLinkedIn = async () => {
  await signOut()

  const credentials = await signInWithPopup(auth, linkedInAuthProvider)
  const token = await getIdToken(credentials.user)

  return token
}

export const signInWithMagicLink = async (email: string, location: string) => {
  await signOut()

  const credentials = await signInWithEmailLink(auth, email, location)
  const token = await getIdToken(credentials.user)

  return token
}
