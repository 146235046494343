import { FlexRow, Spacer } from 'components/Layout'
import { ButtonLevel, Button, ButtonLink } from 'components/Elements'
import { useEffect } from 'react'
import { ProfileFragment, useUpdateProfileMutation } from 'graphql/types'
import { useNavigate } from 'react-router'
import { AppPageLayout } from 'v2/AppPageLayout'
import { EditBlocks } from 'components/Blocks/EditBlocks'
import { useBlocksStore } from 'components/BlocksContext'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'

export interface PageProps {
  profile: ProfileFragment
}

export const Page = ({ profile }: PageProps) => {
  const navigate = useNavigate()

  const [updateProfile] = useUpdateProfileMutation()

  const save = async () => {
    await updateProfile({
      variables: {
        input: {
          blocks: blocksStore.blocks
        }
      }
    })

    navigate(`/${profile.identifier}`)
  }

  const blocksStore = useBlocksStore()

  useEffect(() => {
    blocksStore.import(profile.blocks)
  }, [])

  return (
    <>
      <AppPageLayout
        header={
          <FlexRow fill horizontal="spaced">
            <Spacer height={40} />
          </FlexRow>
        }
      >
        <EditBlocks store={blocksStore} />

        {/* <FlexRow
          fill
          horizontal="middle"
          gap={18}
          style={{ padding: 20, borderTop: '2px dashed #262626' }}
        >
          <ButtonLink
            width={120}
            link={`/${profile.identifier}`}
            level={ButtonLevel.Secondary}
          >
            Cancel
          </ButtonLink>
          <Button width={120} level={ButtonLevel.Primary} onClick={save}>
            Save changes
          </Button>
        </FlexRow> */}

        <FloatingActionMenu
          actions={[
            {
              label: 'Cancel',
              action() {
                navigate(`/${profile.identifier}`)
              }
            },
            {
              label: 'Save changes',
              color: '#b7fbc9',
              action() {
                save()
              }
            }
          ]}
        />
      </AppPageLayout>
    </>
  )
}
