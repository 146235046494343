import { useRef, useEffect } from 'react'
import { Icon } from 'components/Elements'
import { Row } from '@shareback/structural'
import styles from './VoicePlayer.module.scss'
import { formatTime } from './utils'
import $ from 'classnames'
import { useWavesurfer } from './useWavesurfer'

export interface VoicePlayerProps {
  audioUrl: string
  size?: 'small' | 'medium'
}

export interface WavesurferStyle {
  barWidth: number
  barRadius: number
  height: number
  barGap: number
}

export const VoicePlayer = ({
  audioUrl,
  size = 'medium'
}: VoicePlayerProps) => {
  const waveformRef = useRef<HTMLDivElement>(null)

  const [wavesurfer, playing, togglePlay] = useWavesurfer(
    waveformRef,
    audioUrl,
    {
      barWidth: 2,
      barRadius: 2,
      height: size === 'small' ? 16 : 52,
      barGap: 3
    }
  )

  useEffect(() => {
    if (!wavesurfer) return

    wavesurfer.on('audioprocess', (event) => {
      let int = Math.floor(event)
      let text = formatTime(int)

      if (timeRef.current) {
        if (timeRef.current.innerText !== text) {
          timeRef.current.innerText = text
        }
      }
    })
    // ]
  }, [wavesurfer])

  const timeRef = useRef<HTMLDivElement>(null)

  return (
    <div key={audioUrl}>
      <Row
        fill
        horizontal="center"
        className={$(styles.player, size === 'small' && styles.small)}
      >
        <button className={styles.button} onClick={togglePlay}>
          <Icon name={playing ? 'ri-pause-fill' : 'ri-play-fill'} size={24} />
        </button>

        <div className={styles.waves}>
          <div ref={waveformRef} />
        </div>

        {size !== 'small' && (
          <div className={styles.text} ref={timeRef}>
            0:00
          </div>
        )}
      </Row>
    </div>
  )
}
