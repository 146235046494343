import { Link } from './Link'
import { Prompt } from './Prompt'
import { BlockBase } from './Block_v2/types'
import { Row, Spacer, Stack } from '@shareback/structural'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { Block, BlocksStoreState } from 'components/BlocksContext'
import { ModalManager, useModal } from 'components/Elements'
import { EditLinkModal } from './EditBlockModal/EditLinkModal'
import { EditPromptModal } from './EditBlockModal/EditPromptModal'
import { pick } from 'lodash'
import { Question } from './Question'
import { EditQuestionModal } from './EditBlockModal/EditQuestionModal'
import { AButton } from 'routes/HomePage/AButton'

export interface EditBlocksProps {
  store: BlocksStoreState
}

const getComponent = (block: BlockBase) => {
  switch (block.type) {
    case 'link':
      return Link

    case 'prompt':
      return Prompt

    case 'question':
      return Question
  }
}

type Schema = {
  modal: string
  fields: string[]
}

const schemas: Record<string, Schema> = {
  link: {
    modal: 'edit-link',
    fields: ['url', 'content']
  },
  prompt: {
    modal: 'edit-prompt',
    fields: ['sup', 'content']
  },
  question: {
    modal: 'edit-question',
    fields: ['content']
  }
}

export const EditBlocks = ({ store }: EditBlocksProps) => {
  const modal = useModal()

  return (
    <>
      <Stack fill gap={10}>
        <AButton
          icon="ri-link"
          onClick={() => {
            modal.show(schemas.link.modal, {
              onCommit: (block: Block) => {
                store.addBlock(block)
              }
            })
          }}
        >
          Add link
        </AButton>
        <AButton
          icon="ri-chat-1-line"
          onClick={() => {
            modal.show(schemas.prompt.modal, {
              onCommit: (block: Block) => {
                store.addBlock(block)
              }
            })
          }}
        >
          Add prompt
        </AButton>
        <AButton
          icon="ri-question-mark"
          onClick={() => {
            modal.show(schemas.question.modal, {
              onCommit: (block: Block) => {
                store.addBlock(block)
              }
            })
          }}
        >
          Add Question
        </AButton>

        <Spacer height={20} />

        {store.blocks.map((block, index) => {
          const Component = getComponent(block)

          if (!Component) {
            return null
          }

          return (
            <Component
              key={index}
              block={block}
              onClick={() => {
                const blockSchema = schemas[block.type]

                if (!blockSchema) {
                  return
                }

                const initialValues = pick(block, blockSchema.fields)

                modal.show(blockSchema.modal, {
                  initialValues,

                  onCommit(data: Block) {
                    const values = pick(data, blockSchema.fields)

                    store.updateBlock(index, values)
                  },

                  onRemove() {
                    store.removeBlock(index)
                  }
                })
              }}
            />
          )
        })}
      </Stack>

      <ModalManager name={schemas.link.modal} component={EditLinkModal} />
      <ModalManager name={schemas.prompt.modal} component={EditPromptModal} />
      <ModalManager name={schemas.question.modal} component={EditQuestionModal} />
    </>
  )
}
