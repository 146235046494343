import { useDuration } from './useDuration'
import { formatTime } from './utils'

export interface DurationProps {
  enabled: boolean
}

export const Duration = ({ enabled }: DurationProps) => {
  const duration = useDuration(enabled)

  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 12,
        opacity: enabled ? 0.6 : 0
      }}
    >
      {formatTime(duration)}
    </div>
  )
}
