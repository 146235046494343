import { Block } from '../Block_v2'
import { BlockBase } from '../Block_v2/types'
import { URL_REGEXP } from '../EditBlockModal/EditLinkModal'

export interface LinkProps {
  block: BlockBase
  onClick?: (block: BlockBase) => void
}

const formatUrl = (input: string) => {
  const url =
    input.startsWith('http://') || input.startsWith('https://')
      ? input
      : `https://${input}`

  let host = ''

  try {
    host = new URL(url).host
  } catch (error) {
    console.log(error)
  }

  return [url, host] as const
}

export const Link = ({ block, onClick }: LinkProps) => {
  const [link, host] = formatUrl(block.url!)

  return (
    <Block
      sub={host}
      color={block.color}
      icon="ri-arrow-right-up-line"
      onClick={() => {
        if (onClick) {
          return onClick(block)
        }

        if (link) {
          window.open(link, '_blank', 'noopener noreferrer')
        }
      }}
    >
      {block.content}
    </Block>
  )
}
