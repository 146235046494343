import { Centered, Row, Stack } from '@shareback/structural'
import { useAuth } from 'auth'
import {
  GetNotificationsDocument,
  useAcceptGroupInviteMutation,
  useCreateMatchMutation,
  useRejectGroupInviteMutation,
  useRemoveMatchMutation
} from 'graphql/types'
import React from 'react'
import { PageLayout } from 'v2/PageLayout/PageLayout'
import { useNotificationsContext } from './NotificationsProvider'
import { IncomingConnectionCard } from 'components/Records/IncomingConnectionCard'
import { JoinGroupCard } from 'components/Records/JoinGroupCard'
import { Button } from './Button'
import { useNavigate } from 'react-router'

import styles from './NotificationPage.module.scss'

export const NotificationsPage = () => {
  return (
    <PageLayout glow>
      <NotificationsPageBase />
    </PageLayout>
  )
}

export const NotificationsPageBase = () => {
  const { profile } = useAuth()
  const navigate = useNavigate()
  const { matches, invites, loading } = useNotificationsContext()

  const [acceptGroupInviteMutation] = useAcceptGroupInviteMutation()
  const [rejectGroupInviteMutation] = useRejectGroupInviteMutation()
  const [createMatchMutation] = useCreateMatchMutation()
  const [removeMatchMutation] = useRemoveMatchMutation()

  const rejectGroupInvite = async (inviteId: string) => {
    const response = await rejectGroupInviteMutation({
      variables: {
        inviteId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetNotificationsDocument
        }
      ]
    })

    return response
  }

  const acceptGroupInvite = async (inviteId: string) => {
    const response = await acceptGroupInviteMutation({
      variables: {
        inviteId: inviteId
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetNotificationsDocument
        }
      ]
    })

    return response
  }

  if (!loading && matches.length + invites.length === 0) {
    return (
      <Centered>
        <Stack gap="1em" horizontal="center" vertical="center">
          <Row className={styles.empty}>No new notifications</Row>
          <Row>
            <Button
              onClick={() => {
                navigate('/')
              }}
            >
              Go back
            </Button>
          </Row>
        </Stack>
      </Centered>
    )
  }

  return (
    <>
      <Stack fill>
        {invites.map((group) => (
          <React.Fragment key={group.id}>
            <h2 className="heading">{group.name}</h2>
            {group.children.map((invite) => (
              <JoinGroupCard
                key={invite.id}
                onAccept={async () => {
                  await acceptGroupInvite(invite.id)
                }}
                onDecline={async () => {
                  await rejectGroupInvite(invite.id)
                }}
                profileName={invite.profile.name}
                profileLink={'/' + invite.profile.identifier}
                profilePhoto={invite.profile.photo}
                groupPhoto={invite.group.photo}
                // groupLink={'/g/' + invite.group.id}
                // groupName={invite.group.name}
              />
            ))}
          </React.Fragment>
        ))}
      </Stack>

      <Stack fill>
        {matches.map((group) => (
          <React.Fragment key={group.id}>
            <h2 className="heading">{group.name}</h2>

            {group.children.map((match) => (
              <IncomingConnectionCard
                key={match.id}
                name={match.sender.name}
                photo={match.sender.photo}
                link={`/` + match.sender.identifier}
                onDecline={async () => {
                  await removeMatchMutation({
                    variables: {
                      matchId: match.id
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                      {
                        query: GetNotificationsDocument
                      }
                    ]
                  })
                }}
                onAccept={async () => {
                  if (!profile) return

                  await createMatchMutation({
                    variables: {
                      groupId: group.id,
                      receiverId: match.sender.id
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                      {
                        query: GetNotificationsDocument
                      }
                    ]
                  })
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </Stack>
    </>
  )
}
