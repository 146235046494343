import { Route, Routes } from 'react-router'
import { Camera } from './Camera/Camera'
import { Voice } from './Voice'
import { PulsePage } from './PulsePage'

export const TestsRouter = () => {
  return (
    <Routes>
      <Route path="/camera" element={<Camera />} />
      <Route path="/voice" element={<Voice />} />
      <Route path="/pulse" element={<PulsePage />} />
    </Routes>
  )
}
