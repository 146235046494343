import { HTMLAttributes, forwardRef } from 'react'
import LocalClasses from 'classnames/bind'

import styles from './FlexRow.module.scss'

type HTMLDivProps = Pick<
  HTMLAttributes<HTMLElement>,
  'className' | 'style' | 'children' | 'onClick'
>

export interface FlexRowProps extends HTMLDivProps {
  fill?: boolean
  stackOnPhone?: boolean
  gap?: number | string
  wrap?: boolean
  shrink?: boolean
  reverse?: boolean
  horizontal?: 'left' | 'right' | 'middle' | 'spaced'
  vertical?: 'top' | 'center' | 'bottom' | 'baseline'
  padded?: boolean
  contentEditable?: any
  suppressContentEditableWarning?: boolean
}
/**
 * Aligns items horizontally and puts them in the row.
 */

const classes = LocalClasses.bind(styles)

const pad = (padded: boolean, gap: number | string) => {
  if (padded) {
    return { paddingLeft: gap, paddingRight: gap }
  }
}

export const FlexRow = forwardRef<HTMLDivElement, FlexRowProps>(
  (
    {
      className = '',
      style,
      fill = false,
      shrink,
      stackOnPhone = false,
      reverse = false,
      wrap = false,
      gap = 0,
      horizontal = 'left',
      vertical = 'center',
      padded = false,
      ...props
    },
    ref
  ) => {
    const local = classes('row', horizontal, vertical, {
      reverse,
      stackOnPhone,
      fill,
      wrap
    })

    return (
      <div
        {...props}
        ref={ref}
        className={`${local} ${className}`}
        style={{
          gap,
          ...pad(padded, gap),
          ...style,
          flexShrink:
            typeof shrink !== 'undefined' ? (shrink ? 1 : 0) : undefined
        }}
      />
    )
  }
)
