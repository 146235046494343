import { ConfigureCompany } from './ConfigureCompany'
import { ConfigureProfile } from './ConfigureProfile'
import { useAuth } from 'auth'
import { Route, Routes, useNavigate } from 'react-router'
import { ConfigurePhoto } from './ConfigurePhoto/ConfigurePhoto'
import { ConfigureTags } from './ConfigureTags'
import { ConfigureBlocks } from './ConfigureBlocks'

export const OnboardingPageRoute = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const profile = auth.profile
  const company = profile?.company

  if (!profile) {
    return <div>Loading...</div>
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ConfigureProfile
            profile={profile}
            onNext={() => {
              return company && !company.visible
                ? navigate('/onboarding/company')
                : navigate('/onboarding/tags')
            }}
          />
        }
      />

      {company && (
        <Route
          path="/company"
          element={
            <ConfigureCompany
              company={company!}
              onNext={() => {
                navigate('/onboarding/tags')
              }}
            />
          }
        />
      )}

      <Route path="/tags" element={<ConfigureTags />} />
      <Route path="/blocks/:industry" element={<ConfigureBlocks />} />
      <Route path="/photo/*" element={<ConfigurePhoto profile={profile} />} />
    </Routes>
  )
}
