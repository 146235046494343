import { Button, ButtonSize } from 'components/Elements'
import { FlexStack, Spacer } from 'components/Layout'
import { AnswerOption, Question } from '../components/Question'
import { useState } from 'react'
import { useUpdateProfileMutation } from 'graphql/types'
import { Row } from '@shareback/structural'
import { BackButton } from '../BackButton'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useNavigate } from 'react-router'

const cityQuestion = {
  message: 'Which city do you work in?',
  hasOther: true,
  otherPlaceholder: 'e.g. Austin',
  options: [
    {
      value: 'remote',
      text: 'Remote'
    },
    {
      value: 'london',
      text: 'London'
    },
    {
      value: 'dublin',
      text: 'Dublin'
    },
    {
      value: 'paris',
      text: 'Paris'
    },
    {
      value: 'amsterdam',
      text: 'Amsterdam'
    },
    {
      value: 'berlin',
      text: 'Berlin'
    },
    {
      value: 'new-york',
      text: 'New York'
    },
    {
      value: 'san-francisco',
      text: 'San Francisco'
    }
  ]
}

const industryQuestion = {
  message: 'Which industry are you in?',
  hasOther: true,
  otherPlaceholder: 'e.g. Legal, Banking, Journalism',
  options: [
    {
      value: 'startup',
      text: 'Startup'
    },
    {
      value: 'vc',
      text: 'VC'
    },
    {
      value: 'energy',
      text: 'Energy'
    },
    {
      value: 'finance',
      text: 'Finance'
    },
    {
      value: 'technology',
      text: 'Technology'
    },
    {
      value: 'manufacturing',
      text: 'Manufacturing'
    },
    {
      value: 'healthcare',
      text: 'Healthcare'
    },
    {
      value: 'real-estate',
      text: 'Real Estate'
    },

    {
      value: 'retail',
      text: 'Retail'
    },
    {
      value: 'entertainment-media',
      text: 'Entertainment'
    },
    {
      value: 'events',
      text: 'Events'
    },
    {
      value: 'consulting',
      text: 'Consulting'
    }
  ]
}

const checkAnswerConfigured = (answer: AnswerOption | null | undefined): answer is AnswerOption => {
  return !!(answer && (answer.value !== 'other' || (answer.value === 'other' && !!answer.text)))
}

export const ConfigureTags = () => {
  const navigate = useNavigate()

  const [city, setCity] = useState<AnswerOption>()
  const [industry, setIndustry] = useState<AnswerOption>()
  const [updateProfile] = useUpdateProfileMutation()

  const isBasicsFilledIn = checkAnswerConfigured(city) && checkAnswerConfigured(industry)

  return (
    <AppPageLayout>
      <Spacer height={40} />

      <Row horizontal="spaced" fill>
        <Row gap={10}>
          <BackButton to="/onboarding" />
        </Row>

        <Row>1 / 3</Row>
      </Row>

      <FlexStack gap={20}>
        <Question {...cityQuestion} answer={city} onAnswer={setCity} />

        {checkAnswerConfigured(city) && (
          <>
            <Question
              {...industryQuestion}
              answer={industry}
              onAnswer={(answer) => {
                setIndustry(answer)

                setTimeout(() => {
                  document.getElementById('configure-tags-next')?.scrollIntoView()
                }, 10)
              }}
            />
          </>
        )}

        {isBasicsFilledIn && (
          <Button
            id="configure-tags-next"
            async
            size={ButtonSize.Large}
            onClick={async () => {
              await updateProfile({
                variables: {
                  input: {
                    location: city.text,
                    industry: industry.text
                  }
                }
              })

              const next = ['vc', 'startup'].includes(industry.value) ? industry.value : 'other'

              navigate(`/onboarding/blocks/${next}`)
            }}
          >
            Next
          </Button>
        )}
      </FlexStack>
    </AppPageLayout>
  )
}
