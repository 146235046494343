import Area, {
  TextareaAutosizeProps as AreaProps
} from 'react-textarea-autosize'
import $ from 'classnames'
import { forwardRef } from 'react'
import { Row } from '@shareback/structural'

import styles from './Field.module.scss'

export interface TextareaProps extends AreaProps {
  label?: string
  value?: string
  allowNewline?: boolean
  fill?: boolean
  error?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      className,
      maxLength,
      allowNewline = true,
      fill,
      value,
      onKeyDown,
      error,
      ...props
    },
    ref
  ) => (
    <div className={$(styles.container, fill && styles.fill)}>
      {label && (
        <Row fill horizontal="spaced" className={styles.label}>
          <div>{label}</div>
        </Row>
      )}

      <Area
        {...props}
        ref={ref}
        maxLength={maxLength && maxLength}
        value={value}
        className={$(
          styles.field,
          styles.primary,
          styles.textarea,
          fill && styles.fill,
          className
        )}
        onKeyDown={(event) => {
          /**
           * Do not submit form when Enter is pressed
           */
          if (event.key === 'Enter') {
            event.stopPropagation()

            if (!allowNewline) {
              event.preventDefault()
            }
          }

          onKeyDown?.(event)
        }}
      />

      {error && (
        <Row fill horizontal="spaced" className={styles.error}>
          {error}
        </Row>
      )}
    </div>
  )
)
