import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { User } from 'firebase/auth'
import { auth } from './firebase'
import { useAuthState } from './hooks'
import { CompanyFragment, ProfileFragment, useGetSelfQuery } from 'graphql/types'

type SelfFragment = ProfileFragment & { company?: CompanyFragment }

type AuthContextValue = {
  user: User | null
  loading: boolean
  profile: SelfFragment | null
  isTester: boolean
}

const AuthContext = createContext<AuthContextValue>({
  loading: true,
  user: null,
  profile: null,
  isTester: false
})

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, loading] = useAuthState(async (user) => {
    if (user) {
      query.refetch()
    }
  })

  const query = useGetSelfQuery({
    fetchPolicy: 'cache-and-network',
    skip: loading || !user
  })

  // useEffect(() => {
  //   if (user?.uid && !query.loading) {
  //     query.refetch()
  //   }
  // }, [user?.uid, query.loading])

  useEffect(() => {
    const handle = setInterval(async () => {
      const user = auth.currentUser

      if (user) {
        await user.getIdToken(true)
      }
    }, 10 * 60 * 1000)

    return () => clearInterval(handle)
  }, [])

  const profile = (query.data?.self || null) as SelfFragment | null
  // const authenticated = !!(!loading && user && profile)

  return (
    <AuthContext.Provider
      value={{
        user: user || null,
        loading: loading || query.loading,
        profile,
        isTester: user?.email?.includes('@shareback.io') ?? false
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
