import { Avatar, Icon, Modal, ModalProps, Textarea } from 'components/Elements'

import styles from './CommentButton.module.scss'

export interface CommentButtonProps {
  onClick: () => void
}

export const CommentButton = ({ onClick }: CommentButtonProps) => {
  return (
    <div className={styles.button} onClick={onClick}>
      <Icon name="ri-send-plane-2-fill" size={18} />
    </div>
  )
}
