import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from 'auth'
import { SkeletonTheme } from 'react-loading-skeleton'
import { StrictMode } from 'react'
import { AppRouter } from 'AppRouter'
import { Toaster } from 'react-hot-toast'
import { blitz } from 'controller/blitz'

function App() {
  return (
    <StrictMode>
      <SkeletonTheme baseColor="#1f1f1f" highlightColor="#333">
        <ApolloProvider client={blitz.graphql}>
          <AuthProvider>
            <AppRouter />
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              containerStyle={{ zIndex: 200 }}
            />
          </AuthProvider>
        </ApolloProvider>
      </SkeletonTheme>
    </StrictMode>
  )
}

export default App
