export const getRoleDescription = (role?: string, companyName?: string) => {
  if (!role) {
    return ''
  }

  if (!companyName) {
    return role
  }

  return `${role} @ ${companyName}`
}
