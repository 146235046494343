import { Row, Stack } from '@shareback/structural'
import { ReactComponent as Logo } from 'assets/logo_grey.svg'

import styles from './PoweredBy.module.scss'

export const PoweredBy = () => {
  return (
    <div className={styles.container}>
      <a href="https://shareback.com" target="_blank" className={styles.component}>
        <Row horizontal="center" gap="0.5em">
          Powered by <Logo height="1.2em" width="1.2em" /> Shareback
        </Row>
      </a>
    </div>
  )
}
