import $ from 'classnames'
import { forwardRef, ReactNode, useMemo } from 'react'
import { AvatarLoading } from './AvatarLoading'

import styles from './AvatarBase.module.scss'

export interface AvatarBaseProps {
  size: number
  url: string | null | undefined
  clickable?: boolean
  className?: string
  radius?: string | number
  disabled?: boolean
  children?: ReactNode
  onClick?: () => void
}

const useSize = (size: number) => {
  return useMemo(() => {
    return {
      height: size,
      width: size
    }
  }, [size])
}

export const AvatarBase = forwardRef<HTMLDivElement, AvatarBaseProps>(
  ({ size, url = '', clickable, className, radius, disabled, ...props }, ref) => {
    const computedSize = useSize(size)

    if (!url) {
      /* When URL is empty - show loading skeleton */
      return <AvatarLoading size={size} clickable={clickable} />
    }

    return (
      <div
        {...props}
        ref={ref}
        className={$(
          styles.component,
          className,
          clickable && styles.clickable,
          disabled && styles.disabled
        )}
        style={{
          ...computedSize,
          backgroundImage: `url(${url})`,
          borderRadius: radius
        }}
      />
    )
  }
)
