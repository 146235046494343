import $ from 'classnames'

import styles from './ProfileDescription.module.scss'
import { useNavigate } from 'react-router'
import { Row } from '@shareback/structural'
import { ReactNode } from 'react'

export interface ProfileDescriptionProps {
  description: ReactNode
  link?: string
}

export const ProfileDescription = ({
  description,
  link
}: ProfileDescriptionProps) => {
  const navigate = useNavigate()

  return (
    <Row
      horizontal="center"
      className={$(styles.container, link && styles.link)}
      onClick={() => {
        if (link) {
          navigate(link)
        }
      }}
    >
      <div className={styles.text}>{description}</div>
    </Row>
  )
}
