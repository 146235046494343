import { Block } from '../Block_v2'
import { ModalManager, useModal } from 'components/Elements'
import { QuestionModal } from './QuestionModal'
import { BlockBase } from '../Block_v2/types'
import { useGetCommentsQuery } from 'graphql/types'

export interface QuestionProps {
  block: BlockBase
  onClick?: (block: BlockBase) => void
}

export const Question = ({ block, onClick }: QuestionProps) => {
  const modal = useModal()

  const { data, loading } = useGetCommentsQuery({
    variables: {
      blockId: block.id
    }
  })

  const count = data?.comments.length ?? 0

  return (
    <>
      <Block
        icon="ri-question-line"
        sub={loading ? '&nbsp;' : `${count} answer${count - 1 ? 's' : ''}`}
        onClick={() => {
          if (onClick) {
            return onClick(block)
          }

          modal.show(block.id, { block })
        }}
      >
        {block.content}
      </Block>
      <ModalManager name={block.id} component={QuestionModal} />
    </>
  )
}
