import { CasualButton } from 'routes/HomePage/CasualButton'
import $ from 'classnames'

import styles from './IconTabs.module.scss'
import { ColumnGrid } from './ColumnGrid'

export interface IconTabsProps {
  options: {
    id: string
    label: string
    icon: string
  }[]
  value: string
  onChange: (value: string) => void
}

export const IconTabs = ({ value, onChange, options }: IconTabsProps) => {
  return (
    <ColumnGrid gap={10} columns={3}>
      {options.map((option) => (
        <CasualButton
          key={option.id}
          className={$(styles.option, value === option.id && styles.selected)}
          icon={option.icon}
          label={option.label}
          onClick={async () => onChange(option.id)}
        />
      ))}
    </ColumnGrid>
  )
}
