import { Button, ButtonSize, Input } from 'components/Elements'
import { FlexStack, Spacer } from 'components/Layout'
import { ProfileFragment, useUpdateProfileMutation } from 'graphql/types'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useFollowupInvite } from './useFollowupInvite'

export interface ConfigureProfileProps {
  profile: ProfileFragment
  onNext: (value: FormValues) => void
}

type FormValues = {
  name: string
  role: string
  identifier: string
}

const mapError = (error: FieldError | undefined) => {
  if (!error) {
    return ''
  }

  if (error.type === 'required') {
    return 'This field is required'
  }

  if (error.type === 'maxLength') {
    return 'This field is too long'
  }

  if (error.type === 'minLength') {
    return 'This field is too short'
  }

  return error.message ?? error.type ?? ''
}

type GraphQLValidationErrorExtensions = {
  code: 'VALIDATION_ERROR'
  errors: { property: string; message: string }[]
}

const isValidationErrorExtensions = (
  extensions: any
): extensions is GraphQLValidationErrorExtensions => {
  return extensions?.code === 'VALIDATION_ERROR'
}

export const ConfigureProfile = ({
  profile,
  onNext
}: ConfigureProfileProps) => {
  const [updateProfile] = useUpdateProfileMutation()

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onSubmit',
    values: {
      name: profile.name || '',
      role: profile.role || '',
      identifier: profile.identifier || ''
    }
  })

  useFollowupInvite()

  const onSubmit = async (values: FormValues) => {
    await updateProfile({
      variables: {
        input: {
          ...values,
          visible: true
        }
      },
      onError(error) {
        error.graphQLErrors.map((error) => {
          if (isValidationErrorExtensions(error.extensions)) {
            error.extensions.errors.forEach((error) => {
              setError(error.property as keyof FormValues, {
                type: 'validation',
                message: error.message
              })
            })
          }
        })

        throw error
      }
    })

    onNext(values)
  }

  return (
    <AppPageLayout>
      <Spacer height={40} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexStack fill gap={40}>
          <FlexStack gap={12}>
            <h2 style={{ margin: 0 }}>Welcome to Blitz! 👋</h2>

            <div style={{ opacity: 0.6 }}>
              We just need a few details to finish creating your account. You
              can always change these later.
            </div>
          </FlexStack>

          <FlexStack fill gap={12}>
            <Input
              label="Display name"
              fill
              placeholder="E.g. John Smith"
              {...register('name', {
                required: true,
                maxLength: 50,
                minLength: 2
              })}
              error={mapError(errors.name)}
            />

            <Controller
              control={control}
              name="identifier"
              rules={{
                required: true,
                maxLength: 20,
                minLength: 4
              }}
              render={({ field: { onChange, name, value } }) => (
                <Input
                  fill
                  label="Link"
                  description={value.length > 0 ? `blitz.so/${value}` : ''}
                  placeholder="URL"
                  value={value}
                  onChange={(event) => {
                    const $value = event.target.value
                      .toLowerCase()
                      .replace(/[\W]+/g, '_')
                      .substring(0, 20)

                    onChange($value)
                  }}
                  error={mapError(errors.identifier)}
                />
              )}
            />

            <Input
              label="Role"
              placeholder="E.g. Founder & CEO"
              {...register('role', {
                required: true,
                maxLength: 30,
                minLength: 2
              })}
              error={mapError(errors.role)}
            />
          </FlexStack>

          <Button fill async type="submit" size={ButtonSize.Large}>
            Next
          </Button>
        </FlexStack>
      </form>
    </AppPageLayout>
  )
}
