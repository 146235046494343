import styles from './PrivacyPolicy.module.scss'

export const PrivacyPolicy = () => (
  <div className={styles.container}>
    By creating an account, you agree to our{' '}
    <a
      href="https://policy.blitz.so/terms/"
      title="Terms of Service"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Service
    </a>{' '}
    and have read and understood{' '}
    <a
      href="https://policy.blitz.so/privacy/"
      title="Privacy Policy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
    .
  </div>
)
