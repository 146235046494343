import { create } from 'zustand'
import { BlockFragment } from 'graphql/types'
import { devtools } from 'zustand/middleware'
import { BlocksContextProviderProps } from './blocks.context'

export interface BlocksStoreState {
  blocks: Block[]
  addBlock: (block: Block) => void
  removeBlock: (index: number) => void
  updateBlock: (index: number, updatedBlock: Partial<Block>) => void
  swapBlocks: (index1: number, index2: number) => void
  import: (blocks: BlockFragment[]) => void
}

export type Block = {
  id: string
  type: string
  sub: string
  sup: string
  color: string
  icon: string
  content: string
  url: string
  // hidden: boolean
}

export interface LinkBlock extends Block {
  type: 'link'
}

export const createBlock = (type: string, values?: Partial<Block>): Block => {
  return {
    id: '',
    type: type,
    content: '',
    sub: '',
    sup: '',
    color: '',
    icon: '',
    url: '',
    // hidden: false,
    ...values
  }
}

export const formatBlock = (data: BlockFragment): Block => {
  return {
    id: data.id,
    type: data.type,
    content: data.content,
    sub: data.sub || '',
    sup: data.sup || '',
    color: data.color || '',
    icon: data.icon || '',
    url: data.url || ''
    // hidden: false
  }
}

export const useBlocksStore = create<BlocksStoreState>()(
  devtools((set) => ({
    blocks: [],

    import(data: BlockFragment[]) {
      return set(() => {
        return {
          blocks: data.map(formatBlock)
        }
      })
    },

    addBlock(block: Block) {
      return set((state) => {
        return {
          blocks: [...state.blocks, block]
        }
      })
    },

    removeBlock(index: number) {
      return set((state) => ({
        blocks: state.blocks.filter((_, i) => index !== i)
      }))
    },

    updateBlock(index: number, updatedFields: Partial<Block>) {
      set((state) => {
        return {
          blocks: state.blocks.map((block, i) => {
            if (i === index) {
              return { ...block, ...updatedFields }
            }

            return block
          })
        }
      })
    },

    swapBlocks(index1: number, index2: number) {
      return set((state) => {
        const blocks = [...state.blocks]
        const temp = blocks[index1]

        blocks[index1] = blocks[index2]
        blocks[index2] = temp

        return {
          blocks: [...blocks]
        }
      })
    }
  }))
)

export type BlockStore = BlocksContextProviderProps
