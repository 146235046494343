import { Avatar } from 'components/Elements'
import $ from 'classnames'

import styles from './ProfileAvatar.module.scss'

export interface ProfileAvatarProps {
  picture: string
  size?: number
  onClick?: () => void
}

export const ProfileAvatar = ({
  picture,
  size = 200,
  onClick
}: ProfileAvatarProps) => (
  <div className={$(styles.container, !!onClick && styles.clickable)}>
    <div className={styles.background}>
      <img src={picture} className={styles.shadow} tabIndex={-1} />
      <div className={styles.mask} />
    </div>

    <Avatar
      className={styles.avatar}
      size={size}
      url={picture}
      onClick={onClick}
    />
  </div>
)
