import LogoImage from 'assets/Logo_64.png'
import { Avatar } from 'components/Elements'
import { useNavigate } from 'react-router'
import { Button } from 'routes/ProfilePage/Button'

export const Logo = () => {
  const navigate = useNavigate()

  return (
    <Button onClick={() => navigate(`/`)}>
      <Avatar url={LogoImage} size={20} />
    </Button>
  )
}
