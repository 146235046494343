import { useAuth } from 'auth'
import {
  Button,
  ButtonLevel,
  ButtonLink,
  ButtonSize
} from 'components/Elements'
import { FlexStack } from 'components/Layout'
import { Theme } from 'components/Theme'
import {
  GetGroupInfoDocument,
  useGetGroupInfoQuery,
  useJoinGroupMutation
} from 'graphql/types'
import { useParams } from 'react-router'
import { GroupAbout } from './GroupAbout'
import { UnauthenticatedFooter } from './UnauthenticatedFooter'
import { Row } from '@shareback/structural'

export const GroupInvitePage = () => {
  const params = useParams()
  const auth = useAuth()

  const id = params.id ?? ''

  const query = useGetGroupInfoQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id
    },
    skip: auth.loading
  })

  const [joinGroup] = useJoinGroupMutation()

  if (auth.loading || query.loading) {
    return <Theme.Loading />
  }

  const group = query.data?.groupInfo

  if (!group) {
    return (
      <Theme.Container verticalAlignment="center">
        <div>Group doesn't exist</div>
      </Theme.Container>
    )
  }

  return (
    <Theme.Container verticalAlignment="center">
      <FlexStack gap={20} align="center">
        <GroupAbout group={group} />

        <Row>
          {!!auth.user ? (
            <>
              {group.status === 'pending' && (
                <div style={{ textAlign: 'center' }}>Access is pending.</div>
              )}

              {group.status === 'granted' && (
                <ButtonLink
                  size={ButtonSize.Large}
                  level={ButtonLevel.Secondary}
                  link={`/g/${group.id}`}
                >
                  Go to group
                </ButtonLink>
              )}

              {group.status === 'none' && (
                <Button
                  async
                  size={ButtonSize.Large}
                  onClick={async () => {
                    await joinGroup({
                      variables: {
                        groupId: params.id!
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: GetGroupInfoDocument,
                          variables: {
                            id
                          }
                        }
                      ]
                    })
                  }}
                >
                  Join the group
                </Button>
              )}
            </>
          ) : (
            <UnauthenticatedFooter groupId={group.id} />
          )}
        </Row>
      </FlexStack>
    </Theme.Container>
  )
}
