import React, { useState, useEffect, useRef } from 'react'

import QrScanner from 'qr-scanner'

import styles from './QRCodeScanner.module.scss'
import config from 'config'

interface QRScannerProps {
  onScan: (data: string) => void
}

export const QRScanner: React.FC<QRScannerProps> = ({ onScan }) => {
  const [error, setError] = useState<string | null>(null)

  const [hasPermission, setHasPermission] = useState<boolean | undefined>(
    undefined
  )

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    let scanner: QrScanner

    const createScanner = async () => {
      scanner = new QrScanner(
        videoRef.current!,
        (result) => {
          console.log(result.data)

          if (result) {
            const data = result.data

            if (
              data.startsWith('https://blitz.so') ||
              data.startsWith('https://beta.blitz.so')
            ) {
              scanner.stop()

              window.open(data, data)
              console.log(data)
            }
          }
        },
        {
          onDecodeError(error) {
            // console.log(error)
          }
        }
      )

      await scanner.setCamera('environment')
      await scanner.start()
    }

    createScanner()
  }, [videoRef.current])

  useEffect(() => {
    async function checkCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        })

        setHasPermission(true)
        stream.getTracks().forEach((track) => track.stop())
      } catch (error) {
        setHasPermission(false)
      }
    }

    checkCameraPermission()
  }, [])

  return (
    <div className={styles.scanner}>
      <video
        className={styles.video}
        ref={videoRef}
        autoPlay
        muted
        playsInline
      />

      {error && <p className={styles.error}>{error}</p>}

      {hasPermission === false && (
        <p className={styles.error}>
          Camera access denied. Please enable camera permissions for this
          website.
        </p>
      )}
    </div>
  )
}
