import { FlexRow, FlexStack } from 'components/Layout'
import { ReactNode } from 'react'

import styles from './ProfileAbout.module.scss'
import { ProfileDescription } from './ProfileDescription'

export interface ProfileAboutProps {
  title: string
  link?: string
  description?: ReactNode
  onClick?: () => void
  children?: ReactNode
}

export const ProfileAbout = ({
  title,
  description,
  link,
  children,
  onClick
}: ProfileAboutProps) => (
  <FlexStack className={styles.about} onClick={onClick}>
    <FlexRow horizontal="middle" className={styles.name}>
      {title}
    </FlexRow>
    {/* 
    <FlexRow
      horizontal="middle"
      className={styles.role}
      onClick={descriptionOnClick}
    >
      {description}
    </FlexRow> */}
    {description && (
      <ProfileDescription link={link} description={description} />
    )}

    {children}
  </FlexStack>
)
