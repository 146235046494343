import $ from 'classnames'

import styles from './Question.module.scss'
import { FlexRow, FlexStack } from 'components/Layout'
import { Input } from 'components/Elements'

export interface AnswerOption {
  value: string
  text: string
}

export interface QuestionProps {
  message: string
  options: AnswerOption[]
  answer?: AnswerOption
  hasOther?: boolean
  otherPlaceholder?: string
  onAnswer: (answer: AnswerOption) => void
}

export const Question = ({
  message,
  options,
  answer,
  hasOther,
  otherPlaceholder,
  onAnswer
}: QuestionProps) => {
  return (
    <FlexStack fill gap={30} className={styles.container}>
      <FlexStack gap={20}>
        <div className={styles.message}>{message}</div>

        <FlexRow gap={10} wrap fill>
          {options.map((option) => (
            <div
              key={option.value}
              className={$(
                styles.option,
                answer?.value === option.value && styles.selected
              )}
              onClick={async () => {
                onAnswer(option)
              }}
            >
              {option.text}
            </div>
          ))}

          {hasOther && (
            <div
              className={$(
                styles.option,
                answer?.value === 'other' && styles.selected
              )}
              onClick={async () => {
                onAnswer({ value: 'other', text: '' })
              }}
            >
              Other
            </div>
          )}
        </FlexRow>
      </FlexStack>

      {answer?.value === 'other' && (
        <Input
          value={answer.text}
          placeholder={otherPlaceholder || 'Type here...'}
          onChange={(event) => {
            onAnswer({ ...answer, text: event.target.value })
          }}
        />
      )}
    </FlexStack>
  )
}
