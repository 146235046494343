import { Centered, Spacer } from 'components/Layout'
import { MediaClient } from 'graphql/media'
import { Selfie } from 'components/Selfie/Selfie'
import { Route, Routes, useNavigate } from 'react-router'
import { ProfileFragment, UpdateProfileDocument } from 'graphql/types'
import { AppPageLayout } from 'v2/AppPageLayout'
import { Row } from '@shareback/structural'
import { BackButton } from '../BackButton'
import { Avatar, Button, ButtonSize } from 'components/Elements'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { blitz } from 'controller/blitz'
import { openSystemFileDialog } from 'components/Elements/AvatarInput/upload'
import { Column } from 'v2/Column'
import { Overlay } from 'components/Elements/Modal/Overlay'

export interface Props {
  profile: ProfileFragment
}

export const ConfigurePhoto = ({ profile }: Props) => {
  return (
    <>
      <ConfigureAvatarWithUpload profile={profile} />
      <Routes>
        <Route path="/camera" element={<ConfigureAvatarWithCamera />} />
      </Routes>
    </>
  )
}

const uploadPhoto = async (blob: File) => {
  const url = await MediaClient.uploadAvatar(blob)

  await blitz.graphql.mutate({
    mutation: UpdateProfileDocument,
    variables: {
      input: {
        photo: url
      }
    }
  })
}

export const ConfigureAvatarWithUpload = ({ profile }: Props) => {
  const navigate = useNavigate()

  return (
    <AppPageLayout>
      <Spacer height={40} />

      <Row horizontal="spaced" fill>
        <Row>
          <BackButton to="/onboarding/tags" />
        </Row>

        <Row>3 / 3</Row>
      </Row>

      <Row fill>Lastly, which photo do you want to use?</Row>

      <Avatar size={200} url={profile.photo} />

      <Column max={360}>
        <Row fill gap={10}>
          <CasualButton
            onClick={async () => {
              try {
                const file = await openSystemFileDialog('image/*')

                if (file) {
                  await uploadPhoto(file)
                }
              } catch (error) {
                console.log(error)
              }
            }}
            icon="ri-file-upload-line"
            label="Upload image"
          />

          <CasualButton
            onClick={() => {
              navigate(`/onboarding/photo/camera`)
            }}
            icon="ri-camera-line"
            label="Take a photo"
          />
        </Row>
      </Column>

      <Button
        fill
        size={ButtonSize.Large}
        onClick={() => {
          navigate(`/${profile.identifier}`)
        }}
      >
        Next
      </Button>
    </AppPageLayout>
  )
}

export const ConfigureAvatarWithCamera = () => {
  const navigate = useNavigate()

  return (
    <Overlay
      close={() => {
        navigate('/onboarding/photo')
      }}
    >
      <Centered window>
        <Selfie
          rim={false}
          onContinue={async (photo) => {
            try {
              await uploadPhoto(photo)

              navigate('/onboarding/photo')
            } catch (error) {
              console.log(error)
            }
          }}
        />
      </Centered>
    </Overlay>
  )
}
