import { Absolute, FlexStack, Spacer } from 'components/Layout'
import { Input, Textarea } from 'components/Elements'
import { useEffect, useState } from 'react'
import {
  GetGroupsDocument,
  GroupFragment,
  GroupMemberFragment,
  useArchiveGroupMutation,
  useUpdateGroupMutation
} from 'graphql/types'
import { useNavigate } from 'react-router'
import { ChangePictureButton } from 'components/Theme/ChangePictureButton'
import { AppPageLayout } from 'v2/AppPageLayout'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { useBlocksStore } from 'components/BlocksContext'
import { EditBlocks } from 'components/Blocks/EditBlocks'
import { Row } from '@shareback/structural'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'
import { IconTabs } from './IconTabs'
import { ManageMembers } from './ManageMembers'
import { ManageCheckins } from './ManageCheckins/ManageCheckins'
import { ManageEvents } from './ManageEvents'

export interface EditGroupPageProps {
  membership: GroupMemberFragment
  group: GroupFragment
}

export const EditGroupPage = ({ membership, group }: EditGroupPageProps) => {
  const navigate = useNavigate()
  const [photo, setPhoto] = useState(group.photo || '')
  const [name, setName] = useState(group.name || '')
  const [description, setDescription] = useState(group.description || '')
  const blocksStore = useBlocksStore()
  const [archiveGroup] = useArchiveGroupMutation()

  useEffect(() => {
    blocksStore.import(group.blocks)
  }, [])

  const [updateGroup] = useUpdateGroupMutation()
  const [tab, setTab] = useState('general')

  return (
    <AppPageLayout header={<Spacer height={40} />}>
      <FlexStack>
        <ProfileAvatar picture={photo} />

        <Absolute right={20} top={20}>
          <ChangePictureButton
            onChange={(url) => {
              setPhoto(url)
            }}
          />
        </Absolute>
      </FlexStack>

      <IconTabs
        options={[
          {
            id: 'general',
            label: 'General',
            icon: 'fi-rr-cube'
          },
          {
            id: 'links',
            icon: 'fi-rr-database',
            label: 'Links'
          },
          {
            id: 'members',
            icon: 'fi-rr-following',
            label: 'Members'
          }
          // {
          //   id: 'checkins',
          //   icon: 'ri-qr-code-line',
          //   label: 'Checkins'
          // },
          // {
          //   id: 'events',
          //   icon: 'ri-calendar-event-line',
          //   label: 'Events'
          // }
        ]}
        value={tab}
        onChange={setTab}
      />

      {tab === 'general' && (
        <>
          <FlexStack fill gap={10}>
            <Input
              fill
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <Textarea
              fill
              minRows={2}
              placeholder="About (optional)"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </FlexStack>

          <Row fill>
            <CasualButton
              style={{
                padding: '20px 10px',
                background: '#ff3b2f',
                color: '#eee'
              }}
              label="Delete group"
              onClick={async () => {
                if (confirm('Are you sure you want to delete this group')) {
                  await archiveGroup({
                    variables: {
                      id: group.id
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [GetGroupsDocument]
                  })

                  navigate('/')
                }
              }}
              icon=""
            />
          </Row>
        </>
      )}

      {tab === 'links' && <EditBlocks store={blocksStore} />}
      {tab === 'members' && <ManageMembers viewerId={membership.profile.id} groupId={group.id} />}
      {tab === 'checkins' && <ManageCheckins group={group} />}
      {tab === 'events' && <ManageEvents group={group} />}

      <Spacer height={60} />

      <FloatingActionMenu
        actions={[
          {
            label: 'Go back',
            action() {
              navigate(`/g/${group.id}`)
            }
          },
          {
            label: 'Save changes',
            color: '#b7fbc9',
            async action() {
              await updateGroup({
                variables: {
                  id: group.id,
                  input: {
                    name,
                    description,
                    photo,
                    blocks: blocksStore.blocks
                  }
                }
              })

              navigate(`/g/${group.id}`)
            }
          }
        ]}
      />
    </AppPageLayout>
  )
}
