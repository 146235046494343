import { Template } from 'routes/Auth/Template/Template'
import { GoogleButton } from 'routes/Auth/GoogleButton'
import { PrivacyPolicy } from '../PrivacyPolicy'
import { LinkedInButton } from '../LinkedInButton'
import { EmailButton } from '../EmailButton/EmailButton'

export const Auth = () => {
  return (
    <Template
      title="Sign in"
      description="Please use your work email address if you want to be added to your company’s group"
    >
      <GoogleButton />
      <LinkedInButton />
      <EmailButton />

      <PrivacyPolicy />
    </Template>
  )
}
