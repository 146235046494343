import { Row, Stack } from '@shareback/structural'
import { ProfileListItem } from 'components/ProfileListItem'
import {
  GetGroupMembersDocument,
  useGetGroupMembersQuery,
  useRemoveGroupMemberMutation
} from 'graphql/types'
import { AButton } from 'routes/HomePage/AButton'
import { CasualButton } from 'routes/HomePage/CasualButton'

import { getRoleDescription } from 'v2/_utils/getRoledescription'

export interface ManageMembersProps {
  viewerId: string
  groupId: string
}

export const ManageMembers = ({ viewerId, groupId }: ManageMembersProps) => {
  const { data, loading } = useGetGroupMembersQuery({
    variables: {
      id: groupId
    }
  })

  const [removeMember] = useRemoveGroupMemberMutation()

  const members = data?.group?.members ?? []

  return (
    <>
      <AButton
        icon="ri-download-line"
        onClick={() => {
          const header = `"Name", "Email", "Role", "Company"\r\n`

          const list = members
            .map((member) => {
              return [
                member.profile.name ?? '',
                member.profile.email ?? '',
                member.profile.role ?? '',
                member.profile.company?.name ?? ''
              ]
                .map(String)
                .map((v) => v.replaceAll('"', '""'))
                .map((v) => `"${v}"`)
                .join(',')
            })
            .join('\r\n')

          const csv = header + list
          const blob = new Blob([csv], { type: 'text/csv' })
          const url = URL.createObjectURL(blob)

          // Create a link to download it
          var pom = document.createElement('a')
          pom.href = url
          pom.setAttribute('download', `members.csv`)
          pom.click()
        }}
      >
        Export list{' '}
      </AButton>

      <Stack fill>
        {members.map(({ profile, role }) => (
          <ProfileListItem
            key={profile.id}
            title={profile.name}
            description={getRoleDescription(profile.role, profile.company?.name)}
            photo={profile.photo}
            more={
              viewerId !== profile.id && role !== 'admin' ? (
                <Row shrink={false} gap={8} style={{ paddingLeft: 8 }}>
                  <CasualButton
                    label="Remove"
                    onClick={async () => {
                      await removeMember({
                        variables: {
                          groupId,
                          profileId: profile.id
                        },
                        refetchQueries: [
                          {
                            query: GetGroupMembersDocument,
                            variables: {
                              id: groupId
                            }
                          }
                        ]
                      })
                    }}
                  />
                </Row>
              ) : null
            }
          />
        ))}
      </Stack>
    </>
  )
}
