import React from 'react'
import styles from './FullScreenLayout.module.scss'

type FullScreenLayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
}

export const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  className = '',
  header,
  footer,
  children
}) => {
  return (
    <div className={`${styles.fullScreenLayout} ${className}`}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  )
}
