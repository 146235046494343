import { Link } from './Link'
import { Prompt } from './Prompt'
import { BlockBase } from './Block_v2/types'
import { BlockFragment } from 'graphql/types'
import { Stack } from '@shareback/structural'
import { formatBlock } from 'components/BlocksContext'
import { Question } from './Question'

export interface ViewBlocksProps {
  blocks: (BlockBase | BlockFragment)[]
  edit?: boolean
  onClick?: (block: BlockBase | null) => void
  onAdd?: (type: string) => void
}

const mapComponent = (block: BlockBase) => {
  switch (block.type) {
    case 'link':
      return Link

    case 'prompt':
      return Prompt

    case 'question':
      return Question
  }
}

export const ViewBlocks = ({ blocks = [], onClick }: ViewBlocksProps) => {
  return (
    <Stack fill gap={10}>
      {blocks.map(formatBlock).map((block, index) => {
        const Component = mapComponent(block)

        if (!Component) {
          return null
        }

        return <Component key={index} block={block} onClick={onClick} />
      })}
    </Stack>
  )
}
