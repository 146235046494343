import { PageLayout } from 'v2/PageLayout/PageLayout'
import { ContactsPage } from './ContactsPage'

export const ContactsPageRoute = () => {
  return (
    <PageLayout glow>
      <ContactsPage />
    </PageLayout>
  )
}
