import { Block } from 'components/BlocksContext'
import { FieldLabel, Textarea } from 'components/Elements'
import { Modal, ModalProps, useModal } from 'components/Elements'
import * as yup from 'yup'
import styles from './BlockEditor.module.scss'
import { Stack } from '@shareback/structural'
import { EditBlockModalFooter } from './EditBlockModalFooter'
import { useBlockEditForm } from './useBlockEditForm'

interface FormValues {
  content: string
}

const validationSchema = yup.object().shape({
  content: yup.string().required('This field is required').trim()
})

interface ModalAttributes {
  initialValues: FormValues
  onCommit: (block: Block) => void
  onRemove?: () => void
}

export const EditQuestionModal = (props: ModalProps) => {
  const modal = useModal()

  const { initialValues, onCommit, onRemove } =
    props.attributes! as ModalAttributes

  const { form, submit } = useBlockEditForm<FormValues>(
    'question',
    {
      content: initialValues?.content ?? ''
    },
    validationSchema
  )

  console.log('Attributes', props.attributes)

  const onSubmit = (values: FormValues) => {
    const block = submit(values)

    onCommit?.(block)
    modal.hide()
  }

  return (
    <Modal {...props} maxWidth={440}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack gap={24} className={styles.container}>
          <Stack fill gap={14}>
            <Textarea
              minRows={1}
              maxRows={2}
              label="Question"
              placeholder="e.g. What do you think about AI?"
              error={form.formState.errors.content?.message}
              {...form.register('content')}
            />
          </Stack>

          <EditBlockModalFooter onRemove={onRemove} />
        </Stack>
      </form>
    </Modal>
  )
}
