import { ReactNode } from 'react'
import $ from 'classnames'

import styles from './Button.module.scss'

export interface ButtonProps {
  children: ReactNode
  elevated?: boolean
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export const Button = ({
  elevated,
  children,
  onClick,
  onFocus,
  onBlur
}: ButtonProps) => (
  <div
    className={$(styles.button, elevated && styles.elevated)}
    role="button"
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {children}
  </div>
)
