import React, { useState } from 'react'
import { useVoiceRecorder } from './useVoiceRecorder'
import { Centered, Spacer, Stack } from '@shareback/structural'
import { VoicePlayer } from './VoicePlayer'
import { RecordButton } from './RecordButton'
import { MediaClient } from 'graphql/media'
import { Duration } from './Duration'
import { Transcript } from './Transcript'
import { AnimatePresence } from 'framer-motion'
import { AppPageLayout } from 'v2/AppPageLayout'

const VoiceRecorder: React.FC = () => {
  const [error, setError] = useState('')
  const [transcript, setTranscript] = useState('')

  const { recording, start, stop, audioUrl } = useVoiceRecorder({
    onError: (message) => {
      setError(message)
    },
    async onReady(blob) {
      const { url, transcript = '' } = await MediaClient.uploadFile(blob, {
        transcribe: '1'
      })

      setTranscript(transcript)
    }
  })

  return (
    <AppPageLayout>
      <Centered window>
        <Stack fill gap={20} style={{ position: 'relative' }}>
          <Stack fill horizontal="center" gap={10}>
            <Duration enabled={recording} />

            <RecordButton
              recording={recording}
              onClick={() => {
                recording ? stop() : start()
                setTranscript('')
              }}
            />
          </Stack>

          {audioUrl ? (
            <VoicePlayer audioUrl={audioUrl} />
          ) : (
            <Spacer height={70} />
          )}

          <AnimatePresence mode="wait">
            {transcript && <Transcript message={transcript}></Transcript>}
          </AnimatePresence>
        </Stack>
      </Centered>
    </AppPageLayout>
  )
}

export default VoiceRecorder
