import { useLayoutEffect, useRef } from 'react'
import styles from './Rim.module.scss'

interface RimProps {
  size: number
  animate: boolean
  speed?: number
  lineWidth?: number
  visible?: boolean
}

const drawCircle = (
  context: CanvasRenderingContext2D,
  size: number,
  lineWidth: number,
  colorStopA: number,
  colorStopB: number
) => {
  const center = { x: size / 2, y: size / 2 }
  const radius = size / 2

  var gradient = context.createLinearGradient(0, 0, size, size)

  gradient.addColorStop(colorStopA, '#ff6767')
  gradient.addColorStop(colorStopB, 'rgba(255, 103, 103, 0)')

  context.beginPath()
  context.lineWidth = lineWidth
  context.strokeStyle = gradient
  context.arc(center.x, center.y, radius - lineWidth, 0, 2 * Math.PI)
  context.stroke()
}

export const Rim = ({
  animate,
  size,
  visible,
  speed = 0.01,
  lineWidth = 4
}: RimProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  // update the counter
  useLayoutEffect(() => {
    let timerId: number

    const canvas = canvasRef.current!
    const context = canvas.getContext('2d')!
    const dpr = window.devicePixelRatio
    const rect = canvas.getBoundingClientRect()

    const width = rect.width * dpr
    const height = rect.height * dpr

    canvas.width = width
    canvas.height = height

    canvas.style.width = rect.width + 'px'
    canvas.style.height = rect.height + 'px'

    let colorStopA = 0
    let colorStopB = 0.7
    let direction = 0.01

    let rotation = 0.05

    const render = () => {
      if (animate) {
        timerId = requestAnimationFrame(render)
      }
      context.clearRect(0, 0, width, height)

      colorStopB += direction

      if (colorStopB <= 0.1 && direction === -0.01) {
        direction = -speed / 3
      }

      if (colorStopB <= 0.05) {
        direction = speed
      }

      if (colorStopB >= 0.9 && direction === 0.01) {
        direction = speed / 3
      }

      if (colorStopB >= 0.95) {
        direction = -speed
      }

      context.translate(width / 2, height / 2)
      context.rotate(rotation)
      context.translate(-width / 2, -height / 2)

      drawCircle(
        context,
        (width + height) / 2,
        lineWidth,
        colorStopA,
        colorStopB
      )
    }

    timerId = requestAnimationFrame(render)

    return () => {
      cancelAnimationFrame(timerId)
    }
  }, [animate])

  return (
    <div
      className={styles.container}
      style={{
        height: size,
        width: size,
        visibility: visible ? 'visible' : 'hidden'
      }}
    >
      <canvas
        className={styles.canvas}
        height={`${size}px`}
        width={`${size}px`}
        ref={canvasRef}
      />
    </div>
  )
}
