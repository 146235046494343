export const profilePrompts = [
  'What I need most right now is:',
  'Areas I can help you out are:',
  'Areas I need advice in are:',
  'My current obsessions are:',
  'My favourite topics to talk about are:',
  'One thing I can’t stand talking about is:',
  'My biggest challenge these days is:',
  'I think the future of Tech is:',
  'A hack that helped grow my business was:',
  'Advice I would give to someone starting out in my field is:',
  'My perfect partnership is:',
  'I am looking for an investor who is:',
  'The areas I invest are:',
  'My expertise is:',
  'The best part of my job is:',
  'My biggest ick is:',
  'An industry I am interested in working in is:',
  'My controversial opinion is:',
  'When I am not working, my go-to activity is:',
  'Something you wouldn’t know by looking at my CV is:',
  'My go-to work break is:',
  'A type of community I am looking to be involved in is:',
  'My favourite book/show is:'
]

export const groupPrompts = [
  'A unique skill or perspective I bring to the group is:',
  'How I differentiate myself within our community is:',
  'Your personal vision for the future is:',
  "An exciting new idea or project I'm contributing to our community is:",
  'The most rewarding aspect of participating in this group for me is:',
  "Interests or skills I'm looking to explore or develop further are:",
  'The people I am looking to meet are:',
  'Advice that I can provide to this group is:',
  'Advice I am hoping to receive from this group is:',
  'A conversation I would like to have with members of this group is:',
  'A bold idea to explore is:',
  'Topics I am looking to learn more about are:',
  'I collaborate with others who are:',
  'An idea to explore in this group is:'
]
