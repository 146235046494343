import { useAuth } from 'auth'
import { useGetHomePageQuery } from 'graphql/types'
import { useLocation, useNavigate } from 'react-router'
import { HomePage } from './HomePage'
import { ModalProvider } from 'components/Elements'
import { useEffect } from 'react'

export const HomePageRoute = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const location = useLocation()

  const skip = auth.loading || !auth.user || !auth.profile

  const query = useGetHomePageQuery({
    fetchPolicy: 'cache-and-network',
    skip
  })

  useEffect(() => {
    if (!auth.loading && !auth.user) {
      navigate('/auth', { replace: true })
    }
  }, [auth.user, auth.loading])

  const profile = auth.profile
  const company = profile?.company

  useEffect(() => {
    if (profile?.visible === false || company?.visible === false) {
      navigate(`/onboarding${location.search}`)
    }
  }, [profile?.visible, company?.visible])

  if (
    auth.loading ||
    query.loading ||
    !auth.user ||
    !query.data ||
    profile?.visible === false ||
    company?.visible === false
  ) {
    return null
  }

  const groups = query.data.groups || []
  const invites = query.data.invites || []

  return (
    <ModalProvider>
      <HomePage profile={profile!} invites={invites} groups={groups} company={company} />
    </ModalProvider>
  )
}
