import { useState, useEffect, useCallback, RefObject } from 'react'
import WaveSurfer from 'wavesurfer.js'

export interface WavesurferStyle {
  barWidth: number
  barRadius: number
  height: number
  barGap: number
}

export const useWavesurfer = (
  ref: RefObject<HTMLDivElement>,
  url: string,
  style: WavesurferStyle
) => {
  const [playing, setPlaying] = useState(false)
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()

  const togglePlay = useCallback(async () => {
    if (!wavesurfer) return

    try {
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause()
      } else {
        await wavesurfer.play()
      }
    } catch (error) {
      console.log(error)
    }
  }, [wavesurfer])

  useEffect(() => {
    if (!ref.current) return

    const instance = WaveSurfer.create({
      container: ref.current,
      waveColor: '#999a9a',
      progressColor: '#f8fd99',
      ...style,
      cursorWidth: 0
    })

    instance.on('play', () => {
      setPlaying(true)
    })

    instance.on('finish', () => {
      setPlaying(false)
    })

    instance.on('pause', () => {
      setPlaying(false)
    })

    setWavesurfer(instance)

    return () => {
      instance.unAll()
      instance.destroy()

      setPlaying(false)
    }
  }, [])

  useEffect(() => {
    if (wavesurfer && url) {
      wavesurfer.load(url)
      console.log(`Loaded ${url}`)
    }
  }, [wavesurfer, url])

  return [wavesurfer, playing, togglePlay] as const
}
