import { Row, Spacer, Stack } from '@shareback/structural'
import { useAuth } from 'auth'
import { Avatar } from 'components/Elements'
import { Card } from 'components/Records/Card'
import { PendingProfilesCard } from 'components/Records/PendingProfilesCard'
import { useGetContactsQuery } from 'graphql/types'
import { useNavigate } from 'react-router'
import { useNotificationsContext } from 'routes/NotificationsPage/NotificationsProvider'

export const ContactsPage = () => {
  const navigate = useNavigate()
  const { profile } = useAuth()

  const notifications = useNotificationsContext()

  const { data } = useGetContactsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !profile
  })

  const contacts = data?.contacts ?? []
  const matches = notifications.data?.notifications.matches ?? []

  return (
    <Stack>
      <h2 className="heading">Connections</h2>

      {matches.length > 0 && (
        <PendingProfilesCard
          photos={matches.slice(0, 5).map((v) => v.sender.photo)}
          onClick={() => {
            navigate('/notifications')
          }}
        >
          {matches.length} Pending request{matches.length === 1 ? '' : 's'}
        </PendingProfilesCard>
      )}

      <Spacer height={20} />

      {contacts.map((contact) => {
        return (
          <Stack key={contact.id} id={contact.target.id}>
            <Card
              clickable
              avatar={<Avatar size={44} url={contact.target.photo} />}
              onClick={() => {
                navigate('/' + contact.target.identifier)
              }}
            >
              <Stack gap="0.25em">
                <div>
                  {contact.target.name}
                  <span style={{ color: '#969296' }}>
                    {' · '} {contact.target.role}
                  </span>
                </div>

                {contact.group && (
                  <div style={{ color: '#969296', fontSize: 13 }}>
                    Connected via <b> {contact.group?.name}</b>
                  </div>
                )}
              </Stack>
            </Card>

            <Stack fill gap="0.5em" style={{ paddingTop: '0.5em' }}>
              <Row fill gap="1em" horizontal="right">
                {/* <Button>Book a meeting</Button> */}
                {/* <Button>Open profile</Button> */}
              </Row>
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  )
}
