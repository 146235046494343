import { motion } from 'framer-motion'

import styles from './Transcript.module.scss'

export interface TranscriptProps {
  message: string
}
export const Transcript = ({ message }: TranscriptProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.component}
    >
      {message}
    </motion.div>
  )
}
