import React, { useRef, useState } from 'react'
import styles from './Selfie.module.scss'
import Camera from './Camera'
import TakePhotoButton from './TakePhotoButton'
import { Absolute, FlexStack } from 'components/Layout'
import { Flash } from './Flash'
import { Rim } from './Rim'
import { dataURLtoBlob, dataURLtoFile, flipImage } from './utils'
import { Controls } from './Controls'
import { Avatar } from 'components/Elements'

interface SelfieProps {
  rim?: boolean
  onContinue: (photo: File) => void
}

export const Selfie = ({ rim, onContinue }: SelfieProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [flash, setFlash] = useState(false)
  const [photo, setPhoto] = useState<string>()
  const [error, setError] = useState<Error>()

  const handleTakeSelfie = async () => {
    setFlash(true)

    if (!videoRef.current || !canvasRef.current) {
      return
    }

    const video = videoRef.current
    const canvas = canvasRef.current

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const context = canvas.getContext('2d')

    if (!context) {
      return
    }

    flipImage(video, context, true, false, canvas.width, canvas.height)

    const photo = canvas.toDataURL('image/png')

    setError(undefined)
    setPhoto(photo)
  }

  return (
    <FlexStack gap={30} align="center">
      <div
        className={styles.selfie}
        onClick={() => {
          setPhoto('')
        }}
      >
        <canvas className={styles.canvas} ref={canvasRef} />

        <Camera
          videoRef={videoRef}
          onPermission={() => {
            setError(undefined)
          }}
          onError={(error) => {
            setError(error)
          }}
        />

        {!!photo && (
          <Absolute top={0} left={0}>
            <Avatar size={200} url={photo} />
          </Absolute>
        )}

        {rim && (
          <Absolute top={-5} left={-5}>
            <Rim animate={!photo} size={210} visible />
          </Absolute>
        )}

        {flash && <Flash onCompleted={() => setFlash(false)} />}
      </div>

      {error && (
        <div className={styles.error}>
          Please give permission to the application to use your camera and try
          again.
        </div>
      )}

      {photo && (
        <Controls
          onConfirm={async () => {
            const file = await dataURLtoFile(photo)
            onContinue(file)
          }}
        />
      )}

      {!photo && !error && <TakePhotoButton onClick={handleTakeSelfie} />}
    </FlexStack>
  )
}
