import { useLocation } from 'react-router'
import qs from 'qs'
import { useMemo } from 'react'

export type QueryParams = Record<string, string | undefined>

export function useQueryParams<T extends QueryParams>() {
  const location = useLocation()

  const query = useMemo(() => {
    const data = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    return data as QueryParams
  }, [location.search])

  return query as T
}
