import { FlexRow, FlexStack, Spacer } from 'components/Layout'
import { ModalManager, useModal, Input } from 'components/Elements'
import { useEffect, useState } from 'react'
import { ProfileFragment, TagFragment, useUpdateProfileMutation } from 'graphql/types'
import { useNavigate } from 'react-router'
import { ChangePictureButton } from 'components/Theme/ChangePictureButton'
import { ProfileAvatar } from 'components/Profiles/ProfileAvatar'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useBlocksStore } from 'components/BlocksContext'
import { FloatingActionMenu } from './FloatingMenu/FloatingActionMenu'
import { Row, Stack } from '@shareback/structural'

export interface EditPageProps {
  profile: ProfileFragment
}

export const EditPage = ({ profile }: EditPageProps) => {
  const navigate = useNavigate()

  const [name, setName] = useState(profile.name || '')
  const [role, setRole] = useState(profile.role || '')
  const [photo, setPhoto] = useState(profile.photo || '')
  const [industry, setIndustry] = useState(profile.industry || '')
  const [location, setLocation] = useState(profile.location || '')
  const [socialLinkedIn, setSocialLinkedIn] = useState(profile.socialLinkedIn || '')
  const [socialEmail, setSocialEmail] = useState(profile.socialEmail || '')
  const [socialPhone, setSocialPhone] = useState(profile.socialPhone || '')

  const [updateProfileMutation] = useUpdateProfileMutation()

  const save = async () => {
    await updateProfileMutation({
      variables: {
        input: {
          industry: industry.trim(),
          location: location.trim(),
          name: name.trim(),
          role: role.trim(),
          socialEmail,
          socialLinkedIn,
          socialPhone,
          photo,
          blocks: blocksStore.blocks
        }
      }
    })

    navigate(`/${profile.identifier}`)
  }

  const blocksStore = useBlocksStore()

  useEffect(() => {
    blocksStore.import(profile.blocks)
  }, [])

  return (
    <>
      <AppPageLayout
        header={
          <Row fill horizontal="spaced">
            <Spacer height={40} />
          </Row>
        }
      >
        <Stack>
          <Row horizontal="right">
            <ChangePictureButton
              onChange={(url) => {
                setPhoto(url)
              }}
            />
          </Row>

          <ProfileAvatar picture={photo} />
        </Stack>

        <FlexStack fill gap={10}>
          <Input
            fill
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <Input
            label="Role"
            placeholder="Role"
            value={role}
            onChange={(event) => setRole(event.target.value)}
          />

          <Input
            label="Industry"
            placeholder="Industry"
            value={industry}
            onChange={(event) => setIndustry(event.target.value)}
          />

          <Input
            label="Location"
            placeholder="Location"
            value={location}
            onChange={(event) => setLocation(event.target.value)}
          />

          <Input
            fill
            disabled
            label="Blitz link"
            placeholder="URL"
            value={`${window.location.host}/${profile.identifier}`}
            onChange={() => {}}
          />

          <Input
            fill
            label="Linked In"
            placeholder="e.g. linkedin.com/in/user-name"
            value={socialLinkedIn}
            onChange={(event) => setSocialLinkedIn(event.target.value)}
          />

          <Input
            fill
            label="Public email address"
            placeholder="e.g. john@company.com"
            value={socialEmail}
            onChange={(event) => setSocialEmail(event.target.value)}
          />

          <Input
            fill
            label="Phone number"
            placeholder="e.g. +44XXXXXXXXXX"
            value={socialPhone}
            onChange={(event) => setSocialPhone(event.target.value)}
          />
        </FlexStack>

        <Spacer height={60} />

        <FloatingActionMenu
          actions={[
            {
              label: 'Go back',
              action() {
                navigate(`/${profile.identifier}`)
              }
            },
            {
              label: 'Save changes',
              // color: '#f8fd99',
              color: '#b7fbc9',
              action() {
                save()
              }
            }
          ]}
        />
      </AppPageLayout>
    </>
  )
}
