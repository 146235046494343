import { ReactNode } from 'react'
import Background1 from './assets/background1.jpeg'

import styles from './BlurBackground.module.scss'

export interface BlurBackgroundProps {
  image?: string
  children?: ReactNode
}

export const BlurBackground = ({ image, children }: BlurBackgroundProps) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${image || Background1})`
        }}
      />
      <div className={styles.mask} />
      <div className={styles.children}>{children}</div>
    </div>
  )
}
