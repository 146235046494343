import { useGetGroupInvitesQuery } from 'graphql/types'

export const useGroupInvites = (id: string, skip?: boolean) => {
  const { data, loading, error } = useGetGroupInvitesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id
    },
    skip
  })

  if (error) {
    console.warn(error)
  }

  const invites = data?.group?.invites ?? []

  return invites
}
