import { ReactNode } from 'react'

export interface AbsoluteProps {
  left?: number
  top?: number
  right?: number
  bottom?: number
  children: ReactNode
}

export const Absolute = ({
  left,
  top,
  right,
  bottom,
  ...rest
}: AbsoluteProps) => (
  <div
    style={{
      display: 'block',
      position: 'absolute',
      left,
      top,
      right,
      bottom
    }}
    {...rest}
  />
)
