import { ReactComponent as LinkedinLogo } from './LinkedinImage.svg'
import { blitz } from 'controller/blitz'
import { useContinueUrl } from '../useContinueUrl'
import { AuthButton } from '../AuthButton/AuthButton'

export const LinkedInButton = () => {
  const continueUrl = useContinueUrl()

  return (
    <AuthButton
      logo={<LinkedinLogo />}
      label=" Continue with LinkedIn"
      onClick={async () => {
        try {
          await blitz.auth.signInLinkedIn()

          window.open(continueUrl, '_self')
        } catch (error) {
          console.log(error)
        }
      }}
    />
  )
}
