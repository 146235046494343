import { Centered } from '@shareback/structural'
import { Spinner } from 'components/Spinner'

export const PulsePage = () => {
  return (
    <Centered window>
      <Spinner />
    </Centered>
  )
}
