import { ReactNode } from 'react'

import styles from './Footer.module.scss'

export interface FooterProps {
  children?: ReactNode
}

export const Footer = ({ children }: FooterProps) => {
  return <div className={styles.footer}>{children}</div>
}
