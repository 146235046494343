import { useNavigate } from 'react-router-dom'
import { ProfileListItem } from './ProfileListItem'
import { ReactNode } from 'react'

export interface ProfileListItemLinkProps {
  title: string
  description: string
  photo: string
  photoRadius?: number
  to?: string
  more?: ReactNode
}

export const ProfileListItemLink = ({
  title,
  description,
  photo,
  to,
  more,
  photoRadius
}: ProfileListItemLinkProps) => {
  const navigate = useNavigate()

  return (
    <ProfileListItem
      more={more}
      photoRadius={photoRadius}
      title={title}
      description={description}
      photo={photo}
      onClick={() => {
        if (to) {
          navigate(to)
        }
      }}
    />
  )
}
