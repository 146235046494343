import { PropsWithChildren, ReactNode } from 'react'

export interface CenteredProps {
  children: ReactNode
  window?: boolean
}

export const Centered = ({ children, window }: CenteredProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: window ? '100vh' : '100%',
        width: '100%'
      }}
    >
      {children}
    </div>
  )
}
