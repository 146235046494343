import { nanoid } from 'nanoid'
import React, { useCallback } from 'react'
import { useMapState, useStackState } from 'rooks'

import { ModalProps } from './Modal'

type Dictionary = Record<string, any>

type Callbacks = Record<string, Function> & {
  onAfterOpen?: ModalProps['onAfterOpen']
  onAfterClose?: ModalProps['onAfterClose']
}

export type SomeModalProps = Omit<ModalProps, 'children' | 'isOpen'>

export interface ModalTemplate {
  templateId: string
  name: string
  component: React.FC<ModalProps>
  props: SomeModalProps
}

interface ModalIdentity extends ModalTemplate {
  id: string
  attributes: Dictionary
  callbacks: Callbacks
}

export const useModalController = () => {
  /**
   * List of currently rendered modals. Storing it as a list Allows to overlay modals.
   */
  const [rendered, renderedControls] = useMapState<
    Record<string, ModalIdentity>,
    string
  >({})

  const [, layers] = useStackState<string>([])
  /**
   * Registry of templates which can be used to mount modal. This list is controlled by
   * ModalManager components which add / remove template on mount / unmount.
   */
  const [templates, templatesControls] = useMapState<
    Record<string, ModalTemplate>,
    string
  >({})
  /**
   * Shows modal.
   *
   * Modal can be shown only if it was registered through ModalManager.
   *
   * @example:
   * ```
   * <ModalManager name="my-modal" component={MyModal} />
   *
   * ...
   *
   * const modal = useModal()
   *
   * modal.show('my-modal')
   * ```
   */
  const show = useCallback(
    <Attributes extends Dictionary>(
      name: string,
      attributes?: Attributes,
      callbacks?: Callbacks
    ) => {
      const template = templates[name]
      const id = nanoid()

      if (template) {
        renderedControls.set(name, {
          id,
          attributes: attributes ?? {},
          callbacks: callbacks ?? {},
          ...template
        })
        layers.push(name)

        return id
      }
    },
    [templates, renderedControls]
  )
  /**
   * Hides modal.
   *
   * Picks the most recent one.
   */
  const hide = useCallback(() => {
    const id = layers.pop()
    if (id) {
      renderedControls.remove(id)
    }
  }, [renderedControls, layers])

  const addTemplate = useCallback(
    (name: string, component: any, props: SomeModalProps) => {
      const exists = templatesControls.has(name)

      if (exists) {
        console.warn(`Modal :: modal with name "${name}" already registered.`)
      }

      const templateId = templates[name]?.templateId || nanoid()

      templatesControls.set(name, {
        templateId,
        name,
        component,
        props
      })

      return templateId
    },
    [templatesControls]
  )

  const removeTemplate = useCallback(
    (name: string) => {
      templatesControls.remove(name)
    },
    [templatesControls]
  )

  const render = useCallback(
    (name: string) => {
      const modal = rendered[name]
      const Component = modal?.component

      return modal ? (
        <Component
          {...modal.props}
          key={modal.id}
          attributes={modal.attributes}
          isOpen
          onAfterOpen={() => {
            modal.callbacks?.onAfterOpen?.()
            modal.props.onAfterOpen?.()
          }}
          onAfterClose={() => {
            modal.callbacks?.onAfterClose?.()
            modal.props.onAfterClose?.()
          }}
          onRequestClose={(event) => {
            modal.props.onRequestClose?.(event)
            hide()
          }}
        />
      ) : null
    },
    [rendered, hide]
  )

  return {
    render,
    show,
    hide,
    templates,
    addTemplate,
    removeTemplate
  }
}
