import { Row } from '@shareback/structural'
import {
  CompanySummaryFragment,
  GroupSummaryFragment,
  ProfileFragment,
  useGetGroupInfoQuery
} from 'graphql/types'
import { Button } from 'routes/ProfilePage/Button'
import { Icon, ModalManager, useModal } from 'components/Elements'
import { PageSettings } from './PageSettings'
import { useNavigate } from 'react-router'
import { Logo } from './Logo'
import { useNotificationsContext } from 'routes/NotificationsPage/NotificationsProvider'
import styles from './PageHeader.module.scss'
import { useAuth } from 'auth'
import { useQueryParams } from 'hooks/useQueryParams'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { Link } from 'react-router-dom'
import { BackGroupButton } from './BackGroupButton'

export interface HomePageProps {
  profile?: ProfileFragment
  company?: CompanySummaryFragment
  groups: GroupSummaryFragment[]
  invites: any[]
}

export const PageHeader = () => {
  const modal = useModal()
  const navigate = useNavigate()
  const auth = useAuth()
  const notifications = useNotificationsContext()
  const params = useQueryParams()

  const fromGroupId = params['from_group'] ?? ''

  const groupInfoQuery = useGetGroupInfoQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: fromGroupId
    },
    skip: !fromGroupId
  })

  return (
    <>
      <ModalManager component={PageSettings} name="settings" />

      <Row horizontal="spaced" fill>
        <Row gap="0.5em">
          <Logo />

          {fromGroupId && (
            <BackGroupButton
              to={`/g/${fromGroupId}`}
              photo={groupInfoQuery.data?.groupInfo?.photo ?? ''}
            />
          )}
        </Row>

        {auth.user && (
          <Row gap={10}>
            <div className={notifications.some ? styles.fresh : undefined}>
              <Button
                onClick={async () => {
                  navigate('/notifications')
                }}
              >
                <Icon name="ri-notification-2-line" size={16} />
              </Button>
            </div>

            <Button
              onClick={async () => {
                modal.show('settings')
              }}
            >
              <Icon name="ri-menu-line" size={16} />
            </Button>
          </Row>
        )}
      </Row>
    </>
  )
}
