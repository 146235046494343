import { Button, ButtonSize } from 'components/Elements'
import { useModal } from 'components/Elements'
import { Row } from '@shareback/structural'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { FlexStack } from 'components/Layout'

interface EditBlockModalFooterProps {
  onRemove?: () => void
}

export const EditBlockModalFooter = ({
  onRemove
}: EditBlockModalFooterProps) => {
  const modal = useModal()

  return (
    <FlexStack gap={10}>
      <Row fill gap={14}>
        {!!onRemove && (
          <Button
            size={ButtonSize.Large}
            style={{ backgroundColor: '#ef3839', color: '#fff' }}
            icon="ri-delete-bin-4-line"
            onClick={() => {
              onRemove?.()
              modal.hide()
            }}
          />
        )}

        <Button fill size={ButtonSize.Large} type="submit">
          Save
        </Button>
      </Row>

      {/* <Row gap={10}>
        <CasualButton
          style={{
            padding: '20px 10px',
            background: '#f8fd99',
            width: 80,
            backgroundColor: '#ef3839',
            color: '#fff'
          }}
          label=""
          onClick={() => {}}
          icon="ri-delete-bin-4-line"
        />

        <CasualButton
          style={{
            padding: '20px 10px',
            background: '#f8fd99',
            color: '#000'
          }}
          label="Save changes"
          onClick={() => {}}
          icon=""
        />
      </Row> */}
    </FlexStack>
  )
}
