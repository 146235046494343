import { useAuth } from 'auth'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { ModalProvider } from 'components/Elements'
import { useGetEditGroupPageQuery } from 'graphql/types'
import { EditGroupPage } from './EditGroupPage'
import { ManageMembers } from './ManageMembers'

export const EditGroupPageRoute = () => {
  const navigate = useNavigate()
  const params = useParams()
  const auth = useAuth()

  const id = params.id ?? ''

  const { data, loading } = useGetEditGroupPageQuery({
    variables: {
      id: id
    },
    skip: !id || auth.loading
  })

  if (loading || auth.loading) {
    return null
  }

  const group = data?.group

  if (!group || !group.membership || group.membership?.role !== 'admin') {
    navigate('/')
    return null
  }

  return (
    <ModalProvider>
      <EditGroupPage membership={group.membership} group={group} />
    </ModalProvider>
  )
}
