import { Row } from '@shareback/structural'
import styles from './ContactsButton.module.scss'
import { Icon } from 'components/Elements'
import { useNavigate } from 'react-router'

export interface ContactsButtonProps {
  onClick: () => void
}

export const ContactsButton = () => {
  const navigate = useNavigate()

  return (
    <Row
      gap={10}
      fill
      className={styles.clickable}
      onClick={() => {
        navigate('/contacts')
      }}
    >
      <Row gap={20} fill>
        {/* <AvatarStack borderColor="#1b1b1b" avatars={[]} size={32} /> */}
        <div>Connections</div>
      </Row>
      <Row>
        <Icon name="ri-arrow-right-s-line" />
      </Row>
    </Row>
  )
}
