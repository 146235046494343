import { useAuth } from 'auth'
import { ModalProvider } from 'components/Elements'
import { useGetCompanyQuery } from 'graphql/types'
import { useParams } from 'react-router'
import { AnimatedRoute } from 'v2/AnimatedRoute/AnimatedRoute'
import { CompanyPage } from './CompanyPage'

export const CompanyPageRoute = () => {
  const params = useParams()
  const auth = useAuth()

  const identifier = params.identifier

  const { data, loading } = useGetCompanyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      identifier: identifier ?? ''
    },
    skip: auth.loading || !identifier
  })

  const company = data?.company
  const employees = data?.employees ?? []
  // const employees = data

  if (loading || !company) {
    return null
  }

  return (
    <AnimatedRoute>
      <ModalProvider>
        <CompanyPage
          authenticated={!!auth.user}
          company={company}
          employees={employees}
          owner={auth.profile?.company?.identifier === company.identifier}
        />
      </ModalProvider>
    </AnimatedRoute>
  )
}
