export const share = async (
  title: string,
  description: string,
  url: string
) => {
  if (navigator.share) {
    const data = {
      title: title,
      text: description,
      url: url
    }

    await navigator.share(data)
  } else {
    throw new Error('Web Share API not supported')
  }
}
