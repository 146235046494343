import { useAsyncEffect } from 'rooks'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Template } from '../Template/Template'
import { blitz } from 'controller/blitz'
import { FirebaseError } from 'firebase/app'

export const AuthWithEmailRedirect = () => {
  const [search] = useSearchParams()

  const [error, setError] = useState<any | null>(null)

  const email = search.get('email') ?? ''
  const continueUrl = search.get('redirect_url') ?? '/'

  useAsyncEffect(async () => {
    try {
      await blitz.auth.signInMagicLink(email, window.location.href)

      window.open(continueUrl, '_self')
    } catch (error) {
      console.log(error)

      if (error instanceof FirebaseError) {
        if (error.code === 'auth/email-already-in-use') {
          return
        }
      }

      setError(error)
    }
  }, [])

  if (error) {
    return (
      <Template title="Authentication failed">
        <div>{error.toString()}</div>
        <Link to="/auth?">Retry login</Link>
      </Template>
    )
  }

  return <></>
}
