import * as yup from 'yup'

export const createYupValidationResolver =
  (validationSchema: yup.Schema) => async (data: object) => {
    let errors = {}
    let values = {}

    try {
      values = await validationSchema.validate(data, {
        abortEarly: false
      })
    } catch (exceptions: any) {
      if (exceptions instanceof yup.ValidationError) {
        errors = exceptions.inner.reduce((errors, error) => {
          const path = error.path
          const type = error.type ?? 'validation'
          const message = error.message

          if (path) {
            return {
              ...errors,
              [path]: { type, message }
            }
          } else {
            return errors
          }
        }, {})
      }
    }

    return { errors, values }
  }
