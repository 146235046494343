import { Stack } from '@shareback/structural'
import { Modal, ModalProps } from 'components/Elements'
import { Column } from 'v2/Column'
import { blitz } from 'controller/blitz'
import { CasualButton } from 'routes/HomePage/CasualButton'

export const PageSettings = (props: ModalProps) => {
  return (
    <Modal {...props}>
      <Column max={260}>
        <Stack fill gap={10}>
          <CasualButton
            style={{ padding: 14 }}
            onClick={() => {
              window.open('https://shareback.com', 'shareback')
            }}
            label="About"
            icon="ri-question-line"
          />

          <CasualButton
            style={{
              color: '#f53f3d',
              padding: 14
            }}
            label="Log out"
            onClick={async () => {
              await blitz.logout()
            }}
            icon=""
          />
        </Stack>
      </Column>
    </Modal>
  )
}
