import { blitz } from 'controller/blitz'
import { ReactComponent as GoogleLogoSvg } from './GoogleLogoImage.svg'
import { useContinueUrl } from '../useContinueUrl'
import { AuthButton } from '../AuthButton/AuthButton'

export const GoogleButton = () => {
  const continueUrl = useContinueUrl()

  return (
    <AuthButton
      logo={<GoogleLogoSvg />}
      label="Continue with Google"
      onClick={async () => {
        try {
          await blitz.auth.signInGoogle()

          window.open(continueUrl, '_self')
        } catch (error) {
          console.log(error)
        }
      }}
    />
  )
}
