import { FlexRow } from 'components/Layout'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { ReactNode } from 'react'

export interface AuthButtonProps {
  logo?: ReactNode
  label: ReactNode
  onClick?: () => void
}

export const AuthButton = ({ logo, label, onClick }: AuthButtonProps) => {
  return (
    <CasualButton
      style={{ height: 48 }}
      label={
        <FlexRow gap="1em">
          {logo}
          {label}
        </FlexRow>
      }
      onClick={onClick}
    />
  )
}
