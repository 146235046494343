import { useEffect, useState } from 'react'
import styles from './FloatingMenu.module.scss'

interface FloatingMenuProps {
  children: React.ReactNode
}

export const FloatingMenu: React.FC<FloatingMenuProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true)

  // useEffect(() => {
  //   const handleVisibility = () => {
  //     setIsVisible(!window.visualViewport?.height)
  //   }

  //   window.addEventListener('resize', handleVisibility)
  //   window.addEventListener('scroll', handleVisibility)

  //   return () => {
  //     window.removeEventListener('resize', handleVisibility)
  //     window.removeEventListener('scroll', handleVisibility)
  //   }
  // }, [])

  return isVisible ? <div className={styles.menu}>{children}</div> : null
}
