import { useAuth } from 'auth'
import { useGetNotificationsQuery } from 'graphql/types'
import { map, uniqBy } from 'lodash'
import { PropsWithChildren, createContext, useContext } from 'react'

function mapItems<T extends { id: string; group: { id: string; name: string } }>(items: T[]) {
  const groups = uniqBy(
    map(items, (v) => v.group),
    (v) => v.id
  )

  return groups
    .map((group) => {
      return {
        ...group,
        children: items.filter((v) => v.group.id === group.id)
      }
    })
    .filter((v) => v.children.length !== 0)
}

function useNotificationsData() {
  const { user } = useAuth()

  const { data, loading } = useGetNotificationsQuery({
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    skip: !user
  })

  const matches = mapItems(data?.notifications.matches ?? [])
  const invites = mapItems(data?.notifications.invites ?? [])

  const some = invites.length + matches.length > 0

  // todo: need to subscribe for updates

  return { matches, invites, some, data, loading }
}

type ContextValueType = ReturnType<typeof useNotificationsData>

const context = createContext<ContextValueType>({
  loading: true,
  matches: [],
  invites: [],
  some: false,
  data: undefined
})
const Provider = context.Provider

export interface NotificationProviderProps extends PropsWithChildren {}

export const NotificationsProvider = ({ children }: NotificationProviderProps) => {
  const value = useNotificationsData()

  return <Provider value={value}>{children}</Provider>
}

export const useNotificationsContext = () => {
  return useContext(context)
}
