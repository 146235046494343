import { Link } from 'react-router-dom'
import { Icon } from 'components/Elements'

import styles from './BackButton.module.scss'

export interface BackButtonProps {
  to: string
  onClick?: () => void
}

export const BackButton = ({ to, onClick }: BackButtonProps) => {
  return (
    <Link
      to={to}
      className={styles.button}
      onClick={() => {
        onClick?.()
      }}
    >
      <Icon size={20} name="ri-arrow-left-s-line" />
    </Link>
  )
}
