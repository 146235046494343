import { ReactNode } from 'react'
import styles from './Overlay.module.scss'
import { Absolute } from '@shareback/structural'
import { Icon } from '../Icon'

export interface OverlayProps {
  children: ReactNode
  close: () => void
}

export const Overlay = ({ children, close }: OverlayProps) => {
  return (
    <div className={styles.overlay}>
      {children}

      {!!close && (
        <Absolute top={0} right={0}>
          <div
            style={{ padding: 12, cursor: 'pointer', zIndex: 100 }}
            onClick={() => {
              close()
            }}
          >
            <Icon size="1.5em" name="ri-close-line" />
          </div>
        </Absolute>
      )}
    </div>
  )
}
