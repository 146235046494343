import { ReactNode } from 'react'
import { useNavigate } from 'react-router'

import styles from './Container.module.scss'

export interface ContainerProps {
  className?: string
  children: ReactNode
}

export const Container = ({ children }: ContainerProps) => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.logo}></div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
