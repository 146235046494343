import { Icon } from 'components/Elements'
import { useLocation, useNavigate } from 'react-router'
import { AuthButton } from '../AuthButton/AuthButton'

export const EmailButton = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <AuthButton
      logo={<Icon name="ri-at-line" />}
      label="Continue with Email"
      onClick={() => {
        navigate(`/auth/email${location.search}`)
      }}
    />
  )
}
