import { Icon } from 'components/Elements'
import cn from 'classnames'

import styles from './AButton.module.scss'
import { ReactNode } from 'react'
import { Row } from '@shareback/structural'

export interface AButtonProps {
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  children?: ReactNode
  icon?: string
}

export const AButton = ({ children, className, style, icon, onClick }: AButtonProps) => {
  return (
    <Row
      fill
      gap="0.5em"
      className={cn(styles.button, className)}
      horizontal="center"
      vertical="center"
      style={style}
      onClick={onClick}
    >
      {icon && <Icon name={icon} size="1em" />} {children}
    </Row>
  )
}
