import { useLocation, useMatch } from 'react-router'

export const usePageLocation = () => {
  const location = useLocation()

  if (location.pathname.startsWith('/c/')) {
    return 'company'
  }

  if (location.pathname.startsWith('/g/')) {
    return 'group'
  }

  return 'profile'
}
