import { Button, ButtonSize, Icon, Input, Textarea } from 'components/Elements'
import { FlexRow, FlexStack, Spacer } from 'components/Layout'
import { CompanyFragment, useUpdateCompanyMutation } from 'graphql/types'
import { useForm, SubmitHandler } from 'react-hook-form'

import styles from './ConfigureCompany.module.scss'
import { AppPageLayout } from 'v2/AppPageLayout'
import { Row } from '@shareback/structural'
import { BackButton } from '../BackButton'

export interface ConfigureCompanyProps {
  company: CompanyFragment
  onNext: (values: FormValues) => void
}

type FormValues = {
  name: string
  description: string
}

export const ConfigureCompany = ({
  company,
  onNext
}: ConfigureCompanyProps) => {
  const [updateCompany] = useUpdateCompanyMutation()

  const { register, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      name: company.name || '',
      description: company.description || ''
    }
  })

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    await updateCompany({
      variables: {
        input: {
          ...values,
          visible: true
        }
      }
    })

    return onNext(values)
  }

  return (
    <AppPageLayout>
      <Spacer height={40} />

      <Row horizontal="spaced" fill>
        <Row gap={10}>
          <BackButton to="/onboarding" />
        </Row>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexStack fill gap={40}>
          <FlexStack fill gap={12}>
            <Spacer height={10} />
            <FlexRow fill>Tell us more about your company</FlexRow>
            <Spacer height={10} />

            <Input
              placeholder="Name"
              {...register('name', { required: true })}
            />

            <Textarea
              placeholder="About (optional)"
              {...register('description')}
              minRows={2}
            />

            <FlexRow gap={12} className={styles.note}>
              <Icon name="fi-rr-info" />
              <div>
                Anyone with an email address belonging to @{company.domains[0]}{' '}
                domain will automatically join this company.
              </div>
            </FlexRow>
          </FlexStack>

          <Button fill size={ButtonSize.Large} type="submit">
            Next
          </Button>
        </FlexStack>
      </form>
    </AppPageLayout>
  )
}
