import { Icon } from 'components/Elements'
import { FlexRow } from 'components/Layout'
import cn from 'classnames'

import styles from './CasualButton.module.scss'
import { ReactNode } from 'react'

export interface CasualButtonProps {
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
  label: ReactNode
  icon?: string
}

export const CasualButton = ({ label, className, style, icon, onClick }: CasualButtonProps) => {
  return (
    <FlexRow
      fill
      gap="0.5em"
      className={cn(styles.button, className)}
      horizontal="middle"
      vertical="center"
      style={style}
      onClick={onClick}
    >
      {icon && <Icon name={icon} size={20} />} {label}
    </FlexRow>
  )
}
