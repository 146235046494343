import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthRoute } from 'routes/Auth'
import { AuthenticatedApp } from '../routes/AuthenticatedApp'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route key="auth-no" path="/auth/*" element={<AuthRoute />} />
        <Route key="auth-yes" path="/*" element={<AuthenticatedApp />} />
      </Routes>
    </BrowserRouter>
  )
}
