import { PropsWithChildren } from 'react'

export interface ColumnGridProps extends PropsWithChildren {
  columns: number
  gap?: number
}

export const ColumnGrid = ({ columns, gap = 0, children }: ColumnGridProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        width: '100%',
        gap
      }}
    >
      {children}
    </div>
  )
}
