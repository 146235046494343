import $ from 'classnames'
import { forwardRef, InputHTMLAttributes } from 'react'
import { FlexRow } from 'components/Layout'
import { Row } from '@shareback/structural'

import styles from './Field.module.scss'

type InputAttributes = InputHTMLAttributes<HTMLInputElement>

export interface InputProps extends Omit<InputAttributes, 'size'> {
  fill?: boolean
  label?: string
  level?: 'primary' | 'secondary'
  description?: string
  disabled?: boolean
  error?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      level = 'primary',
      fill,
      label = '',
      placeholder = '',
      value,
      description = '',
      disabled = false,
      error,
      ...props
    },
    ref
  ) => (
    <div className={$(styles.container, fill && styles.fill)}>
      {(label || description) && (
        <FlexRow fill horizontal="spaced" className={styles.label}>
          <div>{label}</div>
          <div>{description}</div>
        </FlexRow>
      )}

      <input
        autoComplete="off"
        ref={ref}
        disabled={disabled}
        className={$(
          styles.field,
          styles[level],
          styles.input,
          fill && styles.fill,
          disabled && styles.disabled,
          className
        )}
        placeholder={placeholder}
        value={value}
        {...props}
      />

      {error && (
        <Row fill horizontal="spaced" className={styles.error}>
          {error}
        </Row>
      )}
    </div>
  )
)
