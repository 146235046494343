import axios from 'axios'
import config from 'config'
import { getAccessToken } from 'auth'

const client = axios.create({
  baseURL: config.apiUrl
})

client.interceptors.request.use((request) => {
  const token = getAccessToken()

  request.headers = request.headers || {}
  request.headers['authorization'] = `Bearer ${token}`

  return request
})

export const MediaClient = {
  async uploadFile(file: File | Blob, params: Record<string, string> = {}) {
    const form = new FormData()

    form.append('file', file)

    for (let i in params) {
      form.append(i, params[i])
    }

    const { data } = await client.post(`/media`, form, {
      headers: {
        'DialogContent-Type': 'multipart/form-data'
      }
    })

    return data
  },

  async uploadAvatar(file: File | Blob) {
    const form = new FormData()

    form.append('file', file)

    const { data } = await client.post(`/media/avatar`, form, {
      headers: {
        'DialogContent-Type': 'multipart/form-data'
      }
    })

    return data
  }
}
