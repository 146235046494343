import { PropsWithChildren } from 'react'
import styles from './Button.module.scss'

export interface ButtonProps extends PropsWithChildren {
  onClick?: () => void
}

export const Button = ({ children, onClick }: ButtonProps) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  )
}
