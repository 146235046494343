import $ from 'classnames'
import styles from './RecordButton.module.scss'

interface Props {
  recording: boolean
  onClick: () => void
}

export const RecordButton = ({ recording, onClick }: Props) => {
  return (
    <div
      className={$(styles.component, recording && styles.recording)}
      onClick={onClick}
    >
      <div className={styles.outer}></div>
      <div className={styles.inner}></div>
    </div>
  )
}
