import { Container } from './Container'
import { Footer } from './Footer'
import { PictureHeader } from './PictureHeader'
import { PictureHeaderButton } from './PictureHeaderButton'
import { Loading } from './Loading'

export const Theme = {
  Container,
  Footer,
  PictureHeader,
  PictureHeaderButton,
  Loading
}
