import { Spacer } from 'components/Layout'
import { CompanySummaryFragment, ProfileFragment } from 'graphql/types'
import { getRoleDescription } from 'v2/_utils/getRoledescription'
import { AppPageLayout } from 'v2/AppPageLayout'
import { useNavigate, useNavigation } from 'react-router'
import { Row } from '@shareback/structural'
import { Helmet } from 'react-helmet'
import { getProfileLink } from 'v2/_utils/getProfileLink'
import { FloatingActionMenu } from 'routes/EditProfilePage/FloatingMenu/FloatingActionMenu'
import { AppLogoLink } from 'v2/AppLogoLink/AppLogoLink'
import { ProfileComponent } from './ProfileComponent'
import { CasualButton } from 'routes/HomePage/CasualButton'
import { useState } from 'react'
import { Glow } from 'v2/Picture/Glow'
import { PageLayout } from 'v2/PageLayout/PageLayout'

export interface ProfilePageProps {
  owner: boolean
  profile: ProfileFragment
  company?: CompanySummaryFragment
  authenticated?: boolean
}

export const ProfilePage = ({ authenticated, owner, profile, company }: ProfilePageProps) => {
  const navigate = useNavigate()
  const description = getRoleDescription(profile.role, company?.name)
  // history seems to be a bit weird, caching this in state
  const [from, setFrom] = useState(history.state?.usr?.from)

  return (
    <>
      <Helmet>
        <title>{profile.name}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={profile.name} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={profile.photo} />
        <meta property="og:url" content={getProfileLink(profile)} />
        <meta name="twitter:title" content={profile.name} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={profile.photo} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <PageLayout
        footer={
          owner ? (
            <FloatingActionMenu
              actions={[
                {
                  label: 'Edit profile',
                  action() {
                    navigate(`/edit`)
                  }
                },
                {
                  label: 'Add links',
                  action() {
                    navigate(`/edit-blocks`)
                  }
                }
              ]}
            />
          ) : undefined
        }
      >
        <ProfileComponent profile={profile} company={company} />
        <Spacer height={156} />
      </PageLayout>
    </>
  )
}
