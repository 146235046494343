import $ from 'classnames'
import Skeleton from 'react-loading-skeleton'

import styles from './AvatarBase.module.scss'

export interface AvatarLoadingProps {
  size: number
  clickable?: boolean
}

export const AvatarLoading = ({ size, clickable }: AvatarLoadingProps) => (
  <Skeleton
    className={$(clickable && styles.clickable)}
    width={size}
    height={size}
    circle
  />
)
