import { Row } from '@shareback/structural'
import { FloatingMenu } from './FloatingMenu'
import { CasualButton } from 'routes/HomePage/CasualButton'
import $ from 'classnames'

import styles from './FloatingMenu.module.scss'
import { Icon } from 'components/Elements'
import React from 'react'

export interface Action {
  label: string
  icon?: string
  action: () => void
  color?: string
  className?: string
}

export interface FloatingActionMenuProps {
  actions: Action[]
}

export const FloatingActionMenu = ({ actions }: FloatingActionMenuProps) => {
  return (
    <FloatingMenu>
      <div className={styles.buttons}>
        {actions.map((action, index) => (
          <React.Fragment key={index}>
            <Row
              fill
              gap="0.5em"
              key={`row-${index}`}
              className={styles.button}
              horizontal="center"
              vertical="center"
              style={{ color: action.color }}
              onClick={action.action}
            >
              {action.icon && <Icon name={action.icon} size={20} />}{' '}
              {action.label}
            </Row>
            {index !== actions.length - 1 && (
              <div
                key={`divider-${index}`}
                style={{
                  width: 1,
                  height: 40,
                  marginLeft: 4,
                  marginRight: 4,
                  backgroundColor: 'rgba(255,255,255, 0.1)'
                }}
              />
            )}
          </React.Fragment>
        ))}

        {/* <CasualButton
          style={{
            padding: '12px 20px',
            backgroundColor: 'transparent',
            width: 136
          }}
          label="Go back"
          icon=""
          onClick={() => {}}
        />
        <div
          style={{
            width: 1,
            height: 40,
            backgroundColor: 'rgba(255,255,255, 0.1)'
          }}
        ></div>
        <CasualButton
          style={{
            padding: '10px 10px',
            backgroundColor: 'transparent',
            color: '#f8fd99',
            width: 136
          }}
          label="Save changes"
          icon=""
          onClick={() => {}}
        /> */}
      </div>
    </FloatingMenu>
  )
}
