import { Row, Stack } from '@shareback/structural'
import { ButtonLink, ButtonSize } from 'components/Elements'

import styles from './UnauthenticatedFooter.module.scss'

export interface UnauthenticatedFooterProps {
  groupId: string
}

export const UnauthenticatedFooter = ({
  groupId
}: UnauthenticatedFooterProps) => {
  return (
    <Stack horizontal="center" gap={20} style={{ textAlign: 'center' }}>
      <Row gap={12} className={styles.note}>
        <div>To join this group please create an account first.</div>
      </Row>

      <Row horizontal="center">
        <ButtonLink
          size={ButtonSize.Large}
          link={`/auth?continue_url=/?group_id=${groupId}`}
        >
          Create an account
        </ButtonLink>
      </Row>
    </Stack>
  )
}
