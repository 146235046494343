const config = {
  apiUrl: 'https://api-dt4xmd252a-ez.a.run.app',
  graphqlWebsocketUrl: 'wss://api-dt4xmd252a-ez.a.run.app/graphql',

  firebase: {
    apiKey: 'AIzaSyA6Wpc9tnNs32lyU02dma4CsSkI3xRwl98',
    authDomain: 'blitz-374213.firebaseapp.com'
  },

  qrCodeHost: 'https://beta.blitz'
}

export default config
